import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { SwiperModule } from 'swiper/angular';
import { GoogleMapsModule } from '@angular/google-maps';
import { HttpClientModule } from '@angular/common/http';
import { NgxGoogleAnalyticsModule } from 'ngx-google-analytics';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { ReactiveFormsModule } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { RECAPTCHA_SETTINGS, RECAPTCHA_V3_SITE_KEY, RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings, RecaptchaV3Module } from "ng-recaptcha";

// custom components
import { HeroComponent } from './components/hero/hero.component';
import { BookAppointmentCardComponent } from './components/book-appointment-card/book-appointment-card.component';
import { HeaderComponent } from './components/header/header.component';
import { FaqsComponent } from './components/faqs/faqs.component';

// angular material components
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';
import { MatTabsModule } from '@angular/material/tabs';
import { MatIconModule } from '@angular/material/icon';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatToolbarModule } from '@angular/material/toolbar'
import { MatSidenavModule } from '@angular/material/sidenav'
import { MatSelectModule } from '@angular/material/select';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDatepickerModule, } from '@angular/material/datepicker';
import { MatDateFormats, MatNativeDateModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDialogModule } from '@angular/material/dialog';
import { MatRadioModule } from '@angular/material/radio';
import { MatTooltipModule } from '@angular/material/tooltip';

import { CdkAccordionModule } from '@angular/cdk/accordion';
import { AboutUsComponent } from './components/about-us/about-us.component';
import { FooterComponent } from './components/footer/footer.component';
import { JoinUsFormComponent } from './components/join-us-form/join-us-form.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { HomeComponent } from './components/home/home.component';
import { DownloadAppComponent } from './components/download-app/download-app.component';
import { BookAppointmentComponent } from './components/book-appointment/book-appointment.component';

import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import * as _moment from 'moment';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { SafeHtmlPipe } from './pipes/safe-html/safe-html.pipe';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { SnackBarComponent } from './services/snack-bar/snack-bar.service';
import { HowItWorksComponent } from './components/how-it-works/how-it-works.component';
import { RecyclablesComponent } from './components/recyclables/recyclables.component';
import { OurNetworkComponent } from './components/our-network/our-network.component';
import { ClientSpeakComponent } from './components/client-speak/client-speak.component';
import { ImpactCreatedComponent } from './components/impact-created/impact-created.component';
import { WhatWeDoComponent } from './components/what-we-do/what-we-do.component';
import { OurClientsComponent } from './components/our-clients/our-clients.component';
import { NetworkComponent } from './components/network/network.component';
import { BookAppointmentMapComponent } from './components/book-appointment-map/book-appointment-map.component';
import { DropoffPointsMapComponent } from './components/dropoff-points-map/dropoff-points-map.component';
import { CustomDropdownComponent } from './components/custom-dropdown/custom-dropdown.component';
import { NumericOnlyDirective } from './directives/numeric-only/numeric-only.directive';
import { environment } from 'src/environments/environment';
import { MainLayoutComponent } from './layouts/main-layout/main-layout.component';
import { EWasteDropOffLayoutComponent } from './layouts/e-waste-drop-off-layout/e-waste-drop-off-layout.component';
import { CelektModule } from './components/celekt/celekt.module';
import { OtpInputModule } from './components/otp-input/otp-input.module';
import { ImpactCreatedV2Component } from './components/impact-created-v2/impact-created-v2.component';
import { InnovationsComponent } from './components/innovations/innovations.component';
import { OurNetworkHomepageComponent } from './components/our-network-homepage/our-network-homepage.component';


export const MY_FORMATS: MatDateFormats = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD-MMM-YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@NgModule({
  declarations: [
    AppComponent,
    HeroComponent,
    BookAppointmentCardComponent,
    HeaderComponent,
    FaqsComponent,
    AboutUsComponent,
    FooterComponent,
    JoinUsFormComponent,
    ContactUsComponent,
    HomeComponent,
    DownloadAppComponent,
    BookAppointmentComponent,
    PrivacyPolicyComponent,
    SafeHtmlPipe,
    PageNotFoundComponent,
    SnackBarComponent,
    HowItWorksComponent,
    RecyclablesComponent,
    OurNetworkComponent,
    ClientSpeakComponent,
    ImpactCreatedComponent,
    WhatWeDoComponent,
    OurClientsComponent,
    NetworkComponent,
    // OtpInputComponent,
    BookAppointmentMapComponent,
    DropoffPointsMapComponent,
    CustomDropdownComponent,
    NumericOnlyDirective,
    MainLayoutComponent,
    EWasteDropOffLayoutComponent,
    ImpactCreatedV2Component,
    InnovationsComponent,
    OurNetworkHomepageComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    GoogleMapsModule,
    SwiperModule,
    MatCardModule,
    MatButtonModule,
    MatDividerModule,
    MatListModule,
    MatTabsModule,
    MatIconModule,
    MatGridListModule,
    MatToolbarModule,
    MatSidenavModule,
    MatSelectModule,
    MatExpansionModule,
    CdkAccordionModule,
    MatNativeDateModule,
    MatDatepickerModule,
    GooglePlaceModule,
    MatFormFieldModule,
    MatDialogModule,
    MatRadioModule,
    FormsModule,
    MatInputModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule,
    NgxGoogleAnalyticsModule.forRoot('UA-86512427-1'),
    NgxGoogleAnalyticsModule,
    MatSnackBarModule,
    MatTooltipModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    RecaptchaV3Module,
    CelektModule,
    OtpInputModule
  ],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    CookieService,
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.siteKeyV3
    },
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: environment.siteKeyV2
      } as RecaptchaSettings
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
