<div class="main-container">
  <div>
    <h2 class="title">JOIN US</h2>
    <p class="description">Join us as a Recycler/Employee/Agent</p>
  </div>
  <div class="container">
    <app-join-us-form title="I am a Recycler/Industry"></app-join-us-form>
    <app-join-us-form [showEmployeeSignUpForm]="true" title="I am seeking a job" description="We look forward to generating employment with our initiative to make people’s
lives better. Please fill in the details below and we will get back to you."></app-join-us-form>
    <app-join-us-form [showREOSignUpForm]="true" title="I am an agent"
      description="Embrace the opportunity to be a member of our dedicated and passionate workforce, as we work together towards a greener future."></app-join-us-form>
  </div>
</div>

<!-- <div style="width: 100%"><iframe width="100%" height="500" frameborder="0" scrolling="no" marginheight="0"
    marginwidth="0"
    src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=scrapq+()&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>
</div> -->