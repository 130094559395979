import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CityService } from '../city/city.service';
import { HttpService } from '../http/http.service';
import { REST_APIS } from '../RestApis';
import * as moment from 'moment';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  city: any;

  constructor(private httpService: HttpService, private cityService: CityService) {
    this.cityService.getCity().subscribe(city => {
      this.city = city;
    });
  }

  bookAppointment(data: any) {
    const url = environment.API_URL + REST_APIS.registerConsumer;
    return this.httpService.post(url, data);
  }

  async getServiceCategories(city: string) {
    const url = environment.API_URL + REST_APIS.getServiceCategories.replace(':city', city);
    return this.httpService.get(url);
  }


  async getSubServiceCategories(data: any) {
    const url = environment.API_URL + REST_APIS.getSubServiceCategories;
    return this.httpService.post(url, data);
  }

  async getAddressCategories() {
    const url = environment.API_URL + REST_APIS.addressCategories;
    return this.httpService.get(url);
  }

  async getAllCities() {
    const url = environment.API_URL + REST_APIS.allCities;
    return this.httpService.get(url);
  }

  async getFaqs() {
    const url = environment.API_URL + REST_APIS.faqs;
    return this.httpService.get(url);
  }

  async registerServicePartner(data: any) {
    const url = environment.sales_API_URL + REST_APIS.registerServicePartner;
    return this.httpService.postWithoutHeaders(url, data);
  }

  async uploadResume(data: any) {
    const url = environment.sales_API_URL + REST_APIS.uploadResume;
    return this.httpService.uploadResume(url, data);
  }

  getPrivacyPolicy() {
    const url = environment.API_URL + REST_APIS.privacyPolicy;
    return this.httpService.get(url);
  }

  getTermsAndConditions() {
    const url = environment.API_URL + REST_APIS.termsAndConditions;
    return this.httpService.get(url);
  }

  getCancellationPolicy() {
    const url = environment.API_URL + REST_APIS.cancellationPolicy;
    return this.httpService.get(url);
  }

  callMeBack(mobileNo: any) {
    const url = environment.API_URL + REST_APIS.callMeBack.replace(':mobileNo', mobileNo);
    return this.httpService.get(url);
  }

  createOrder(data: object) {
    const url = environment.API_URL + REST_APIS.createOrder;
    return this.httpService.post(url, data);
  }

  retrieveOrder(data: object) {
    const url = environment.API_URL + REST_APIS.retrieveOrder;
    return this.httpService.post(url, data);
  }

  getPublicIpAddress() {
    const url = 'https://api64.ipify.org?format=json';
    return this.httpService.getWithoutHeaders(url);
  }
  async getPrePayments() {
    const url = environment.API_URL + REST_APIS.enablePrePayments.replace(':city', this.city);
    return this.httpService.get(url);
  }

  async checkServiceArea(city: string, lat: any, lng: any) {
    const url = environment.API_URL + REST_APIS.checkServiceArea
      .replace(':city', city)
      .replace(":lat", lat)
      .replace(':lng', lng);
    return this.httpService.get(url);
  }

  async getPaymentOptions(city: string) {
    const url = environment.API_URL + REST_APIS.getAllSettings.replace(':city', city);
    return this.httpService.get(url);
  }

  getSlots(consumerId: string) {
    const date = moment(new Date()).add(1, 'days').format('DD-MMM-yyyy');
    const url = environment.API_URL + REST_APIS.getSlots.replace(':date', date).replace(':consumerId', consumerId);
    return this.httpService.get(url);
  }

  async getProductCategories(city: string) {
    const url = environment.API_URL + REST_APIS.productCategories.replace(':city', city);
    return this.httpService.get(url);
  }

  async getDropOffPoints(lat: string, lng: string) {
    const url = environment.API_URL + REST_APIS.dropoffPoints.replace(':lat', lat).replace(':lng', lng);
    return this.httpService.get(url);
  }

  async getImpactCreated() {
    const url = environment.API_URL + REST_APIS.impactCreated;
    return this.httpService.get(url);
  }

  async verifyRecaptchaToken(token: string) {
    const url = environment.API_URL + REST_APIS.verifyRecaptcha.replace(':token', token);
    return this.httpService.get(url);
  }

  async registerEWasteRRR(data: any) {
    const url = environment.API_URL + REST_APIS.registerEWasteRRR;
    return this.httpService.post(url, data);
  }

  async getEWasteProductCategories(city: string) {
    const url = environment.API_URL + REST_APIS.eWasteProductCategories.replace(':city', city);
    return this.httpService.get(url);
  }

  async getCelektAgent(agentId: string) {
    const url = environment.API_URL + REST_APIS.celektAgent.replace(':agentId', agentId);
    return this.httpService.get(url);
  }


  async uploadCelektItem(data: any) {
    const url = environment.API_URL + REST_APIS.uploadItems;
    return this.httpService.post(url, data);
  }

  async completeCelektAppointment(data: any) {
    const url = environment.API_URL + REST_APIS.celektCompleteAppointment;
    return this.httpService.post(url, data);
  }

  async getCelektAppointmentSummary(appointmentId: string) {
    const url = environment.API_URL + REST_APIS.appointmentItems.replace(':appointmentId', appointmentId);
    return this.httpService.get(url);
  }

  async getcelektUserSells(userId: string) {
    const url = environment.API_URL + REST_APIS.celekUserSells.replace(':userId', userId);
    return this.httpService.get(url);
  }
  async getcelektCouponSummary(couponCode: string) {
    const url = environment.API_URL + REST_APIS.celektCouponSummary.replace(':couponCode', couponCode);
    return this.httpService.get(url);
  }

  async uploadVideo(confirmationId: string, categoryId: string, file: File) {
    const formData = new FormData();
    formData.append('confirmationId', confirmationId);
    formData.append('categoryId', categoryId);

    try {
      const blob = await this.httpService.convertFileToBlob(file);
      formData.append('file', blob);
    } catch (error) {
      console.error('Error converting file to blob:', error);
      throw new Error('Error converting file to blob');
    }

    const headers = new HttpHeaders().set('_method', 'POST');

    const url = environment.API_URL + REST_APIS.uploadVideo;

    return this.httpService.postWithCustomHeaders(url, formData, headers);
  }

  async getAreaWiseHolidays(areaId: string) {
    const url = environment.API_URL + REST_APIS.areaWiseHolidays.replace(':areaId', areaId);
    return this.httpService.get(url);
  }

  async getHolidaysForNewAddress(lat: number, lng: number, city: string, addressType: string) {
    const url = environment.API_URL + REST_APIS.holidays
      .replace(':lat', lat.toString())
      .replace(':lng', lng.toString())
      .replace(':city', city)
      .replace(':addressType', addressType);
    return this.httpService.get(url);
  }
}
