import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import * as moment from 'moment';
import { CookieService } from 'ngx-cookie-service';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { Subject, debounceTime } from 'rxjs';
import { AuthService } from 'src/app/services/auth/auth.service';
import { BookAppointmentService } from 'src/app/services/book-appointment/book-appointment.service';
import { CityService } from 'src/app/services/city/city.service';
import { CommonService } from 'src/app/services/common/common.service';
import { LocationService } from 'src/app/services/location/location.service';
import { SnackBarService } from 'src/app/services/snack-bar/snack-bar.service';

export const SESSION_ID = 'SESSION_ID';
@Component({
  selector: 'app-book-appointment-card',
  templateUrl: './book-appointment-card.component.html',
  styleUrls: ['./book-appointment-card.component.scss']
})
export class BookAppointmentCardComponent {
  static readonly DATE_FORMAT = 'DD-MMM-YYYY';
  selectedTabIndex: any;
  mobileNo = '';
  successMessage: any;
  errorMessage: any;

  resendTimer: number = 0;
  timerInterval: any;

  showDefaultLocation: boolean = true;

  otpSent = false;
  otpVerified = false;
  timerId: any;
  resendOtpTimeLeft = 30;
  formattedAddress = '';
  mobileVerificationForm: FormGroup = new FormGroup({});
  savedAddresses: any[] = [];
  addressCategories: any;
  categoryId: any = '';
  disableAddressCategory: boolean = false;
  appointmentDate: FormControl = new FormControl(moment(new Date()).add('days', 1), Validators.required);
  screenWidth: any;
  minDate = moment(new Date()).add('days', 1);
  maxDate = moment(new Date()).add('days', 60);
  city: any;
  localityName = '';
  buttonClick = new Subject();
  options: any;
  loading = false;
  consumerId: any;
  showDownloadAppMessage = false;
  locationError: boolean = false;
  center: any = {};
  otp: any;
  addressId: FormControl = new FormControl({ value: '', disabled: true });
  dropOffPoints: any;
  timer: any;
  isholidaysLoading: boolean = false;
  areaWiseHolidays: any = [];
  holidayMessage: any = '';

  constructor(private locationService: LocationService,
    private authService: AuthService,
    private commonService: CommonService,
    private formBuilder: FormBuilder,
    private cityService: CityService,
    private snackbarService: SnackBarService,
    private bookAppointmentService: BookAppointmentService,
    private cookieService: CookieService) {
    this.options = {
      componentRestrictions: { country: 'IN' }
    }

    this.cityService.getCity().subscribe(city => {
      this.city = city;
    });

    this.locationService.getPosition().then((position) => {
      this.locationError = false;
      this.center = position;
      this.bookAppointmentService.setLatLng(position.lat, position.lng);
    }).catch((err: GeolocationPositionError) => {
      this.locationError = true;
      if (err.code === 1) {
        // this.snackbarService.showErrorBar('Location permission is required to book appointment. Once you grant permission, please refresh the page to continue', 5000);
      } else {
        this.snackbarService.showErrorBar('An error occurred while trying to determine your location. Please try again.');
      }
    });

    this.buttonClick.pipe(debounceTime(500)).subscribe(() => {
      this.bookAppointment();
    });
  }

  ngOnInit() {
    this.mobileVerificationForm = this.formBuilder.group({
      mobile: ['', [Validators.required, Validators.pattern('[0-9]{10}')]]
    });

    this.addressId.valueChanges.subscribe(value => {
      if (value) {
        this.setMapCenter(value);
        this.getAreaWiseHolidays(value);
      }
    });

    this.bookAppointmentService.getMarkerDragEnd().subscribe((latLng) => {
      if (latLng) {
        this.disableAddressCategory = false;
        if (this.showDefaultLocation) {
          this.showDefaultLocation = false;
        }
        this.addressId.setValue('');
        const { latitude, longitude } = latLng;
        this.center = {
          lat: latitude, lng: longitude
        }
        this.bookAppointmentService.setLatLng(latitude, longitude);
        this.getLocationByLatLong(latitude, longitude);
      };
    });
  }

  setSelectedTabIndex(index: number) {
    if (index === 1) {
      if (!this.otpVerified) {
        if (this.cookieService.check(SESSION_ID)) {
          this.startTimer();
          const sessionId = this.cookieService.get(SESSION_ID);
          this.verifySessionId(sessionId);
        }
      } else {
        this.bookAppointmentService.showMap();
      }
      this.bookAppointmentService.hideDropOffMap();
    }
    else if (index === 2) {
      this.bookAppointmentService.hideMap();
      this.bookAppointmentService.showDropOffMap();
    } else {
      this.bookAppointmentService.hideMap();
      this.bookAppointmentService.hideDropOffMap();
    }

    this.selectedTabIndex = index;
  }

  async callMeBack() {
    if (this.mobileNo.length !== 10 || isNaN(Number(this.mobileNo))) {
      this.successMessage = '';
      this.errorMessage = 'Please enter valid contact number';
      return;
    } else {
      this.successMessage = '';
      this.errorMessage = '';
    }

    console.log('mobile ', this.mobileNo);
    (await this.commonService.callMeBack(this.mobileNo)).subscribe((res: any) => {
      if (res.status === 'SUCCESS') {
        this.mobileNo = '';
        this.successMessage = "Thanks for submitting your number. We'll call you shortly.";
        this.errorMessage = '';
      } else {
        this.successMessage = '';
        this.errorMessage = res.error.message;
      }
    });
  }

  public async handleAddressChange(address: Address) {
    this.disableAddressCategory = false;
    this.categoryId = '';
    const location = address.geometry.location;
    this.formattedAddress = address.formatted_address;

    const isRegisteredAddress = this.isAddressRegistered(address.formatted_address);
    if (isRegisteredAddress) {
      this.categoryId = isRegisteredAddress.categories[0].key;
      this.addressId.setValue(isRegisteredAddress.addressId);
      this.disableAddressCategory = true;
    } else {
      this.addressId.setValue('');
    }
    this.center = {
      lat: location.lat(),
      lng: location.lng(),
    };
    console.log('theicnet er', this.center)
    this.bookAppointmentService.setLatLng(this.center.lat, this.center.lng);
    const localityObj = address.address_components.find((obj: any) => obj.types.includes('locality'));
    this.localityName = localityObj ? localityObj.long_name : '';
  }

  setMapCenter(addressId: string) {
    console.log('map secnte id ', addressId);
    const selectedAddress = this.savedAddresses.find((address: any) => address.addressId === addressId);
    this.formattedAddress = selectedAddress.formattedAddress;
    this.categoryId = selectedAddress.categories[0].key;
    this.localityName = selectedAddress.city;
    this.center = {
      lat: Number(selectedAddress.latitude),
      lng: Number(selectedAddress.longitude)
    };
    this.bookAppointmentService.setLatLng(this.center.lat, this.center.lng);
    this.disableAddressCategory = true;
    console.log('addressiD map center ', this.formattedAddress)
  }

  addressSelected(addressId: string) {
    console.log('selected addres id', addressId)
    this.addressId.setValue(addressId)
  }

  showLocationSearchBox() {
    this.showDefaultLocation = false;
    this.formattedAddress = '';
    this.categoryId = '';
    this.disableAddressCategory = false;
    this.addressId.setValue('');
  }

  // handleDateSelected(event: MatDatepickerInputEvent<moment.Moment, moment.Moment | null>) {
  //   if (event?.value) {
  //     this.appointmentDate = moment(event.value?.toString()).format(BookAppointmentCardComponent.DATE_FORMAT);
  //   }
  // }

  getLocationByLatLong(latitude: any, longitude: any) {
    const geocoder = new google.maps.Geocoder();
    const latlng = new google.maps.LatLng(latitude, longitude);
    geocoder.geocode({
      location: latlng
    }, async (results, status) => {
      if (status === google.maps.GeocoderStatus.OK) {
        if (results) {
          this.formattedAddress = results[0].formatted_address;
          const localityObj = results[0].address_components.find((obj: any) => obj.types.includes('locality'));

          this.localityName = localityObj ? localityObj.long_name : '';

          if (this.addressId.value === '' && this.categoryId) {
            const addressType = this.addressCategories.find((addressCategory: any) => addressCategory.consumerCategoryId === this.categoryId).name;
            this.getHolidaysForNewAddress(this.center.lat, this.center.lng, this.localityName, addressType);
          }
        }
      }
    });
  }

  private startTimer() {
    // Set the interval (in milliseconds) for checking cookie expiry
    const intervalTime = 5000; // 5 seconds

    // Start the interval timer
    this.timer = setInterval(() => {
      this.checkCookieExpiry();
    }, intervalTime);
  }

  private checkCookieExpiry() {
    const cookieExists = this.cookieService.check(SESSION_ID);

    if (cookieExists) {
      // The cookie exists and is not expired.
      // console.log('Cookie exists and is not expired.');
    } else {
      clearInterval(this.timer);
      // The cookie does not exist or has expired.
      this.otpSent = false;
      this.otpVerified = false;
      this.formattedAddress = '';
      this.categoryId = '';
      this.showDefaultLocation = true;
      this.addressId.setValue('');
      this.appointmentDate.reset();
      this.snackbarService.showErrorBar('Session Expired, Please verify again..', 5000)
      // console.log('Cookie does not exist or has expired.');
    }
  }

  ngOnDestroy() {
    clearInterval(this.timerId);
    clearInterval(this.timer);
  }

  onOtpChange(event: any) {
    this.otp = event
  }

  showLoader() {
    this.loading = true;
  }

  hideLoader() {
    this.loading = false;
  }

  startOTPTimer() {
    this.resendOtpTimeLeft = 30;
    const self = this;
    const countdown = () => {
      if (self.resendOtpTimeLeft == 0) {
        clearTimeout(this.timerId);
      } else {
        self.resendOtpTimeLeft--;
      }
    }
    this.timerId = setInterval(countdown, 1000);
  }

  async sendOTP() {
    if (this.locationError) {
      this.snackbarService.showErrorBar('Location permission is required to book appointment. Once you grant permission, please refresh the page to continue', 5000);
      return;
    }

    if (!this.city) {
      this.snackbarService.showErrorBar('To proceed, kindly select a city from the city dropdown located in the header.');
      return;
    }
    this.showDownloadAppMessage = false;
    this.mobileVerificationForm.controls['mobile'].enable();
    if (this.mobileVerificationForm.valid) {
      this.showLoader();
      const data = {
        mobileNo: this.mobileVerificationForm.value.mobile,
        city: this.city
      };
      (await this.authService.sendOtp(data)).subscribe((response: any) => {
        this.hideLoader();
        if (response.status === 'SUCCESS') {
          this.otpSent = true;
          this.mobileVerificationForm.controls['mobile'].disable();
          // this.ngOtpInput?.setValue('');
          this.startOTPTimer();
          this.snackbarService.showSuccessBar(response.data.message);
        } else {
          this.snackbarService.showErrorBar(response.error.message);
        }
      }, () => this.hideLoader());
    } else {
      if (this.mobileVerificationForm.get('mobile')?.invalid) {
        this.snackbarService.showErrorBar('Mobile Number is not valid');
      }
    }
  }

  async getAddressCategories() {
    (await this.commonService.getAddressCategories()).subscribe((res: any) => {
      if (res.status === 'SUCCESS') {
        this.addressCategories = res.data.categories;
      } else {
        this.snackbarService.showErrorBar(res.error.message);
      }
    })
  }

  onOtpEntered(otp: string) {
    console.log('Entered OTP:', otp);
    this.otp = otp;
  }

  async verifySessionId(sid: string) {
    const data = {
      sid: sid
    };
    (await this.authService.verifyOTP(data)).subscribe((response: any) => {
      this.hideLoader();
      if (response.status === 'SUCCESS') {
        this.otpVerified = true;
        this.bookAppointmentService.enableMarkerDrag();
        this.consumerId = response?.data?.userId;
        this.mobileVerificationForm.controls['mobile'].setValue(response?.data?.mobile)
        if (response.data?.addresses?.length > 0) {
          this.savedAddresses = response.data.addresses.filter((address: any) => !address.invalid);
          const defaultAddress = this.savedAddresses.find((address: any) => address.defaultAddress === 'YES');
          if (Boolean(defaultAddress)) {
            this.addressId.setValue(defaultAddress.addressId);
            // this.setMapCenter(defaultAddress.addressId);
          }
        } else {
          const { lat, lng } = this.center;
          this.getLocationByLatLong(lat, lng);
        }
        this.getAddressCategories();
        this.bookAppointmentService.showMap();
      } else {
        this.snackbarService.showErrorBar(response.error.message);
      }
    }, () => this.hideLoader());
  }

  saveSessionId(sid: string) {
    let date = new Date();
    var time = date.getTime();
    var expireTime = time + 1000 * 550;
    console.log('expirt', expireTime);
    this.cookieService.set(SESSION_ID, sid, new Date(expireTime))
  }

  getSessionId() {
    this.cookieService
    return this.cookieService.get(SESSION_ID);
  }

  async verifyOTP() {
    if (this.otp.length === 6) {
      this.showLoader();
      const data = {
        verificationCode: Number(this.otp),
        mobileOrEmail: this.mobileVerificationForm.value['mobile']
      };
      (await this.authService.verifyOTP(data)).subscribe((response: any) => {
        this.hideLoader();
        if (response.status === 'SUCCESS') {
          this.otpVerified = true;
          this.bookAppointmentService.enableMarkerDrag();
          this.consumerId = response?.data?.userId;
          this.saveSessionId(response?.data?.sid);
          if (response.data?.addresses?.length > 0) {
            this.savedAddresses = response.data.addresses.filter((address: any) => !address.invalid);
            const defaultAddress = this.savedAddresses.find((address: any) => address.defaultAddress === 'YES');
            if (Boolean(defaultAddress)) {
              this.addressId.setValue(defaultAddress.addressId);
              this.setMapCenter(defaultAddress.addressId);
            }
          } else {
            const { lat, lng } = this.center;
            this.getLocationByLatLong(lat, lng);
          }
          this.getAddressCategories();
          this.bookAppointmentService.showMap();
        } else {
          this.snackbarService.showErrorBar(response.error.message);
        }
      }, () => this.hideLoader());
    } else {
      this.snackbarService.showErrorBar('Verification code is incorrect');
    }
  }

  isAddressRegistered(formattedAddress: string) {
    return this.savedAddresses.find((address: any) => address.formattedAddress === formattedAddress);
  }

  async bookAppointment() {

    if (!this.formattedAddress) {
      this.snackbarService.showErrorBar('Please select a valid address.');
      return;
    }
    else if (this.appointmentDate.invalid) {
      this.snackbarService.showErrorBar('please select an appointment date.');
      return;
    } else if (!this.categoryId) {
      this.snackbarService.showErrorBar('Please select an address category.');
      return;
    }
    this.showLoader();

    let data: any = {
      mobile: this.mobileVerificationForm.value.mobile,
      addressType: this.addressCategories.find((addressCategory: any) => addressCategory.consumerCategoryId === this.categoryId).name,
      formattedAddress: this.formattedAddress.trim(),
      latitude: this.center.lat,
      longitude: this.center.lng,
      preferredDate: this.appointmentDate.value.format(BookAppointmentCardComponent.DATE_FORMAT),
      addressId: this.addressId.getRawValue() || null,
      items: [],
      preferredSlot: ''
    };

    (await this.commonService.bookAppointment(data)).subscribe(async (response: any) => {
      this.hideLoader();
      if (response.status === 'SUCCESS') {
        this.otpSent = false;
        this.otpVerified = false;
        this.formattedAddress = '';
        this.categoryId = '';
        this.showDefaultLocation = true;
        this.addressId.setValue('');
        this.bookAppointmentService.disableMarkerDrag();
        this.appointmentDate.setValue(moment(new Date()).add('days', 1).format(BookAppointmentCardComponent.DATE_FORMAT));
        this.snackbarService.showSuccessBar(response.data.message, 10000);
      } else {
        this.snackbarService.showErrorBar(response.error.message);
      }
    }, () => { this.hideLoader(); });
    this.mobileVerificationForm.controls['mobile'].enable();
  }

  async getAreaWiseHolidays(areaId: string) {
    this.isholidaysLoading = true;
    (await this.commonService.getAreaWiseHolidays(areaId)).subscribe((res: any) => {
      if (res.status === 'SUCCESS') {
        this.maxDate = moment(new Date()).add('days', res.data.dateCount);
        this.areaWiseHolidays = res.data.holidays.map((holiday: any) => holiday.date);
        this.appointmentDate.setValue(moment(new Date(res.data.defaultAvailableDate)));
        this.holidayMessage = res.data.areaHolidaysMessage;
      } else {
        this.snackbarService.showErrorBar(res.error.message);
      }
      this.isholidaysLoading = false;
    })
  };

  async getHolidaysForNewAddress(lat: number, lng: number, city: string, addressType: string) {
    this.isholidaysLoading = true;
    (await this.commonService.getHolidaysForNewAddress(lat, lng, city, addressType)).subscribe((res: any) => {
      if (res.status === 'SUCCESS') {
        this.maxDate = moment(new Date()).add('days', res.data.dateCount);
        this.areaWiseHolidays = res.data.holidays.map((holiday: any) => holiday.date);
        this.appointmentDate.setValue(moment(new Date(res.data.defaultAvailableDate)));
        this.holidayMessage = res.data.areaHolidaysMessage;
      } else {
        this.areaWiseHolidays = [];
        this.holidayMessage = '';
        if (res.error.code === 'EC_INVALID_AREA') {
          this.snackbarService.showErrorBar("Sorry, we don't serve at the address you provided.");
        } else {
          this.snackbarService.showErrorBar(res.error.message);
        }
      }
      this.isholidaysLoading = false;
    })
  };

  handleAddressCategoryChange(addressCategoryId: string) {
    const addressType = this.addressCategories.find((addressCategory: any) => addressCategory.consumerCategoryId === addressCategoryId).name;
    if (this.addressId.value === '') {
      this.getHolidaysForNewAddress(this.center.lat, this.center.lng, this.localityName, addressType);
    }
  }

  filterHolidays = (date: moment.Moment | null): boolean => {
    const dateFormat = date?.format(BookAppointmentCardComponent.DATE_FORMAT).toString();
    return !this.areaWiseHolidays.includes(dateFormat);
  };
}
