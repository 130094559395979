<form [formGroup]="otpForm" class="otp-container">
  <label for="digit1">Enter OTP</label>
  <input type="tel" id="digit1" #digit1 class="otp-input" [placeholder]="otpInputPlaceholders[0]" maxlength="1"
    (input)="onInputChange(1)" formControlName="digit1" (keydown)="onInputKeydown($event, 1)" autocomplete="off"
    autofocus appNumericOnly>
  <input type="tel" id="digit2" #digit2 class="otp-input" [placeholder]="otpInputPlaceholders[1]" maxlength="1"
    (input)="onInputChange(2)" formControlName="digit2" (keydown)="onInputKeydown($event, 2)" autocomplete="off"
    appNumericOnly>
  <input type="tel" id="digit3" #digit3 class="otp-input" [placeholder]="otpInputPlaceholders[2]" maxlength="1"
    (input)="onInputChange(3)" formControlName="digit3" (keydown)="onInputKeydown($event, 3)" autocomplete="off"
    appNumericOnly>
  <input type="tel" id="digit4" #digit4 class="otp-input" [placeholder]="otpInputPlaceholders[3]" maxlength="1"
    (input)="onInputChange(4)" formControlName="digit4" (keydown)="onInputKeydown($event, 4)" autocomplete="off"
    appNumericOnly>
  <input type="tel" id="digit5" #digit5 class="otp-input" [placeholder]="otpInputPlaceholders[4]" maxlength="1"
    (input)="onInputChange(5)" formControlName="digit5" (keydown)="onInputKeydown($event, 5)" autocomplete="off"
    appNumericOnly>
  <input type="tel" id="digit6" #digit6 class="otp-input" [placeholder]="otpInputPlaceholders[5]" maxlength="1"
    (input)="onInputChange(6)" formControlName="digit6" (keydown)="onInputKeydown($event, 6)" autocomplete="off"
    appNumericOnly>
</form>