import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DropOffComponent } from './dropoff/dropoff.component';
import { CelektStoreSummaryComponent } from './celekt-store-summary/celekt-store-summary.component';
import { CelektAppointmentSummaryComponent } from './celekt-appointment-summary/celekt-appointment-summary.component';

const routes: Routes = [{
  path: 'dropoff/:rrrId',
  component: DropOffComponent
},
{
  path: ':userType/coupon',
  component: CelektStoreSummaryComponent
},
{
  path: ':userType/coupon/summary',
  component: CelektAppointmentSummaryComponent
},
{
  path: ':userType/coupon/summary/:couponOrMobile',
  component: CelektAppointmentSummaryComponent
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CelektRoutingModule { }
