export const REST_APIS = {
  sendOTP: '/api/registration/consumer-mobile',
  verifyOTP: '/api/registration/verification/consumer-mobile',
  bookService: '/api/web/sell/pickup',
  registerConsumer: '/api/websiteRegistration/consumer',
  getServiceCategories: '/api/web/categories/distinct-product-categories/:city?includeIds=true',
  getSubServiceCategories: '/api/web/area/prices/consumer/service',
  addressCategories: '/api/consumer/categories',
  allCities: '/api/city/getAll',
  faqs: '/api/consumer/web/faqs',
  registerServicePartner: '/api/add-lead-web',
  uploadResume: '/api/upload-image',
  privacyPolicy: '/api/info/privacy-policy',
  termsAndConditions: '/api/info/terms-and-conditions',
  cancellationPolicy: '/api/info/cancellation-policy',
  callMeBack: '/api/consumer/triggercall/:mobileNo',
  createOrder: '/api/billdesk/order/create',
  retrieveOrder: '/api/billdesk/transaction/retrieve',
  enablePrePayments: '/api/appointment-properties/enable-pre-payments/:city',
  checkServiceArea: '/api/get/area/:city/:lat/:lng',
  getAllSettings: '/api/platform-settings/get-toggle-on/web/:city',
  getSlots: '/api/web/slots/available/:date/:consumerId',
  productCategories: '/api/categories-city/:city',
  dropoffPoints: '/api/addresses/lng/:lng/lat/:lat/agents',
  impactCreated: '/api/recycling-impact/web',
  verifyRecaptcha: '/api/recaptcha/verify?token=:token',
  registerEWasteRRR: '/api/websiteRegistration/E-WasteRRR/consumer',
  eWasteProductCategories: '/api/categories/productCategory/E-Waste/:city',
  celektAgent: '/api/agent/celekt/:agentId',
  uploadItems: '/api/sell/celekt/items',
  celektCompleteAppointment: '/api/sell/celekt/complete',
  appointmentItems: '/api/sell/celekt/:appointmentId',
  celekUserSells: '/api/sells/celekt/:userId',
  celektCouponSummary: '/api/sell/celekt-coupon/:couponCode',
  uploadVideo: '/api/sell/celekt/upload-video',
  areaWiseHolidays: '/api/area-holidays/web/:areaId',
  holidays: '/api/get-area-holidays/web?lat=:lat&lng=:lng&city=:city&addressType=:addressType'
};
