import { Component } from '@angular/core';

@Component({
  selector: 'app-what-we-do',
  templateUrl: './what-we-do.component.html',
  styleUrls: ['./what-we-do.component.scss']
})
export class WhatWeDoComponent {
  whatWeDo: any;
  activeIndex: number = 2;
  expandedIndex = 0;

  constructor() {
    this.whatWeDo = [
      {
        title: `SQ Recycle HUB`,
        backgroundImage: `/assets/what-we-do/recycle-hub.jpg`,
        desc: `Seamlessly connecting Households, Businesses,
        Industries and Governments with efficient recycling
        solutions. Simplifies sustainability by converting
        100+ recyclables into resources.`,
        link: 'content/blog/0',
        textColor: 'black'
      },
      {
        title: `SQ RoR`,
        backgroundImage: `/assets/what-we-do/ror.jpg`,
        desc: `Excels in the recovery of recyclables,
        operating Material Recovery Facilities at landfills,
        railway stations, and events, all in pursuit
        of a zero-landfill objective`,
        link: 'content/blog/1',
        textColor: 'white'
      },
      {
        title: `SQ Circulomony`,
        backgroundImage: `/assets/what-we-do/circulonomy.jpg`,
        desc: `Propels a circular economy, optimizing
        revenue for all, including consumers, Rag pickers,
        kabadiwalas, and industries, while promoting
        sustainable material reuse for a greener future.`,
        link: 'content/blog/1',
        textColor: 'white'
      },
      {
        title: `SQ Carbon Tech`,
        backgroundImage: `/assets/what-we-do/carbon-tech.jpg`,
        desc: `Quantities recycled carbon footprint,
        enlightness consumers, and empowers industries
        toward minimal emissions, nurturing
        a greener worlds.`,
        link: 'content/blog/1',
        textColor: 'black'
      },
      {
        title: `SQ Zero Carbon`,
        backgroundImage: `/assets/what-we-do/zero-carbon.jpg`,
        desc: `Aids idustries in attaining their net-zero
        carbon & EPR objectives, promoting sustainability
        and eco-friendliness the our`,
        link: 'content/blog/1',
        textColor: 'black'
      },
      {
        title: `SQ e-RRR`,
        backgroundImage: `/assets/what-we-do/rrr.jpg`,
        desc: `EcoStations, enabling effortless e-waste
        recycling, reduction, and reuse for consumers,
        fostering environmental stewardship and
        resource efficiency`,
        link: 'content/blog/1',
        textColor: 'white'
      }
    ]
  }
  ngOnInit() {
    // if (this.whatWeDo.length > 0) {
    //   this.showDescription(this.whatWeDo[0]);
    // }
  }

  get selectedContent() {
    return this.whatWeDo[this.activeIndex];
  }
  showDescription(item: any) {
    this.activeIndex = this.whatWeDo.indexOf(item);
  }

  getBackgroundImage(item: any) {
    return `url(${item.backgroundImage})`;
  }
}
