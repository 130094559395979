// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  API_URL: 'https://devapi.scrapq.com/scrapq-restcontroller',
  staticUrl: 'http://localhost:3000',
  nodeAPIUrl: 'http://143.110.245.106:2418',
  sales_API_URL: 'https://sales-dev.scrapq.com',
  siteKeyV3: '6LdXSpknAAAAADpPOWxeYopWVyOZIvbwSimB8F8E',
  siteKeyV2: '6LdtmK0nAAAAALQc4k0--MrcUm5PeFpuDiYUMRQw'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
