import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { CommonService } from 'src/app/services/common/common.service';
import { SnackBarService } from 'src/app/services/snack-bar/snack-bar.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Params } from '@angular/router';
import { FormControl, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { OtpInputComponent } from '../../otp-input/otp-input.component';
import { DialogImageComponent } from '../dialogs/dialog-image/dialog-image.component';

@Component({
  selector: 'app-celekt-appointment-summary',
  templateUrl: './celekt-appointment-summary.component.html',
  styleUrls: ['./celekt-appointment-summary.component.scss']
})

export class CelektAppointmentSummaryComponent implements OnInit, OnDestroy {
  @ViewChild('otpInput', { static: false }) otpInput!: OtpInputComponent;
  appointmentData: any;
  loading: boolean = false;
  couponOrMobileNumber: any;
  userType: any;
  mobileNo: FormControl = new FormControl('', [Validators.required, Validators.pattern('[0-9]{10}')]);
  otp: FormControl = new FormControl('', [Validators.required, Validators.min(6)])
  resendOtpTimeLeft = 30;
  timerId: any;
  otpSent: boolean = false;
  otpVerified: boolean = false;
  sells: any = [];
  sellIndex: FormControl = new FormControl();
  showNoCouponsCodeMessage: boolean = false;
  showNoCouponNotFound: boolean = false;

  constructor(
    private commonService: CommonService,
    private snackbarService: SnackBarService,
    public domSanitizer: DomSanitizer,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private dialog: MatDialog) {
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.couponOrMobileNumber = params['couponOrMobile'];
      this.userType = params['userType'];

      if (!isNaN(this.couponOrMobileNumber) && /^\d{10}$/.test(this.couponOrMobileNumber)) {
        this.mobileNo.setValue(this.couponOrMobileNumber);
        // this.sendOTP();
      } else if (this.userType === 'store') {
        this.otpSent = true;
        this.otpVerified = true;
        this.getcelektCouponSummary(this.couponOrMobileNumber);
      }
    });

    // this.otpSent = true;
    // this.otpVerified = true;
    // this.getcelektUserSells('64e87244470b69240b175ea0');

    this.sellIndex.valueChanges.subscribe((index) => {
      this.appointmentData = this.sells[index];
    })
  }

  showLoader() {
    this.loading = true;
  }

  hideLoader() {
    this.loading = false;
  }

  getJustifyContentValue(otpSent: boolean) {
    return otpSent ? 'flex-start' : 'center';
  }

  async getcelektCouponSummary(couponCode: string) {
    this.showLoader();
    (await this.commonService.getcelektCouponSummary(couponCode)).subscribe((res: any) => {
      this.hideLoader();
      if (res.status === 'SUCCESS') {
        if (res?.data?.sell) {
          this.sells = [res.data.sell];
          this.sellIndex.setValue(0);
        } else {
          this.showNoCouponNotFound = true;
        }
      } else {
        this.showNoCouponNotFound = true;
        this.snackbarService.showErrorBar(res.error.message);
      }
    }, () => this.hideLoader());
  }

  startOTPTimer() {
    this.resendOtpTimeLeft = 30;
    const self = this;
    const countdown = () => {
      if (self.resendOtpTimeLeft == 0) {
        clearTimeout(this.timerId);
      } else {
        self.resendOtpTimeLeft--;
      }
    }
    this.timerId = setInterval(countdown, 1000);
  }

  ngOnDestroy() {
    clearInterval(this.timerId);
  }

  async sendOTP(isResendOTP = false) {

    if (this.mobileNo.valid || isResendOTP) {
      this.showLoader();
      const data = {
        mobileNo: this.mobileNo.value,
        city: 'Hyderabad',
        celkon: true
      };
      (await this.authService.sendOtp(data)).subscribe((response: any) => {
        this.hideLoader();
        if (response.status === 'SUCCESS') {
          this.otpSent = true;
          this.mobileNo.disable();
          this.otpInput?.resetOtp();
          this.startOTPTimer();
          this.snackbarService.showSuccessBar(response.data.message);
        } else {
          this.snackbarService.showErrorBar(response.error.message);
        }
      }, () => this.hideLoader());
    } else {
      if (this.mobileNo.invalid) {
        this.snackbarService.showErrorBar('Mobile Number is not valid');
      }
    }
  }

  async verifyOTP() {
    if (this.otp.valid) {
      this.showLoader();
      const data = {
        verificationCode: this.otp.value,
        mobileOrEmail: this.mobileNo.value,
        celkon: true
      };
      (await this.authService.verifyOTP(data)).subscribe((response: any) => {
        this.hideLoader();
        if (response.status === 'SUCCESS') {
          this.otpVerified = true;
          clearTimeout(this.timerId);
          this.getcelektUserSells(response.data.userId);
        } else {
          this.snackbarService.showErrorBar(response.error.message);
        }
      }, () => this.hideLoader());
    } else {
      this.snackbarService.showErrorBar('Verification code is incorrect');
    }
  }

  onOtpEntered(otp: string) {
    this.otp.setValue(otp);
  }

  async getcelektUserSells(userId: string) {
    this.showLoader();
    (await this.commonService.getcelektUserSells(userId)).subscribe((response: any) => {
      this.hideLoader();
      if (response.status === 'SUCCESS') {
        this.sells = response.data.sells;
        if (this.sells.length === 0) {
          this.showNoCouponsCodeMessage = true;
        } else {
          this.sellIndex.setValue(0);
        }
      } else {
        this.showNoCouponsCodeMessage = true;
        this.snackbarService.showErrorBar(response.error.message);
      }
    }, () => this.hideLoader());
  }


  showMediaDialog(title: string, src: string, type = 'image') {
    this.dialog.open(DialogImageComponent, {
      data: {
        title: title,
        src: src,
        type
      },
      width: '90%',
      maxWidth: '400px'
    });
  }

}
