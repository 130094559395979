<div class="container">
  <!-- <div> -->
  <mat-toolbar-row class="header">
    <!-- <a href="https://scrapq.com" rel="noopener noreferrer" target="_blank"> -->
    <img src="/assets/mission-e -waste-top-logo.svg" class="logo" height="auto" width="50" />
    <!-- </a> -->
    <a href="https://celekt.com" rel="noopener noreferrer" target="_blank">
      <img src="/assets/celekt logo.svg" class="logo" height="auto" width="50" />
    </a>
  </mat-toolbar-row>
  <div style="flex:1">
    <router-outlet></router-outlet>
  </div>
  <!-- </div> -->

  <footer>
    <div class="row">
      <!-- <div>
        <p>Contact Information :</p>
        <p style="white-space: pre-line;line-height: 1.8; font-size: 0.9rem;">
          {{address}}
        </p>
      </div>

      <div>
        <p>Phone : <a role="button" href="tel:9121007777"><span>+91-91210-07777</span></a></p>
        <p>Email : <a role="button" href="mailto:info@celekt.com"><span>info@celekt.com</span></a>
        </p>
        <p>Whatsapp : <a role="button" href="https://wa.me/919121007777"><span>+91-91210-07777</span></a></p>
      </div> -->

      <!-- <p> <a href="https://scrapq.com" rel="noopener noreferrer" target="_blank">Powered by ScrapQ</a></p> -->
      <a class="row" href="https://scrapq.com" rel="noopener noreferrer" target="_blank">
        <span style="font-size: 20px;">Powered by</span> <img src="/assets/scrapq.png" height="auto" width="150" />
      </a>
      <!-- <p style="display: flex; gap: 5px; justify-content: flex-start;">
          <mat-icon style="color:rgb(178, 177, 177)">phone</mat-icon>
          <a role="button" href="tel:04030707070"><span>040 30 70 70 70 </span></a>
          <a role="button" href="tel:919030727277"><span>+91 90 30 72 72 77</span></a>
        </p> -->
    </div>

  </footer>
</div>