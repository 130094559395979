<div class="global-container">
  <div style="display:grid;grid-template-columns: 0.025fr 0.95fr 0.025fr;justify-content: center;">
    <div (click)="prevSlide()" style="place-self: center;cursor:pointer;">
      <button mat-icon-button aria-label="prev">
        <mat-icon style=" transform: scale(2.5);">arrow_left</mat-icon>
      </button>
    </div>
    <div style="min-width: 0;cursor: grab;">
      <swiper #scrapItemsSwiper [config]="swiperConfig">
        <ng-template swiperSlide *ngFor="let item of data; let i = index">
          <div class="swiper-slide-container" (click)="showItem(i)">
            <img style="cursor: pointer;" [matTooltip]="item.itemTitle" [src]="item.grayImage" alt="Image"
              class="recyclable-img">
            <div class="text-overlay">{{ item.itemTitle }}</div>
          </div>
        </ng-template>
      </swiper>
    </div>
    <div (click)="nextSlide()" style="place-self: center;cursor:pointer;">
      <button mat-icon-button aria-label="prev">
        <mat-icon style=" transform: scale(2.5);">arrow_right</mat-icon>
      </button>
    </div>
  </div>

  <div class="item-content">
    <div><img [src]="data[currentIndex].itemImage" height="auto" width="100%" /></div>
    <div>
      <h1 class="title">{{data[currentIndex].itemTitle}}</h1>
      <p class="desc">{{data[currentIndex].para1}}</p>
      <p class="desc">{{data[currentIndex].para2}}</p>
      <p class="desc" *ngIf="data[currentIndex].currency">{{data[currentIndex].currency}}
        <span style="font-weight: bold;font-size: xx-large;"
          *ngIf="!loading;else showLoader">{{data[currentIndex].amount}}</span>
        <span *ngIf="!loading">{{data[currentIndex].unit}}</span>
      </p>
    </div>
  </div>
</div>

<ng-template #showLoader>
  <span class="loader"></span>
</ng-template>