import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, HostListener, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { GoogleMap, MapInfoWindow, MapMarker } from '@angular/google-maps';
import { Router } from '@angular/router';
import { map } from 'rxjs';
import { CommonService } from 'src/app/services/common/common.service';
import { LocationService } from 'src/app/services/location/location.service';
import { SnackBarService } from 'src/app/services/snack-bar/snack-bar.service';
import { SwiperOptions } from 'swiper';
import { SwiperComponent } from 'swiper/angular';
export interface DropOffAddress {
  userId: string;
  dropoff: boolean;
  rrr: boolean;
  name: string;
  mobileNo: string;
  emailId: string;
  addressId: string;
  formattedAddress: string;
  longitude: string;
  latitude: string;
  distance: string;
  wholesalername: string | null;
  wholesalerid: string | null;
  agentno: string | null;
  slotCount: number;
  agentType: string | null;
  area: string | string[] | null;
}

@Component({
  selector: 'app-dropoff-points-map',
  templateUrl: './dropoff-points-map.component.html',
  styleUrls: ['./dropoff-points-map.component.scss']
})
export class DropoffPointsMapComponent implements OnInit {
  @ViewChild(MapInfoWindow, { static: false }) infoWindow!: MapInfoWindow;
  @ViewChildren(MapMarker) markers!: QueryList<MapMarker>;
  @ViewChild(GoogleMap, { static: false }) dropOffMap!: GoogleMap
  @ViewChild('dropoffAddresses', { static: false }) swiper?: SwiperComponent;

  mapOptions: google.maps.MapOptions = { zoom: 14, mapTypeControl: false, streetViewControl: false };
  markerOptions: google.maps.MarkerOptions = {
    clickable: true,
    icon: {
      url: '/assets/agent-marker.svg',
      scaledSize: { width: 50, height: 50 } as google.maps.Size
    },
  };

  currentLocationMarkerOptions: google.maps.MarkerOptions = {
    clickable: true,
    icon: {
      url: '/assets/consumer-marker.svg',
      scaledSize: { width: 50, height: 50 } as google.maps.Size
    },
    zIndex: 999
  };

  dropOffAddresses: DropOffAddress[] = [];
  mapCenter: google.maps.LatLngLiteral = { lat: 17.4280, lng: 78.4551 };
  infoContent: string = '';
  showDropOffPageMode: boolean = false;

  swiperOptions: SwiperOptions = {
    pagination: { clickable: true },
    slidesPerView: 1,
    spaceBetween: 30,
    speed: 1000,
    grabCursor: true,
    centeredSlides: true
  };
  currentIndex: number = 0;
  screenWidth: any;
  isMobile: boolean = false;

  constructor(
    private locationService: LocationService,
    private snackbarService: SnackBarService,
    private commonService: CommonService,
    private router: Router,
    private breakpointObserver: BreakpointObserver) {
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.screenWidth = window.innerWidth;
  }

  get mapWidth() {
    return this.screenWidth < 768 ? this.screenWidth - 20 : 550;
  }

  get mapHeight() {
    return this.screenWidth < 1100 ? 300 : 400;
  }

  ngOnInit(): void {
    this.showDropOffPageMode = this.router.url.includes('drop-off');
    console.log('url', this.router.url, this.showDropOffPageMode)
    this.locationService.getPosition().then((position) => {
      this.mapCenter = position;
      this.dropOffMap?.googleMap?.setCenter(position);
      this.getDropOffPoints(position.lat, position.lng);
    }).catch((err: GeolocationPositionError) => {
      if (err.code === 1) {
        this.snackbarService.showErrorBar('Location permission is required to view drop-off points on the map. Once you grant permission, please refresh the page to continue.', 6000);
      } else {
        this.snackbarService.showErrorBar('An error occurred while trying to determine your location. Please try again.');
      }
    });


    // Create an observable to track changes in the viewport
    this.breakpointObserver.observe([Breakpoints.Handset, Breakpoints.Small, Breakpoints.Medium, Breakpoints.Large])
      .pipe(
        map(result => result.matches)
      )
      .subscribe(isMobile => {
        this.isMobile = isMobile;
        this.screenWidth = window.innerWidth;
      });
  }

  // method to get the latitude and longitude of a drop-off address
  getLatLng(address: DropOffAddress) {
    return { lat: parseFloat(address.latitude), lng: parseFloat(address.longitude) };
  }

  openInfoCurrentLocationWindow() {
    this.infoContent = `My Current Location`;
    this.infoWindow.open(this.markers.get(0));
  };


  openInfoWindow(windowIndex: number, address: DropOffAddress) {
    this.swiper?.swiperRef.slideTo(windowIndex - 1);
    this.markers.forEach((marker: MapMarker, ix: number) => {
      if (windowIndex === ix) {
        this.infoContent = `<div id="infoWindowContent" style="width:180px;">
        <div style="overflow: hidden;font-weight: 500;font-size: 14px;line-height:1.5;">${address.name} (${address.rrr ? 'RRR' : 'Stationary'})</div>
        <p>${address.formattedAddress}</p>
        <p>Distance : ${address.distance}</p>
        <a style="text-decoration: none;color: #1a73e8;" href="https://www.google.com/maps/dir/${this.mapCenter.lat},${this.mapCenter.lng}/${address.latitude},${address.longitude}"
          target="_blank">Get Directions</a>
      </div>`;
        this.infoWindow.open(marker);
      }
    });
  }

  getDirectionLink(address: DropOffAddress) {
    return `https://www.google.com/maps/dir/${this.mapCenter.lat},${this.mapCenter.lng}/${address.latitude},${address.longitude}`;
  }

  async getDropOffPoints(lat: string, lng: string) {
    (await this.commonService.getDropOffPoints(lat, lng)).subscribe((res: any) => {
      if (res.status === 'SUCCESS') {
        this.dropOffAddresses = res.data.addresses;
      } else {
        this.snackbarService.showErrorBar(res?.data?.errorMessage);
      }
    });
  }

  nextSlide() {
    if (this.currentIndex !== this.dropOffAddresses.length - 1) {
      this.currentIndex = this.currentIndex + 1;
      this.swiper?.swiperRef.slideNext()
      this.swiper?.swiperRef.slideTo(this.currentIndex)
      this.updateMapCenterForSlide(this.currentIndex);
    }
  }

  prevSlide() {
    if (this.currentIndex !== 0) {
      this.currentIndex = this.currentIndex - 1;
      this.swiper?.swiperRef.slidePrev()
      this.swiper?.swiperRef.slideTo(this.currentIndex);
      this.updateMapCenterForSlide(this.currentIndex);
    }
  }

  updateMapCenterForSlide(index: number) {
    const { latitude, longitude } = this.dropOffAddresses[index];
    this.dropOffMap?.googleMap?.panTo({ lat: parseFloat(latitude), lng: parseFloat(longitude) });
    this.openInfoWindow(index + 1, this.dropOffAddresses[index])
  }

  // Method to handle the swiper event
  onSwiper(swiper: any) {
    this.currentIndex = this.swiper!.swiperRef.activeIndex;
    this.updateMapCenterForSlide(this.currentIndex);
  }

}
