import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CityService } from 'src/app/services/city/city.service';
import { CommonService } from 'src/app/services/common/common.service';

@Component({
  selector: 'app-our-network-homepage',
  templateUrl: './our-network-homepage.component.html',
  styleUrls: ['./our-network-homepage.component.scss']
})
export class OurNetworkHomepageComponent {
  contactUsForm: FormGroup;
  successMessage: any;
  failureMessage: any;
  city: any;
  selelctedFile: any;
  submitting = false;

  get name() { return this.contactUsForm.get('name'); }
  get mobile() { return this.contactUsForm.get('mobile'); }
  get email() { return this.contactUsForm.get('email'); }
  get message() { return this.contactUsForm.get('message'); }
  get recaptcha() { return this.contactUsForm.get('recaptcha'); }

  constructor(private formBuilder: FormBuilder,
    private commonService: CommonService,
    private cityService: CityService) {

    this.cityService.getCity().subscribe(city => {
      this.city = city;
    });

    this.contactUsForm = this.formBuilder.group({
      name: ['', [Validators.required, Validators.pattern(/^[a-zA-Z ]{3,}$/)]],
      mobile: ['', [Validators.required, Validators.pattern(/^\d{10}$/)]],
      email: ['', [Validators.required, Validators.email]],
      message: ['', [Validators.required, Validators.maxLength(200)]]
    });
  }

  async onSubmit() {
    if (!this.city) {
      this.failureMessage = 'Please choose a city from the dropdown menu in the header before submitting the form. It helps us serve you better.';
      return;
    }
    this.submitting = true;
    if (this.contactUsForm.valid) {
      const { name, mobile, email, message } = this.contactUsForm.value;
      this.contactUsForm.disable();

      let data: any = {
        mobileNo: mobile,
        email,
        organizationName: "org",
        contactName: name,
        city: this.city,
        comment: message,
        leadStatus: "LEAD",
        userType: "Vendor",
      };

      (await this.commonService.registerServicePartner(data)).subscribe((res: any) => {
        if (res.status === 'Success') {
          this.submitting = false;
          this.successMessage = 'Submission successful! Thank you for your interest. We will get back to you soon. In the meantime, feel free to check out our FAQ section or follow us on social media for updates and tips.';
          this.contactUsForm.reset();
          this.failureMessage = '';
        } else {
          this.submitting = false;
          this.failureMessage = res.error.message;
          this.successMessage = '';
        }
        this.contactUsForm.enable();
      });
    } else {
      this.submitting = false;
      this.failureMessage = '';
      this.successMessage = ''
      // If the form is invalid, mark all fields as touched to trigger validation messages
      this.contactUsForm.markAllAsTouched();
    }
  }

}
