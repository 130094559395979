<mat-sidenav-container>
  <mat-sidenav #sidenav mode="push" position="end">
    <mat-list>
      <mat-list-item style="margin-bottom: 5px;">
        <button mat-icon-button (click)="sidenav.toggle()">
          <mat-icon color="primary">close</mat-icon>
        </button>
      </mat-list-item>
      <mat-divider></mat-divider>
      <mat-list-item routerLink="/" (click)="sidenav.toggle()">
        <span routerLinkActive="link-active" [routerLinkActiveOptions]="{exact:
          true}">Home</span>
      </mat-list-item>
      <mat-divider></mat-divider>
      <mat-list-item routerLink="/about-us" (click)="sidenav.toggle()">
        <span routerLinkActive="link-active">About Us</span>
      </mat-list-item>
      <mat-divider></mat-divider>
      <mat-list-item routerLink="/book-appointment" (click)="sidenav.toggle()"> <span
          routerLinkActive="link-active">Book
          Appointment</span></mat-list-item>
      <mat-divider></mat-divider>
      <mat-list-item routerLink="/our-networks" (click)="sidenav.toggle()"> <span routerLinkActive="link-active">Our
          Networks</span></mat-list-item>
      <mat-divider></mat-divider>
      <mat-list-item routerLink="/faqs" (click)="sidenav.toggle()"> <span routerLinkActive="link-active">Facts &
          FAQ's</span></mat-list-item>
      <mat-divider></mat-divider>
      <mat-list-item routerLink="/contact-us" (click)="sidenav.toggle()"><span routerLinkActive="link-active">Contact
          Us</span></mat-list-item>
      <mat-divider></mat-divider>
      <mat-list-item routerLink="/privacy-policy" (click)="sidenav.toggle()"><span
          routerLinkActive="link-active">Privacy policy</span></mat-list-item>
    </mat-list>
    <mat-divider></mat-divider>
  </mat-sidenav>

  <mat-sidenav-content>
    <app-header (openSideMenuEvent)="sidenav.toggle()"></app-header>
    <router-outlet> </router-outlet>
    <app-footer></app-footer>
  </mat-sidenav-content>
</mat-sidenav-container>