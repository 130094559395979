<div class="global-container">
  <swiper #clientSpeak [config]="swiperConfig" style="padding-bottom: 60px;">
    <ng-template swiperSlide *ngFor=" let item of feedbacks;">
      <div class="feedback-card">
        <div class="feedback-card__profile-image-container">
          <div [style.backgroundImage]="'url(' + item.image + ')'" alt="Profile Image"
            class="feedback-card__profile-image"></div>
        </div>
        <div class="feedback-card__content">
          <img src="/assets/quote1.png" alt="Quote Icon" class="feedback-card__quote-icon" />
          <p class="feedback-card__quote">
            {{ item.feedback }}
          </p>
          <p class="feedback-card__author">{{ item.author }}</p>
        </div>
      </div>
    </ng-template>
  </swiper>
</div>