<div class="main-container">
  <h1 class="title">Privacy Policy</h1>
  <div [innerHTML]="privacyPolicy | safeHtml">
  </div>
</div>

<!-- <section class="container">
  <div>
    <h2 class="title">Privacy Policy</h2>
    <div>
      <div>
        <div class="content" style="border-bottom: 1px solid #75cc75;">
          <p>ScrapQ Hub Private Limited built the ScrapQ website and the app as a free service.
            This SERVICE is provided by ScrapQ Hub Private Limited is intended for use as is.</p>
          <p>
            This page is used to inform visitors of the website and users of our mobile application regarding our
            policies with the collection,
            use, and disclosure of Personal Information if anyone decided to use our Service.</p>
          <p>If you choose to use our Service, then you agree to the collection and use of information in relation
            to this policy. The Personal Information that we collect is used for providing and improving the
            Service.
            We will not use or share your information with anyone except as described in this Privacy Policy.</p>
          <p>The terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, which is
            accessible at ScrapQ unless otherwise defined in this Privacy Policy.</p>
        </div>
        <div>
          <p></p>
        </div>
        <div>
          <ul>
            <details open>
              <summary class="c-title">Your Consent</summary>
              <div class="c-answer">
                <p>By using the ScrapQ Platform and the Services, you agree and consent to the collection, transfer,
                  use, storage, disclosure and sharing of your information as described and collected by us in
                  accordance with this Policy. If you do not agree with the Policy, please do not use or access the
                  ScrapQ Platform.</p>
              </div>
            </details>
            <details class=" c-faq">
              <summary class="c-title">Policy Changes</summary>
              <div class="c-answer">
                <p>
                  We may occasionally update this Policy and such changes will be posted on this page. If we make
                  any significant changes to this Policy we will endeavour to provide you with reasonable notice of
                  such changes, such as via a prominent notice on the ScrapQ Platform or to your email address on
                  record and where required by applicable law, we will obtain your consent. To the extent permitted
                  under the applicable law, your continued use of our Services after we publish or send a notice
                  about our changes to this Policy shall constitute your consent to the updated Policy.
                </p>
              </div>
            </details>
            <details class=" c-faq">
              <summary class="c-title">Information Collection and Use</summary>
              <div class="c-answer">
                <p>For a better experience, while using our Service, we may require you to provide us with certain
                  personally identifiable information, including but not limited to Mobile Phone Number, First Name,
                  Last Name, Address, Advertising Id, Password and Precise Location. The information that we request
                  will be retained by us and used as described in this privacy policy.

                  The app does use third party services that may collect information used to identify you.

                  Link to privacy policy of third party service providers used by the app</p>
                <li style="margin-left: 10px;">
                  <a style="text-decoration: none;" href="https://policies.google.com/privacy" target="_blank">Google
                    Play Services</a>
                </li>
              </div>
            </details>
            <details class=" c-faq">
              <summary class="c-title">Log Data</summary>
              <div class="c-answer">
                <p>We want to inform you that whenever you use our Service, in a case of an error in the app we
                  collect data and information (through third party products) on your phone called Log Data. This
                  Log Data may include information such as your device Internet Protocol (“IP”) address, device
                  name, operating system version,
                  the configuration of the app when utilizing our Service, the time and date of your use of the
                  Service, and other statistics.</p>
              </div>
            </details>
            <details class=" c-faq">
              <summary class="c-title">Cookies</summary>
              <div class="c-answer">
                <p>Cookies are files with a small amount of data that are commonly used as anonymous unique
                  identifiers.
                  These are sent to your browser from the websites that you visit and are stored on your device's
                  internal memory.</p>
                <p>This Service does not use these “cookies” explicitly. However,
                  the app may use third party code and libraries that use “cookies” to collect information and
                  improve their services. You have the option to either accept or refuse these cookies and know when
                  a cookie is being sent to your device.
                  If you choose to refuse our cookies, you may not be able to use some portions of this Service.</p>
              </div>
            </details>
            <details class=" c-faq">
              <summary class="c-title">Service Providers</summary>
              <div class="c-answer">
                <p>We may employ third-party companies and individuals due to the following reasons: </p>
                <ul>
                  <li>To facilitate our Service;</li>
                  <li>To provide the Service on our behalf;</li>
                  <li>To perform Service-related services; or</li>
                  <li>To assist us in analyzing how our Service is used.</li>
                </ul>
                <p>We want to inform users of this Service that these third parties may have access to your Personal
                  Information. The reason is to perform the tasks assigned to them on our behalf. However, they are
                  obligated not to disclose or use the information for any other purpose.</p>
              </div>
            </details>
            <details class=" c-faq">
              <summary class="c-title">Security</summary>
              <div class="c-answer">
                <p>We value your trust in providing us your Personal Information, thus we are striving to use
                  commercially acceptable means of protecting it. But remember that no method of transmission over
                  the internet,
                  or method of electronic storage is 100% secure and reliable, and we cannot guarantee its absolute
                  security.</p>
              </div>
            </details>
            <details class=" c-faq">
              <summary class="c-title">Changes to This Privacy Policy</summary>
              <div class="c-answer">
                <p>We may update our Privacy Policy from time to time. Thus, you are advised to review this page
                  periodically for any changes. We will notify you of any changes by posting the new Privacy Policy
                  on this page.
                  These changes are effective immediately after they are posted on this page.</p>
              </div>
            </details>
            <details class=" c-faq">
              <summary class="c-title">Contact Us</summary>
              <div class="c-answer">
                <p>If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us.
                </p>
                <p>Further, please note that the ScrapQ Platform stores your data with the cloud
                  platform of DigitalOcean provided by DigitalOcean, LLC., which may store this data on their
                  servers located outside of India. DigitalOcean has security measures in place to protect the loss,
                  misuse and alteration of the information, details of which are available at
                  https://digitalocean.com/. The privacy policy adopted by DigitalOcean are
                  detailed in <span> <a style="text-decoration: none;"
                      href="https://www.digitalocean.com/legal/privacy-policy"
                      target="_blank">https://www.digitalocean.com/legal/privacy-policy/</a></span>. In the event
                  you have questions or concerns about the security measures adopted by DigitalOcean, you can
                  contact their data protection / privacy team / legal team or
                  designated the grievance officer at these organisations, whose contact details are available in
                  its privacy policy.</p>
              </div>
            </details>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section> -->