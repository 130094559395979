<div class="main-container">
  <div class="container">
    <h1 class="title">About Us</h1>
    <div class="grid-container">
      <div class="grid-item item1"></div>
      <div class="grid-item item2"></div>
      <div class="grid-item item3"></div>
    </div>

    <div class="info-container">
      <p>ScrapQ is a unique service provider in collection of recyclables,
        enabling recycling,
        confidential data destruction and scrap disposal services in Hyderabad.</p>
      <p>We also customize and provide effective solutions to the
        responsible recycling needs of our esteemed customers.
        Through our network of REO’s and our local level channel partners we ensure to promote our Mission “Recycling is
        Divine”. </p>
      <p>The philosophy at ScrapQ is that the solid waste service business
        is a local business managed by professionals living and
        working in the communities around us hence we strive to act as an aggregator to connect our customers and the
        local Kabbadiwala . At ScrapQ our endeavor is to provide
        service excellence for those communities that place their trust in our company and we are always dedicated to
        putting our customers first.
        We look to technology as a catalyst to help our customers, employees and shareholders “Responsible Recycle” </p>
      <p>We are committed to improving the environment for the future of the
        communities we live in and serve. We believe that our
        services play an integral part in improving environment quality and are mindful of having the most
        cost-effective waste management solutions for our customers.
        We constantly monitor and evaluate new technologies and programs that can enhance our commitment to the
        environment. Within our industry, there is enormous effort made
        toward alternative technologies to reduce landfill disposal,reduce greenhouse gas emissions, and harness
        bio-fuels. </p>
      <p>Scrapq has been actively serving 12000 + customers across Hyderabad
        .
        Whether at single or multi-family residences, commercial institutions, industrial locations,
        construction sites or special events, we provide services to meet the customer needs.
      </p>
      <h3>General Service Standards</h3>
      <ul style="margin: 5px;">
        <li>Multi channel reachablity for your recyclable collection – we are there whenever and wherever you need us.
        </li>
        <li>Customer tailored collection programs with scheduled or on-call pick-ups</li>
        <li>Highly trained staff and Local channel partners – it is our desire to always exceed customer expectations
          and customer delight is our motto.</li>
        <li>Enhancement of the life styles of the local kabbadi partners by continuously addressing their interactions
          and living conditions</li>
        <li>Flexibility to adapt to evolving customer needs.</li>
      </ul>
      <p>Let us be your service provider and experience the ScrapQ
        difference, for making this Earth a better and a healthy place to live.</p>
    </div>
  </div>
</div>