import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonService } from 'src/app/services/common/common.service';
import { SwiperOptions } from 'swiper';
export interface ImpactCreated {
  rowName: "Environment" | "Economic" | "Social";
  fieldDescriptionOne: string;
  fieldDescriptionTwo: string;
  value: string;
  rowOrder: number;
  columnOrder: number;
  enabled: boolean;
}
export interface RowGroup {
  [rowName: string]: ImpactCreated[];
}

export interface ImpactCreatedResponse {
  status: string;
  data: {
    recyclingImpact: ImpactCreatedArray;
    message: string;
  };
}

export interface ImpactCreatedArray extends Array<ImpactCreated> { }

export interface NestedImpactCreatedArray extends Array<ImpactCreatedArray> { }
@Component({
  selector: 'app-impact-created',
  templateUrl: './impact-created.component.html',
  styleUrls: ['./impact-created.component.scss']
})
export class ImpactCreatedComponent implements OnInit {
  breakpoints = {
    '0': {
      slidesPerView: 1,
      spaceBetween: 0
    },
    '768': {
      slidesPerView: 2,
      spaceBetween: 0
    },
    '1024': {
      slidesPerView: 3,
      spaceBetween: 0
    }
  };
  impactCreatedData: NestedImpactCreatedArray = [];

  constructor(private commonService: CommonService) { }

  ngOnInit(): void {
    this.getImpactCreated();
  }

  reshapeData(achievements: ImpactCreatedArray): NestedImpactCreatedArray {
    const rowGroups: RowGroup = {};
    achievements.forEach((achievement) => {
      if (!rowGroups[achievement.rowName]) {
        rowGroups[achievement.rowName] = [];
      }

      rowGroups[achievement.rowName].push(achievement);
    });

    for (const rowName in rowGroups) {
      rowGroups[rowName].sort((a, b) => a.columnOrder - b.columnOrder);
    }
    const sortedRowGroups = Object.values(rowGroups).sort((a, b) => a[0].rowOrder - b[0].rowOrder);
    return sortedRowGroups;
  }

  async getImpactCreated() {
    (await this.commonService.getImpactCreated()).subscribe((res: any) => {
      const response: ImpactCreatedResponse = res;
      if (response.status === 'SUCCESS') {
        this.impactCreatedData = this.reshapeData(response.data.recyclingImpact);
      }
    })
  }

}
