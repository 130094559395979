<div class="global-container">
  <div class="grid-container">
    <ng-container *ngFor="let item of whatWeDo;let index=index;">
      <div class="grid-container__item" [style.backgroundImage]="getBackgroundImage(item)"
        [ngClass]="'grid-container__item__order-'+(index+1)" [style.color]="item.textColor">
        <h2 class="grid-container__item__title">{{item.title}}</h2>
        <p class="grid-container__item__description">{{item.desc}}</p>
        <a [routerLink]="item.link" class="grid-container__item__read-more-btn">Read more &#8594;</a>
      </div>
    </ng-container>
  </div>
</div>