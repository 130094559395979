<div class="main-container">
  <div class="container">
    <h1 class="title">Network</h1>

    <div class="network-image-container">
      <!-- <p class="overlay-title">
      Our Network</p> -->
      <!-- <img src="/assets/Icons-88.png" height="auto" width="100%" /> -->
    </div>

    <div class="tab-container">
      <mat-tab-group style="height:100%;">
        <mat-tab label="Agents">
          <div>
            <p>
              A network of 2300+ agents all across Hyderabad are always ready to help you recycle better.
              These agents are using ScrapQ platform to enhance the cause of "Recycling is Divine"
            </p>
            <div style="border-top: 1px solid lightgray; border-bottom: 1px solid lightgray;">
              <p>City</p>
              <h2>Hyderabad</h2>
            </div>
            <div style=" border-bottom: 1px solid lightgray;">
              <p>Agents</p>
              <h2>2300+</h2>
            </div>
            <div>
              <p>Service Time</p>
              <h2>&#60;30min</h2>
            </div>
          </div>
        </mat-tab>
        <mat-tab label="Corporate">
          <div>
            <p>
              ScrapQ is the most preferred Recycling Partner with 3500+ corporate entities in Hyderabad which
              is growing on daily basis, ranging from Multinationals to SME’s.
            </p>
            <div style="border-top: 1px solid lightgray; border-bottom: 1px solid lightgray;">
              <p>City</p>
              <h2>Hyderabad</h2>
            </div>
            <div style=" border-bottom: 1px solid lightgray;">
              <p>Corporate</p>
              <h2>3500+</h2>
            </div>
            <div>
              <p>Service Time</p>
              <h2>&#60;4hrs</h2>
            </div>
          </div>
        </mat-tab>
        <mat-tab label="Residential">
          <div>
            <p>
              ScrapQ serves around 50000+ households across Hyderabad, we are also tied up with large
              Housing communities all over Hyderabad for all their responsible recycling requirements
            </p>
            <div style="border-top: 1px solid lightgray; border-bottom: 1px solid lightgray;">
              <p>City</p>
              <h2>Hyderabad</h2>
            </div>
            <div style=" border-bottom: 1px solid lightgray;">
              <p>Households</p>
              <h2>50000+</h2>
            </div>
            <div>
              <p>Service Time</p>
              <h2>&#60;30min</h2>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>