import { Component } from '@angular/core';

@Component({
  selector: 'app-our-network',
  templateUrl: './our-network.component.html',
  styleUrls: ['./our-network.component.scss'],

})
export class OurNetworkComponent {
}
