import { Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { AuthService } from 'src/app/services/auth/auth.service';
import { CityService } from 'src/app/services/city/city.service';
import { CommonService } from 'src/app/services/common/common.service';
import { LocationService } from 'src/app/services/location/location.service';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { SnackBarService } from 'src/app/services/snack-bar/snack-bar.service';
import { OtpInputComponent } from '../otp-input/otp-input.component';
import { MapMarker } from '@angular/google-maps';

@Component({
  selector: 'app-book-appointment',
  templateUrl: './book-appointment.component.html',
  styleUrls: ['./book-appointment.component.scss'],
})

export class BookAppointmentComponent implements OnInit, OnDestroy {
  @ViewChild('otpInput', { static: false }) otpInput!: OtpInputComponent; // Access the OtpInputComponent
  @ViewChild(MapMarker) marker!: MapMarker;
  static readonly DATE_FORMAT = 'DD-MMM-YYYY';
  center: google.maps.LatLngLiteral = { lat: 17.428250168737826, lng: 78.4551260126551 };
  otp: any;
  zoom = 18;
  markerOptions: google.maps.MarkerOptions = {
    draggable: false, clickable: false, icon: {
      url: '/assets/consumer-marker.svg',
      scaledSize: { width: 50, height: 50 } as google.maps.Size
    },
  };
  otpSent = false;
  otpVerified = false;
  timerId: any;
  resendOtpTimeLeft = 30;
  formattedAddress = '';
  mobileVerificationForm: FormGroup = new FormGroup({});
  savedAddresses: any[] = [];
  selectedAddress: any = {};
  addressCategories: any;
  categoryId: any = '';
  disableAddressCategory: boolean = false;
  screenWidth: any;
  minDate = moment(new Date()).add('days', 1);
  maxDate = moment(new Date()).add('days', 60);
  city: any;
  localityName = '';
  buttonClick = new Subject();
  options: any;
  loading = false;
  consumerId: any;
  showDownloadAppMessage = false;
  locationError: boolean = false;
  showSavedAddressedDropdown: boolean = true;
  addressId: FormControl = new FormControl('');
  appointmentDate: FormControl = new FormControl(moment(new Date()).add('days', 1));
  areaWiseHolidays: any = [];
  holidayMessage: string = '';
  isholidaysLoading: boolean = false;

  constructor(private locationService: LocationService,
    private authService: AuthService,
    private commonService: CommonService,
    private formBuilder: FormBuilder,
    private cityService: CityService,
    private snackbarService: SnackBarService) {
    this.options = {
      componentRestrictions: { country: 'IN' }
    }

    this.cityService.getCity().subscribe(city => {
      this.city = city;
    });

    this.locationService.getPosition().then((position) => {
      this.locationError = false;
      this.center = position;
    }).catch((err: GeolocationPositionError) => {
      this.locationError = true;
      if (err.code === 1) {
        this.snackbarService.showErrorBar('Location permission is required to book appointment. Once you grant permission, please refresh the page to continue', 5000);
      } else {
        this.snackbarService.showErrorBar('An error occurred while trying to determine your location. Please try again.');
      }
    });

    this.buttonClick.pipe(debounceTime(500)).subscribe(() => {
      this.bookAppointment();
    });
  }

  ngOnInit() {
    this.mobileVerificationForm = this.formBuilder.group({
      mobile: ['', [Validators.required, Validators.pattern('[0-9]{10}')]],
    });

    this.screenWidth = window.innerWidth;

    this.addressId.valueChanges.subscribe(value => {
      if (value) {
        this.setMapCenter(value);
        this.getAreaWiseHolidays(value);
      }
    })

    // this.getAreaWiseHolidays('586e50e6769d7d057ece5566');
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.screenWidth = window.innerWidth;
  }

  get mapWidth() {
    return this.screenWidth < 768 ? this.screenWidth - 20 : 600;
  }

  public async handleAddressChange(address: Address) {
    this.disableAddressCategory = false;
    this.categoryId = '';
    const location = address.geometry.location;
    this.formattedAddress = address.formatted_address;

    const isRegisteredAddress = this.isAddressRegistered(address.formatted_address);
    if (isRegisteredAddress) {
      this.categoryId = isRegisteredAddress.categories[0].key;
      this.disableAddressCategory = true;
      this.addressId.setValue(isRegisteredAddress.addressId);
    } else {
      this.selectedAddress = {};
      this.addressId.setValue('');
    }
    this.center = {
      lat: location.lat(),
      lng: location.lng(),
    };
    const localityObj = address.address_components.find((obj: any) => obj.types.includes('locality'));
    this.localityName = localityObj ? localityObj.long_name : '';
  }

  handleAddressCategoryChange(addressCategoryId: string) {
    const addressType = this.addressCategories.find((addressCategory: any) => addressCategory.consumerCategoryId === addressCategoryId).name;
    if (this.addressId.value === '') {
      this.getHolidaysForNewAddress(this.center.lat, this.center.lng, this.localityName, addressType);
    }
  }

  setMapCenter(addressId: string) {
    this.selectedAddress = this.savedAddresses.find((address: any) => address.addressId === addressId);
    this.formattedAddress = this.selectedAddress.formattedAddress;
    this.categoryId = this.selectedAddress.categories[0].key;
    this.localityName = this.selectedAddress.city;
    this.center = {
      lat: Number(this.selectedAddress.latitude),
      lng: Number(this.selectedAddress.longitude)
    };
    this.disableAddressCategory = true;
  }

  onMarkerDragEnd(event: google.maps.MapMouseEvent) {
    this.disableAddressCategory = false;
    if (this.showSavedAddressedDropdown) {
      this.showSavedAddressedDropdown = false;
    }
    if (event.latLng) {
      this.addressId.setValue('');
      const { lat, lng } = event.latLng.toJSON();
      this.center = {
        lat, lng
      }
      this.getLocationByLatLong(lat, lng);
    }
  }

  getLocationByLatLong(latitude: any, longitude: any) {
    const geocoder = new google.maps.Geocoder();
    const latlng = new google.maps.LatLng(latitude, longitude);
    geocoder.geocode({
      location: latlng
    }, async (results, status) => {
      if (status === google.maps.GeocoderStatus.OK) {
        if (results) {
          this.formattedAddress = results[0].formatted_address;
          const localityObj = results[0].address_components.find((obj: any) => obj.types.includes('locality'));

          this.localityName = localityObj ? localityObj.long_name : '';

          if (this.addressId.value === '' && this.categoryId) {
            const addressType = this.addressCategories.find((addressCategory: any) => addressCategory.consumerCategoryId === this.categoryId).name;
            this.getHolidaysForNewAddress(this.center.lat, this.center.lng, this.localityName, addressType);
          }
        }
      }
    });
  }

  ngOnDestroy() {
    clearInterval(this.timerId);
  }

  onOtpEntered(otp: string) {
    this.otp = otp;
  }

  showLoader() {
    this.loading = true;
  }

  hideLoader() {
    this.loading = false;
  }

  startOTPTimer() {
    this.resendOtpTimeLeft = 30;
    const self = this;
    const countdown = () => {
      if (self.resendOtpTimeLeft == 0) {
        clearTimeout(this.timerId);
      } else {
        self.resendOtpTimeLeft--;
      }
    }
    this.timerId = setInterval(countdown, 1000);
  }

  async sendOTP() {
    if (this.locationError) {
      this.snackbarService.showErrorBar('Location permission is required to book appointment. Once you grant permission, please refresh the page to continue', 5000);
      return;
    }

    if (!this.city) {
      this.snackbarService.showErrorBar('To proceed, kindly select a city from the city dropdown located in the header.');
      return;
    }
    this.showDownloadAppMessage = false;
    this.mobileVerificationForm.controls['mobile'].enable();
    if (this.mobileVerificationForm.valid) {
      this.showLoader();
      const data = {
        mobileNo: this.mobileVerificationForm.value.mobile,
        city: this.city
      };
      (await this.authService.sendOtp(data)).subscribe((response: any) => {
        this.hideLoader();
        if (response.status === 'SUCCESS') {
          this.otpSent = true;
          this.mobileVerificationForm.controls['mobile'].disable();
          this.otpInput?.resetOtp();
          this.startOTPTimer();
          this.snackbarService.showSuccessBar(response.data.message);
        } else {
          this.snackbarService.showErrorBar(response.error.message);
        }
      }, () => this.hideLoader());
    } else {
      if (this.mobileVerificationForm.get('mobile')?.invalid) {
        this.snackbarService.showErrorBar('Mobile Number is not valid');
      }
    }
  }

  async getAddressCategories() {
    (await this.commonService.getAddressCategories()).subscribe((res: any) => {
      if (res.status === 'SUCCESS') {
        this.addressCategories = res.data.categories;
      } else {
        this.snackbarService.showErrorBar(res.error.message);
      }
    })
  }

  async verifyOTP() {
    if (this.otp?.length === 6) {
      this.showLoader();
      const data = {
        verificationCode: this.otp,
        mobileOrEmail: this.mobileVerificationForm.value.mobile
      };
      (await this.authService.verifyOTP(data)).subscribe((response: any) => {
        this.hideLoader();
        if (response.status === 'SUCCESS') {
          this.marker.marker?.setDraggable(true);
          this.otpVerified = true;
          this.consumerId = response?.data?.userId;
          if (response.data?.addresses?.length > 0) {
            this.savedAddresses = response.data.addresses.filter((address: any) => !address.invalid);
            const defaultAddress = this.savedAddresses.find((address: any) => address.defaultAddress === 'YES');
            if (Boolean(defaultAddress)) {
              this.addressId.setValue(defaultAddress.addressId);
            }
          } else {
            const { lat, lng } = this.center;
            this.getLocationByLatLong(lat, lng);
          }
          this.getAddressCategories();
        } else {
          this.snackbarService.showErrorBar(response.error.message);
        }
      }, () => this.hideLoader());
    } else {
      this.snackbarService.showErrorBar('Verification code is incorrect');
    }
  }

  isAddressRegistered(formattedAddress: string) {
    return this.savedAddresses.find((address: any) => address.formattedAddress === formattedAddress);
  }

  async bookAppointment() {

    if (!this.formattedAddress) {
      this.snackbarService.showErrorBar('Please select a valid address.');
      return;
    }

    if (!this.categoryId) {
      this.snackbarService.showErrorBar('Please select an address category.');
      return;
    }

    this.showLoader();

    let data: any = {
      mobile: this.mobileVerificationForm.value.mobile,
      addressType: this.addressCategories.find((addressCategory: any) => addressCategory.consumerCategoryId === this.categoryId).name,
      formattedAddress: this.formattedAddress.trim(),
      latitude: this.center.lat,
      longitude: this.center.lng,
      preferredDate: this.appointmentDate.value.format(BookAppointmentComponent.DATE_FORMAT),
      addressId: this.addressId.value || null,
      items: [],
      preferredSlot: ''
    };

    (await this.commonService.bookAppointment(data)).subscribe(async (response: any) => {
      this.hideLoader();
      if (response.status === 'SUCCESS') {
        this.otpSent = false;
        this.otpVerified = false;
        this.showDownloadAppMessage = true;
        this.formattedAddress = '';
        this.categoryId = '';
        this.showSavedAddressedDropdown = true;
        this.addressId.setValue('');
        this.appointmentDate.reset();
        this.marker.marker?.setDraggable(false);
        this.snackbarService.showSuccessBar(response.data.message, 10000);
      } else {
        this.snackbarService.showErrorBar(response.error.message);
      }
    }, () => { this.hideLoader(); });
    this.mobileVerificationForm.controls['mobile'].enable();
  }

  async getAreaWiseHolidays(areaId: string) {
    this.isholidaysLoading = true;
    (await this.commonService.getAreaWiseHolidays(areaId)).subscribe((res: any) => {
      if (res.status === 'SUCCESS') {
        this.maxDate = moment(new Date()).add('days', res.data.dateCount);
        this.areaWiseHolidays = res.data.holidays.map((holiday: any) => holiday.date);
        this.appointmentDate.setValue(moment(new Date(res.data.defaultAvailableDate)));
        this.holidayMessage = res.data.areaHolidaysMessage;
      } else {
        this.snackbarService.showErrorBar(res.error.message);
      }
      this.isholidaysLoading = false;
    })
  };

  async getHolidaysForNewAddress(lat: number, lng: number, city: string, addressType: string) {
    this.isholidaysLoading = true;
    (await this.commonService.getHolidaysForNewAddress(lat, lng, city, addressType)).subscribe((res: any) => {
      if (res.status === 'SUCCESS') {
        this.maxDate = moment(new Date()).add('days', res.data.dateCount);
        this.areaWiseHolidays = res.data.holidays.map((holiday: any) => holiday.date);
        this.appointmentDate.setValue(moment(new Date(res.data.defaultAvailableDate)));
        this.holidayMessage = res.data.areaHolidaysMessage;
      } else {
        this.areaWiseHolidays = [];
        this.holidayMessage = '';
        if (res.error.code === 'EC_INVALID_AREA') {
          this.snackbarService.showErrorBar("Sorry, we don't serve at the address you provided.");
        } else {
          this.snackbarService.showErrorBar(res.error.message);
        }
      }
      this.isholidaysLoading = false;
    })
  };

  filterHolidays = (date: moment.Moment | null): boolean => {
    const dateFormat = date?.format(BookAppointmentComponent.DATE_FORMAT).toString();
    return !this.areaWiseHolidays.includes(dateFormat);
  };
}


