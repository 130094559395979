import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';

import { CelektRoutingModule } from './celekt-routing.module';

// Third Party Modules
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { SwiperModule } from 'swiper/angular';

// Third Party Services
import { NgxImageCompressService } from 'ngx-image-compress';

// Angular Material Modules
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatCardModule } from '@angular/material/card';
import { MatListModule } from '@angular/material/list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatInputModule } from '@angular/material/input';

// Custom Modules
import { OtpInputModule } from '../otp-input/otp-input.module';

// Custom Components
import { CelektAppointmentSummaryComponent } from './celekt-appointment-summary/celekt-appointment-summary.component';
import { CelektStoreSummaryComponent } from './celekt-store-summary/celekt-store-summary.component';
import { DropOffComponent, MessageDialog } from './dropoff/dropoff.component';
import { DialogImageComponent } from './dialogs/dialog-image/dialog-image.component';
import { MatButtonModule } from '@angular/material/button';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatDateFormats } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';

export const MY_FORMATS: MatDateFormats = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD-MMM-YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};


@NgModule({
  declarations: [
    DropOffComponent,
    CelektAppointmentSummaryComponent,
    CelektStoreSummaryComponent,
    MessageDialog,
    DialogImageComponent,
  ],
  imports: [
    CommonModule,
    GoogleMapsModule,
    FormsModule,
    ReactiveFormsModule,

    CelektRoutingModule,

    MatFormFieldModule,
    MatDatepickerModule,
    MatDialogModule,
    MatIconModule,
    MatSelectModule,
    MatCardModule,
    MatListModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatInputModule,

    OtpInputModule,

    GooglePlaceModule,
    SwiperModule
  ],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: MY_FORMATS
    },
    NgxImageCompressService
  ]
})
export class CelektModule { }
