import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRouteSnapshot, ActivatedRoute } from '@angular/router';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PageTitleService {

  constructor(private router: Router,
    private titleService: Title,
    private activatedRoute: ActivatedRoute) { }

  init() {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        const pageTitle = this.getPageTitle(this.activatedRoute.snapshot.root);
        this.titleService.setTitle(pageTitle);
      });
  }

  getPageTitle(route: ActivatedRouteSnapshot): string {
    let pageTitle = '';
    const routeData: any = route.data;

    if (!route.parent) {
      pageTitle = 'ScrapQ'; // Add 'ScrapQ' prefix only for top-level routes
    }

    if (routeData && routeData.title) {
      const titleFromUrl = routeData.title;
      pageTitle += pageTitle ? ' | ' + titleFromUrl : titleFromUrl;
    } else {
      const titleFromUrl = route.url[0]?.path; // Get the first segment of the URL

      if (titleFromUrl) {
        const formattedTitle = this.formatTitleFromUrl(titleFromUrl);
        pageTitle += pageTitle ? ' | ' + formattedTitle : formattedTitle;
      }
    }

    if (route.firstChild) {
      const childPageTitle = this.getPageTitle(route.firstChild);
      if (childPageTitle) {
        pageTitle += ' | ' + childPageTitle;
      }
    }

    return pageTitle;
  }

  formatTitleFromUrl(urlSegment: string): string {
    // Remove special characters, replace hyphens with spaces, and capitalize the first letter of each word
    const formattedSegment = urlSegment.replace(/-/g, ' ').replace(/[^\w\s]/g, '').replace(/\b\w/g, match => match.toUpperCase());
    return formattedSegment;
  }
}
