import { Component } from '@angular/core';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  address = `ScrapQ HeadQuarters\nThakur Mansion Ln, Durga Nagar, Somajiguda,\nHyderabad, Telangana, 500082`;
  currentYear = (new Date()).getFullYear();
  constructor() { }
}
