
import { Component } from '@angular/core';
import { SwiperOptions } from 'swiper';

@Component({
  selector: 'app-client-speak',
  templateUrl: './client-speak.component.html',
  styleUrls: ['./client-speak.component.scss']
})
export class ClientSpeakComponent {
  feedbacks: any = [];

  swiperConfig: SwiperOptions = {
    allowTouchMove: true,
    pagination: {
      clickable: true
    },
    preloadImages: true,
    grabCursor: true,
    breakpoints: {
      200: {
        slidesPerView: 1,
        spaceBetween: 10
      },
      // 320: {
      //   slidesPerView: 1,
      //   spaceBetween: 0
      // },
      // 480: {
      //   slidesPerView: 2
      // },
      // 768: {
      //   slidesPerView: 2,
      //   spaceBetween: 20
      // },
      1024: {
        slidesPerView: 2,
        spaceBetween: 10
      },
      1600: {
        slidesPerView: 3,
        spaceBetween: 10
      }
    }
  }

  constructor() {
    this.feedbacks =
      [
        {
          feedback: `We are very happy with the promptness and thoroughness with which your staff completed their job at our residence.
              They did a fine job. We wish to continue availing your services in future. Thank you.`,
          author: `Air Commander Ramu`,
          image: '/assets/customer-feedback/sample-image.jpg'
        },
        {
          feedback: `Because of you guys our place looks clean and nice. Now we know how and where we can sell our empty bottles.
            Till now we don't have any complaint or problem. Thank you for all your services and your precious time.`,
          author: ` Lameka`,
          image: '/assets/customer-feedback/sample-image.jpg'
        },
        {
          feedback: `Excellent service by ScrapQ. Very much satisfied with the service. Go for it.`,
          author: `Ravinder`,
          image: '/assets/customer-feedback/sample-image.jpg'
        },
        {
          feedback: `Excellent service. Prompt pickup and weighing with digital weighing machine`,
          author: `Aparna`,
          image: '/assets/customer-feedback/sample-image.jpg'
        }
      ];

  }
}
