<div class="global-container global-container--without-mx">
  <div class="grid-container">
    <div class="grid-item">
      <div class="overlay">
        <h2 class="title">Our Network</h2>
        <p class="sub-title">Of PAN India Stakeholders and Partners</p>
        <div class="network-info">
          <div>
            <p>2500+</p>
            <span>Agents</span>
          </div>
          <div class="divider"></div>
          <div>
            <p>3500+</p>
            <span>Corporate</span>
          </div>
          <div class="divider"></div>
          <div>
            <p>25+</p>
            <span>Years of Experience</span>
          </div>
        </div>
        <div class="appstore-images">
          <img src="/assets/app-store/android.png" height="50" />
          <img src="/assets/app-store/appstore.png" height="50" />
        </div>
        <button class="submit-button" routerLink="contact-us">Join Us</button>
      </div>
    </div>
    <div class="grid-item">
      <h2 class="title">Contact Us</h2>
      <p class="sub-title">If you wish to reach out to us, please fill in your details. Our team will get back to you
        soon.</p>
      <form [formGroup]="contactUsForm" (ngSubmit)="onSubmit()">
        <div class="container">
          <input type="text" id="name" formControlName="name" class="custom-input" name="name" placeholder="Name"
            required />
          <div class="error-message" *ngIf="name && name.invalid && (name.dirty || name.touched)">
            <div *ngIf=" name.errors && name.errors['required']">Name is required.</div>
            <div *ngIf="name.errors && name.errors['pattern']">Name must contain only alphabets and be at least 3
              letters long.</div>
          </div>

          <input type="tel" id="mobile" formControlName="mobile" class="custom-input" name="phone"
            placeholder="Mobile Number" maxlength="10" required />
          <div class="error-message" *ngIf=" mobile && mobile.invalid && (mobile.dirty || mobile.touched)">
            <div *ngIf="mobile.errors && mobile.errors['required']">Mobile number is required.</div>
            <div *ngIf="mobile.errors && mobile.errors['pattern']">Mobile number must be a 10-digit number.</div>
          </div>

          <input type="email" id="email" formControlName="email" class="custom-input" name="email"
            placeholder="Email ID" required />
          <div class="error-message" *ngIf="email && email.invalid && (email.dirty || email.touched)">
            <div *ngIf="email.errors && email.errors['required']">Email is required.</div>
            <div *ngIf="email.errors && email.errors['email']">Email must be a valid email address.</div>
          </div>

          <input id="message" formControlName="message" class="custom-input" name="message"
            placeholder="Organization Name" required />
          <div class="error-message" *ngIf="message && message.invalid && (message.dirty || message.touched)">
            <div *ngIf="message.errors && message.errors['required']">Organization Name is required.</div>
            <div *ngIf="message.errors && message.errors['maxlength']">Organization Name must be no more than 200
              characters.
            </div>
          </div>
          <div class="row">
            <button class="submit-button" type="submit" [class.submitting]="submitting" [disabled]="submitting">
              <mat-spinner color="white" diameter="20" *ngIf="submitting"></mat-spinner>
              <span *ngIf="submitting">Submitting</span>
              <span *ngIf="!submitting">Submit</span>
            </button>
          </div>

          <p class="errorMessage" *ngIf="failureMessage">{{failureMessage}}</p>
          <p class="succesMessage" *ngIf="successMessage">{{successMessage}}</p>
        </div>
      </form>
    </div>
  </div>
</div>