import { Component } from '@angular/core';
import { SwiperOptions } from 'swiper';

@Component({
  selector: 'app-innovations',
  templateUrl: './innovations.component.html',
  styleUrls: ['./innovations.component.scss']
})
export class InnovationsComponent {
  innovations = [
    {
      title: 'For Customer',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed diam nonummy nibh',
      imageUrl: '/assets/innovations/customer.jpg', // Replace with your image URL
    },
    {
      title: 'For Agents',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed diam nonummy nibh',
      imageUrl: '/assets/innovations/agent.jpg', // Replace with your image URL
    },
    {
      title: 'For Recycler',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed diam nonummy nibh',
      imageUrl: '/assets/innovations/recycler.jpg', // Replace with your image URL
    },
  ];

  swiperConfig: SwiperOptions = {
    // allowTouchMove: true,
    // initialSlide: 1,
    // effect: 'coverflow',
    pagination:true,
    spaceBetween: 10,
    breakpoints: {
      200: {
        slidesPerView: 1,
        spaceBetween: 10
      },
      500: {
        slidesPerView: 2,
        spaceBetween: 10
      },
      768: {
        slidesPerView: 3,
        spaceBetween: 10,
      },
      // 992: {},

      // 1024: {
      //   slidesPerView: 1,
      //   spaceBetween: 30,
      // }

    }
  }
}
