import { Component, OnInit, ViewChild } from '@angular/core';
import { CityService } from 'src/app/services/city/city.service';
import { CommonService } from 'src/app/services/common/common.service';
import SwiperCore, { SwiperOptions, Virtual } from 'swiper';
import { SwiperComponent } from 'swiper/angular';

SwiperCore.use([Virtual]);

interface RecyclableItem {
  grayImage: string;
  greenImage: string;
  itemTitle: string;
  itemImage: string;
  para1: string;
  para2: string;
  amount: string;
  currency: string;
  unit: string;
  categoryId?: string;
}
@Component({
  selector: 'app-recyclables',
  templateUrl: './recyclables.component.html',
  styleUrls: ['./recyclables.component.scss']
})
export class RecyclablesComponent implements OnInit {
  @ViewChild('scrapItemsSwiper', { static: false }) swiper?: SwiperComponent;
  currentIndex: number = 0;

  data: RecyclableItem[] = [
    {
      "grayImage": "assets/recyclabels_categories/paper.jpg",
      "greenImage": "assets/recyclabels_categories/paper.jpg",
      "itemTitle": "NEWS PAPER",
      "itemImage": "assets/NewsPaper.jpg",
      "para1": "24 trees are cut down to create 1000 kgs of paper, these trees can\n     be saved if we recycle this newspaper. At ScrapQ ,we promote\n     recycling as it not only saves our trees but also reduces the usage\n      of energy to produce new paper by almost 75%.",
      "para2": "Now, just book an appointment with ScrapQ to recycle your\n    Newspaper @Uniform rate , with Accurate Weight and Instant Payment ",
      "amount": "15.00",
      "currency": "Rs.",
      "unit": "/Kg",
      "categoryId": "56ef8a90df72ee13f4e3b98f"
    },
    {
      "grayImage": "assets/recyclabels_categories/plastic.jpg",
      "greenImage": "assets/recyclabels_categories/plastic.jpg",
      "itemTitle": "PLASTICS",
      "itemImage": "assets/Plastic.jpg",
      "para1": "An estimated 60 lakh tones of plastic goes un-recycled in India\n    every year, which takes 1000 yrs to degrade ,the amount of plastic\n    discarded every year across the world can circle the earth 4 times.",
      "para2": "Recycling plastic uses only 12% of the energy required to produce new plastic. Recycling will also reduce the deterioration of landfills.Now recycle plastic at ScrapQ and be a responsible recycler.",
      "amount": "20.00",
      "currency": "Rs.",
      "unit": "/Kg",
      "categoryId": "56ef8acfdf72ee13f4e3b993"
    },
    {
      "grayImage": "assets/recyclabels_categories/metal.jpg",
      "greenImage": "assets/recyclabels_categories/metal.jpg",
      "itemTitle": "METALS",
      "itemImage": "assets/Iron.jpg",
      "para1": "Iron is used on daily basis, this iron can be recycled , remolded to make new reusable iron , this would help our national economy and the environment, e.g Increased local employment and lesser emissions.",
      "para2": "ScrapQ specializes in responsibly recycling of iron, we take utmost care to ensure every kilo of iron collected by us is recycled.Recycle iron with ScrapQ at your place and at your convenient time.",
      "amount": "20.00",
      "currency": "Rs.",
      "unit": "/Kg",
      "categoryId": "56ef8da4b1ceb611787cb858"
    },
    // {
    //   "grayImage": "assets/recyclables/recyclable-steel-gray.png",
    //   "greenImage": "assets/recyclables/recyclable-steel-green.png",
    //   "itemTitle": "STEEL",
    //   "itemImage": "assets/Steel.jpg",
    //   "para1": "Recycling 1 ton of steel will save 1136 kgs of iron ore, 454 kgs of coal and 18 kg of limestone. Recycled steel has supported many industries to reduce their environment impact eg. reduced mining of natural and scant resources.",
    //   "para2": "You can also be a part of this saving of Mother Earth partnering with ScrapQ to recycle steel to ensure reduction of landfills , leading to positive effect.",
    //   "amount": "33.00",
    //   "currency": "Rs.",
    //   "unit": "/Kg",
    //   "categoryId": "589d8192769d7d28c71e8565"
    // },
    // {
    //   "grayImage": "assets/recyclables/recyclable-refrigerator-gray.png",
    //   "greenImage": "assets/recyclables/recyclable-refrigerator-green.png",
    //   "itemTitle": "REFRIGERATOR",
    //   "itemImage": "assets/Refrigerator.jpg",
    //   "para1": "Disposing obsolete refrigerator or freezer unit improperly effects environment adversely. These appliances contain CFC-11, which is an environmentally destructive gas contributing recent climatic changes. These units if left will rot our scarce landfills, as they emit harmful amount of carbon dioxide while degrading.",
    //   "para2": "ScrapQ partners with authorized recycler of obsolete refrigerators to ensure responsible recycling.",
    //   "amount": "800.00",
    //   "currency": "Rs.",
    //   "unit": "/Per Unit",
    //   "categoryId": "589d7fdd769d7d28c71e8545"
    // },
    // {
    //   "grayImage": "assets/recyclables/recyclable-microwave-gray.png",
    //   "greenImage": "assets/recyclables/recyclable-microwave-green.png",
    //   "itemTitle": "MICROWAVE OVEN",
    //   "itemImage": "assets/MicroWave.jpg",
    //   "para1": "Microwave ovens are most common e-waste items that cannot be disposed along with regular trash. Some microwave energy may leak from your old oven when it becomes obsolete or is not disposed properly, but this would pose no known health risks, as long as the oven is recycled properly in time.",
    //   "para2": "We at ScrapQ have partnered with authorized recycler of old Microwaves to ensure responsible recycling.",
    //   "amount": "175.00",
    //   "currency": "Rs.",
    //   "unit": "/Per Unit",
    //   "categoryId": "589d800b769d7d28c71e8549"
    // },
    {
      "grayImage": "assets/recyclabels_categories/glass.jpg",
      "greenImage": "assets/recyclabels_categories/glass.jpg",
      "itemTitle": "GLASS/BOTTLES",
      "itemImage": "assets/Glass Bottles.jpg",
      "para1": "Recycling one bottle can save enough energy to power a television set for one and a half hours. Melting of recycled glass consumes very less amount of energy than required to melt raw materials to make new bottles. Glass recycling helps the environment considerably.",
      "para2": "Now just recycle old glass items with ScrapQ and become an environment savior.",
      "amount": "2.50",
      "currency": "Rs.",
      "unit": "/Kg",
      "categoryId": "589d7f14769d7d28c71e8539"
    },
    // {
    //   "grayImage": "assets/recyclables/recyclable-laptop-scrap-gray.png",
    //   "greenImage": "assets/recyclables/recyclable-laptop-scrap-green.png",
    //   "itemTitle": "LAPTOPS",
    //   "itemImage": "assets/Laptop Scrap.jpg",
    //   "para1": "The only way to dispose your old defunct laptop is Recycle with ScrapQ, by recycling the old laptop you are actively avoiding air, water pollution greenhouse emissions.",
    //   "para2": "Recycling 1 lakh old laptops will save energy which can provide street lighting to 500 Indian villages across the year.Now just pick up that old defunct laptop and hand it over to our REO at your desired place and time.",
    //   "amount": "100.00",
    //   "currency": "Rs.",
    //   "unit": "/Per Unit",
    //   "categoryId": "61cd8e2f769d7d0d5e139dce"
    // },
    // {
    //   "grayImage": "assets/recyclables/recyclable-regular-carton-gray.png",
    //   "greenImage": "assets/recyclables/recyclable-regular-carton-green.png",
    //   "itemTitle": "REGULAR CARTON",
    //   "itemImage": "assets/Regular cartoons.jpg",
    //   "para1": "Making the pulp used in manufacturing cardboard creates sulfur dioxide, a gas that causes acid rain harmful to us. Recycling cardboard cuts that pollution in half. Even Waxed, wet, and soiled cardboard which is not recyclable can be composted as manure to plants.",
    //   "para2": "At ScrapQ, recycling your used card boards is just a missed call away",
    //   "amount": "11.00",
    //   "currency": "Rs.",
    //   "unit": "/Kg",
    //   "categoryId": "572b0ef338836e093240aac1"
    // },
    {
      "grayImage": "assets/recyclabels_categories/aluminium.jpg",
      "greenImage": "assets/recyclabels_categories/aluminium.jpg",
      "itemTitle": "ALUMINIUM",
      "itemImage": "assets/Aluminium.jpg",
      "para1": "Compared to virgin aluminum from raw bauxite, recycling aluminum takes just 5%of the energy with less than 5%of the greenhouse emission. Aluminum loses none of its integrity even when melted down repeatedly, the whole recycling process takes 60 days flat. Recycling 96 aluminium cans gives enough energy to keep a laptop running for one month.",
      "para2": "Give a missed call to our REX, our ReO is at your doorstep.",
      "amount": "100.00",
      "currency": "Rs.",
      "unit": "/Kg",
      "categoryId": "56ef8b33df72ee13f4e3b99b"
    },

    {
      "grayImage": "assets/recyclabels_categories/others.jpg",
      "greenImage": "assets/recyclabels_categories/others.jpg",
      "itemTitle": "OTHERS",
      "itemImage": "assets/mobilemdpi.png",
      "para1": "Mobile phones have grown from mere communication tools to indispensable everyday devices. Comprising an intricate blend of metals, plastics, and glass. Over 1.5 billion mobile phones are sold annually worldwide and only about 20% of old mobile phones are recycled, even though recycling them can conserve valuable resources and reduce the environmental impact.",
      "para2": "As models quickly become outdated due to rapid technological advancements, recycling becomes even more critical. Doing so not only ensures the recovery of precious metals but also prevents the accumulation of e-waste.",
      "amount": "",
      "currency": "",
      "unit": ""
    },
    // {
    //   "grayImage": "assets/recyclables/recyclable-bb-carton-gray.png",
    //   "greenImage": "assets/recyclables/recyclable-bb-carton-green.png",
    //   "itemTitle": "BB CARTON",
    //   "itemImage": "assets/BB Carton.jpg",
    //   "para1": "With advent of online e-retailers, affordable packaging which is light weight but strong available in variable sizes are used for delivery of goods, these cartons called BB cartons are hard to manufacture as it consumes high amount of plant fibre for strength.",
    //   "para2": "Recycling these cartons at ScrapQ stops cutting of trees for plant fibre, also lessens the carbon emissions emitted.",
    //   "amount": "3.00",
    //   "currency": "Rs.",
    //   "unit": "/Kg",
    //   "categoryId": "589d7f7d769d7d28c71e853d"
    // },
    // {
    //   "grayImage": "assets/recyclables/recyclable-cd-gray.png",
    //   "greenImage": "assets/recyclables/recyclable-cd-green.png",
    //   "itemTitle": "CD",
    //   "itemImage": "assets/CD.jpg",
    //   "para1": "Compact Discs are one of the major components of landfills in last decade, it has been resulting into damage of our environment as CD’s don’t get destroyed readily, overtime they release Bisphenol which cause major health implications to humans.",
    //   "para2": "ScrapQ and its partners specializes in responsibly recycling compact discs avoiding them landing into furnaces resulting polluting the environment.",
    //   "amount": "5.00",
    //   "currency": "Rs.",
    //   "unit": "/Kg",
    //   "categoryId": "589d812e769d7d28c71e855d"
    // },
    // {
    //   "grayImage": "assets/recyclables/recyclable-cassette-gray.png",
    //   "greenImage": "assets/recyclables/recyclable-cassette-green.png",
    //   "itemTitle": "CASSETTE",
    //   "itemImage": "assets/Cassette.jpg",
    //   "para1": "You can recycle your collection of old audio cassette, videotapes known as magnetic media, but not without ScrapQ. Tape casings are of recyclable plastic but the inner tape is not. Mylar sometimes is coated with chromium and other harmful metals means they can't go into landfill as magnetic media does not break down for hundreds of years.",
    //   "para2": "Just log on to our ScrapQ App, let our REO do the rest.",
    //   "amount": "5.00",
    //   "currency": "Rs.",
    //   "unit": "/Kg",
    //   "categoryId": "589d8157769d7d28c71e8561"
    // },
    // {
    //   "grayImage": "assets/recyclables/recyclable-copper-gray.png",
    //   "greenImage": "assets/recyclables/recyclable-copper-green.png",
    //   "itemTitle": "COPPER",
    //   "itemImage": "assets/Cooper.jpg",
    //   "para1": "Copper has been recycled for almost hundred years, fact is that almost all that copper is still in use. As copper’s recycling rate is greater than other metals the amount of copper recycled is equal to new copper mined annually. We at ScrapQ believe in recycling every kilogram of copper as it boosts the economy but also is a source of income to all our customers.",
    //   "para2": "Just SMS to book your appointment now.",
    //   "amount": "350.00",
    //   "currency": "Rs.",
    //   "unit": "/Kg",
    //   "categoryId": "589d81bc769d7d28c71e8569"
    // },
    // {
    //   "grayImage": "assets/recyclables/recyclable-cable-gray.png",
    //   "greenImage": "assets/recyclables/recyclable-cable-green.png",
    //   "itemTitle": "CABLES",
    //   "itemImage": "assets/Cables.jpg",
    //   "para1": "Whatever you do, cables should never go in the garbage. They contain metals like copper that make them hazardous waste if they’re put in the trash or in landfills.",
    //   "para2": "Regulatory licenses are pre-requisites for cable recycling, ScrapQ has partnered with licensed cable recyclers all across, Now you can just SMS to 90 30 72 72 77 .",
    //   "amount": "22.00",
    //   "currency": "Rs.",
    //   "unit": "/Kg",
    //   "categoryId": "5aed5c73769d7d7f7dba1f97"
    // },
    // {
    //   "grayImage": "assets/recyclables/recyclable-automobile-scrap-gray.png",
    //   "greenImage": "assets/recyclables/recyclable-automobile-scrap-green.png",
    //   "itemTitle": "AUTOMOBILE SCRAP",
    //   "itemImage": "assets/Automobile Scrap.jpg",
    //   "para1": "Automobile scrap has been on steep rise in the last 20 yrs , A car weighs 1,400-1,600 kgs. Upon recycling, it generates 65-70% steel scrap, 7-8% aluminum scrap, 1-1.5% copper scrap and 15-20% rubber and plastic scrap. At ScrapQ, these items are recycled in an organized way through our licensed recycle partners.",
    //   "para2": "Now download our ScrapQ App to recycle your automobile scrap responsibly.",
    //   "amount": "16.00",
    //   "currency": "Rs.",
    //   "unit": "/Kg",
    //   "categoryId": "5aed5b56769d7d7f7dba1f7e"
    // },
    // {
    //   "grayImage": "assets/recyclables/recyclable-books-gray.png",
    //   "greenImage": "assets/recyclables/recyclable-books-green.png",
    //   "itemTitle": "BOOKS",
    //   "itemImage": "assets/Books.jpg",
    //   "para1": "24 trees are cut down to create 700 kgs of books, these trees can be saved if we recycle these used books . At ScrapQ ,we promote recycling as it not only saves our trees but also reduces the usage of energy to produce new paper by almost 75%.",
    //   "para2": "Now, just book an appointment with ScrapQ to recycle your old books @Uniform rate , with Accurate Weight and Instant Payment .",
    //   "amount": "8.00",
    //   "currency": "Rs.",
    //   "unit": "/Kg",
    //   "categoryId": "572b0f1738836e093240aac5"
    // },
    // {
    //   "grayImage": "assets/recyclables/recyclable-beer-bottle-gray.png",
    //   "greenImage": "assets/recyclables/recyclable-beer-bottle-green.png",
    //   "itemTitle": "BEER BOTTLES - B",
    //   "itemImage": "assets/Beer Bottles.jpg",
    //   "para1": "ScrapQ promotes beer bottle recycling , as Glass recycling helps the environment considerably, now you can schedule your pick up as per your convenience at regular intervals.",
    //   "para2": "",
    //   "amount": "1.50",
    //   "currency": "Rs.",
    //   "unit": "/Per Unit",
    //   "categoryId": "5a006d21d909ecf4c0dd490c"
    // },
    // {
    //   "grayImage": "assets/recyclables/recyclable-washing-machine-gray.png",
    //   "greenImage": "assets/recyclables/recyclable-washing-machine-green.png",
    //   "itemTitle": "WASHING MACHINE",
    //   "itemImage": "assets/Washing Machine.jpg",
    //   "para1": "Unlike refrigerators and freezers, washing machines do not contain hazardous chemicals that make them challenging and expensive to break down. Like other appliances, they are made of mostly metal, which is easy to recycle.",
    //   "para2": "Just send us your location and preferred time to our Whatsapp and our ReO will pick your Washing Machine.",
    //   "amount": "350.00",
    //   "currency": "Rs.",
    //   "unit": "/Per Unit",
    //   "categoryId": "589d802d769d7d28c71e854d"
    // },
    // {
    //   "grayImage": "assets/recyclables/recyclable-air-cooler-gray.png",
    //   "greenImage": "assets/recyclables/recyclable-air-cooler-green.png",
    //   "itemTitle": "AIR COOLER",
    //   "itemImage": "assets/Air Cooler.jpg",
    //   "para1": "An electrical appliance designed to cool the air in a room by evaporating water. Built with various materials like metal, plastic, and some electronic components, air coolers, when no longer operational or outdated, should be properly recycled to ensure responsible disposal and minimize environmental impact.",
    //   "para2": "",
    //   "amount": "120.00",
    //   "currency": "Rs.",
    //   "unit": "/Per Unit",
    //   "categoryId": "589d7fb2769d7d28c71e8541"
    // },
    // {
    //   "grayImage": "assets/recyclables/recyclable-tv-gray.png",
    //   "greenImage": "assets/recyclables/recyclable-tv-green.png",
    //   "itemTitle": "TV",
    //   "itemImage": "assets/Tv.jpg",
    //   "para1": "Like many other electronics, TVs contain chemicals that, if disposed of improperly,can damage our health and the environment. ... TV recycling can also produce valuable materials that can easily be reused, including plastic, glass, copper wiring, and even precious metals.",
    //   "para2": "Give a missed call to our REX, our ReO is at your doorstep.",
    //   "amount": "120.00",
    //   "currency": "Rs.",
    //   "unit": "/Per Unit",
    //   "categoryId": "589d809e769d7d28c71e8551"
    // },
    // {
    //   "grayImage": "assets/recyclables/recyclable-monitor-gray.png",
    //   "greenImage": "assets/recyclables/recyclable-monitor-green.png",
    //   "itemTitle": "MONITOR",
    //   "itemImage": "assets/Monitor.jpg",
    //   "para1": "ScrapQ’s commitment to recycle computer monitors allows businesses and individuals to not only practice more environmentally friendly disposal your, but also to aid in spreading technology to areas where it may be too expensive or otherwise unavailable.",
    //   "para2": "Now just sms your date and time our here ReO is there to pick up your monitor scrap",
    //   "amount": "100.00",
    //   "currency": "Rs.",
    //   "unit": "/Unit"
    // },
    // {
    //   "grayImage": "assets/recyclables/recyclable-cpu-gray.png",
    //   "greenImage": "assets/recyclables/recyclable-cpu-green.png",
    //   "itemTitle": "CPU",
    //   "itemImage": "assets/CPU.jpg",
    //   "para1": "CPU (central Processing Unit) from outdated computers and mainframe contains gold and copper, that has to be recycled. ScrapQ buysS most kinds of Scrap CPU, gold plated CPU's, gold plated fingers, pins and connectors",
    //   "para2": "We buy 186, 286, 386, 486, Pentium 1, Pentium 2, Pentium 3, Pentium 4 and all can be non working, old or new CPU's.Give us a missed call to Scrap your Old cpu now.",
    //   "amount": "100.00",
    //   "currency": "Rs.",
    //   "unit": "/Per Unit",
    //   "categoryId": "589d8106769d7d28c71e8559"
    // },
    // {
    //   "grayImage": "assets/recyclables/recyclable-white-paper-gray.png",
    //   "greenImage": "assets/recyclables/recyclable-white-paper-green.png",
    //   "itemTitle": "WHITE PAPER",
    //   "itemImage": "assets/White Paper.jpg",
    //   "para1": "24 trees are cut down to create 1000 kgs of paper, these trees can be saved if we recycle thesr new books . At ScrapQ ,we promote recycling as it not only saves our trees but also reduces the usage of energy to produce new paper by almost 75%.",
    //   "para2": "Now, just book an appointment with ScrapQ to recycle your old books @Uniform rate , with Accurate Weight and Instant Payment .",
    //   "amount": "7.00",
    //   "currency": "Rs.",
    //   "unit": "/Kg",
    //   "categoryId": "589d7d46769d7d28c71e8535"
    // },
    // {
    //   "grayImage": "assets/recyclables/recyclable-oil-tin-gray.png",
    //   "greenImage": "assets/recyclables/recyclable-oil-tin-green.png",
    //   "itemTitle": "FUEL TINS",
    //   "itemImage": "assets/Oil Thins.jpg",
    //   "para1": "Recycling oil cans,saves 74% of the energy used to produce them. On average, we recycle just over 49% of the cans we use. There is a huge chunk of these cans which are ending up in the landfills and degrading our soil. No lets Recycle with ScrapQ",
    //   "para2": "",
    //   "amount": "8.00",
    //   "currency": "Rs.",
    //   "unit": "/Kg",
    //   "categoryId": "61cd8551769d7d0d5e139d80"
    // },
    // {
    //   "grayImage": "assets/recyclables/recyclable-desktop-scrap-gray.png",
    //   "greenImage": "assets/recyclables/recyclable-desktop-scrap-green.png",
    //   "itemTitle": "DESKTOP SCRAP",
    //   "itemImage": "assets/Desktop Scrap.jpg",
    //   "para1": "If you have old desktop computers or vintage computer parts you don’t want any more, don’t just throw them out. Recycling your e-waste is better for the environment and can also make you some money.Recycling of desk top scrap needs certain regulatory permissions , ScrapQ and its partners are authorized collect desk top scrap and recycle it.",
    //   "para2": "",
    //   "amount": "15.00",
    //   "currency": "Rs.",
    //   "unit": "/Kg",
    //   "categoryId": "5aed5d69769d7d7f7dba1fad"
    // },
    // {
    //   "grayImage": "assets/recyclables/recyclable-shredding-service-gray.png",
    //   "greenImage": "assets/recyclables/recyclable-shredding-service-green.png",
    //   "itemTitle": "SHREDDING SERVICES",
    //   "itemImage": "assets/Shredding Services.jpg",
    //   "para1": "ScrapQ promotes data privacy and data confidentiality, apart from regular recyclable pick up services we also provide confidential data destruction services at your doorstep under your supervision.",
    //   "para2": "Our high end shredding machines and trained personnel take utmost care that none of the your confidential data is leaked.We are just a missed call away to serve you.",
    //   "amount": "1000.00",
    //   "currency": "Rs.",
    //   "unit": "/Kg"
    // },
    // {
    //   "grayImage": "assets/recyclables/recyclable-shredded-paper-gray.png",
    //   "greenImage": "assets/recyclables/recyclable-shredded-paper-green.png",
    //   "itemTitle": "SHREDDED PAPER",
    //   "itemImage": "assets/Shredded Paper.jpg",
    //   "para1": "\n    SHREDDED PAPER\n    Shredded paper is recycled to make paper pulp , which is reused to manufacture new paper , this saves lot of energy and gas . ScrapQ recognizes the importance of shredded paper as it saves trees and lot of other scarce resources.",
    //   "para2": "Now just whatsapp your location to pick up your shredded paper.",
    //   "amount": "8.00",
    //   "currency": "Rs.",
    //   "unit": "/Kg",
    //   "categoryId": "5edf34b1769d7d5d122030ac"
    // },
    // {
    //   "grayImage": "assets/recyclables/recyclable-brass-gray.png",
    //   "greenImage": "assets/recyclables/recyclable-brass-green.png",
    //   "itemTitle": "BRASS",
    //   "itemImage": "assets/Brass.jpg",
    //   "para1": "Making brass from new copper and zinc is highly uneconomical, real waste of raw materials; as there's enough scrap brass available for recycling. Brass materials that are commonly recycled include brass slag and turnings, which may vary in brass type and amount of contaminants. Water meters and sprinklers are often difficult to break down and separate.",
    //   "para2": "Just download our ScrapQ app to fix your appointment.",
    //   "amount": "250.00",
    //   "currency": "Rs.",
    //   "unit": "/Kg",
    //   "categoryId": "5aed5be0769d7d7f7dba1f88"
    // },
    // {
    //   "grayImage": "assets/recyclables/recyclable-office-chairs-gray.png",
    //   "greenImage": "assets/recyclables/recyclable-office-chairs-green.png",
    //   "itemTitle": "OFFICE CHAIRS",
    //   "itemImage": "assets/Office Chairs.jpg",
    //   "para1": "Office chairs, primarily made of wood, metal, plastic, foam, and fabric components, are essential for workspaces to provide ergonomic support. Over time, they may wear out or become outdated.",
    //   "para2": "Rather than sending them to a landfill, it's crucial to recycle or upcycle office chairs to prevent unnecessary waste and conserve resources.",
    //   "amount": "50.00",
    //   "currency": "Rs.",
    //   "unit": "/Per Unit",
    //   "categoryId": "5edf3418769d7d5d12203093"
    // },
    // {
    //   "grayImage": "assets/recyclables/recyclable-office-wood-material-gray.png",
    //   "greenImage": "assets/recyclables/recyclable-office-wood-material-green.png",
    //   "itemTitle": "OFFICE WOOD MATERIAL",
    //   "itemImage": "assets/Office Wood Material.jpg",
    //   "para1": "Wood recycling or Timber recycling is the process of turning waste timber into new/usable products. At ScrapQ we ensure through recycling of wood with our partners develop reused products, artificial wood, etc. which are environmentally friendly. Recycling wood helps us save Trees and nature to a large extent.",
    //   "para2": "Now, just book an appointment with ScrapQ to recycle your old wooden items or wood @Uniform rate, with Accurate Weight and Instant Payment.",
    //   "amount": "",
    //   "currency": "",
    //   "unit": ""
    // },
    // {
    //   "grayImage": "assets/recyclables/recyclable-tyre-gray.png",
    //   "greenImage": "assets/recyclables/recyclable-tyre-green.png",
    //   "itemTitle": "TYRES",
    //   "itemImage": "assets/Tyres.jpg",
    //   "para1": "Among most problematic sources of waste are tires .Innovation in recycling has resulted in a major reduction of dumping of tires. ScrapQ promotes tire recycling with its recycling partners across your city.",
    //   "para2": "Give a missed call to 90 30 72 72 77 and ensure that old tire is recycled.",
    //   "amount": "1.00",
    //   "currency": "Rs.",
    //   "unit": "/Kg",
    //   "categoryId": "5edf34b1769d7d5d1220309f"
    // },
    // {
    //   "grayImage": "assets/recyclables/recyclable-carpet-gray.png",
    //   "greenImage": "assets/recyclables/recyclable-carpet-green.png",
    //   "itemTitle": "CARPET",
    //   "itemImage": "assets/Carpet.jpg",
    //   "para1": "Carpets are full of complex fibers that make them impossible to break down in landfills. And, because carpet is made from multiple components with different chemical makeups, it’s tough to recycle properly.",
    //   "para2": "ScrapQ through its recycling partners ensures carpets are recycled properly to ensure a safer and healthier environment.",
    //   "amount": "1.00",
    //   "currency": "Rs.",
    //   "unit": "/Kg",
    //   "categoryId": "5aed5cbd769d7d7f7dba1f9e"
    // },
    // {
    //   "grayImage": "assets/recyclables/recyclable-ac-gray.png",
    //   "greenImage": "assets/recyclables/recyclable-ac-green.png",
    //   "itemTitle": "AIR CONDITIONER",
    //   "itemImage": "assets/Air Conditioner - Window.jpg",
    //   "para1": "AC units eventually run their course and stop working. If your AC unit is broken and irreparable, you can recycle it — but only after removing its refrigerant first. Do not attempt to do this yourself — a trained professional at ScrapQ will be able to remove the materials safely and according to specific regulations.",
    //   "para2": "Now disposing your old AC is on your finger tips , sms to ScrapQ our ReO will take care.",
    //   "amount": "2000.00",
    //   "currency": "Rs.",
    //   "unit": "/Per Unit",
    //   "categoryId": "5aed5aca769d7d7f7dba1f77"
    // },
    // {
    //   "grayImage": "assets/recyclables/recyclable-ac-split-gray.png",
    //   "greenImage": "assets/recyclables/recyclable-ac-split-green.png",
    //   "itemTitle": "AIR CONDITIONER(S)",
    //   "itemImage": "assets/Air Conditioner - Split.jpg",
    //   "para1": "AC units eventually run their course and stop working. If your AC unit is broken and irreparable, you can recycle it — but only after removing its refrigerant first. Do not attempt to do this yourself — a trained professional at ScrapQ will be able to remove the materials safely and according to specific regulations.",
    //   "para2": "Now disposing your old AC is on your finger tips , sms to ScrapQ our ReO will take care.",
    //   "amount": "2300.00",
    //   "currency": "Rs.",
    //   "unit": "/Per Unit",
    //   "categoryId": "5aed6554769d7d7f7dba2006"
    // },
    // {
    //   "grayImage": "assets/recyclables/recyclable-clothes-gray.png",
    //   "greenImage": "assets/recyclables/recyclable-clothes-green.png",
    //   "itemTitle": "CLOTHES",
    //   "itemImage": "assets/clothesmdpi.png",
    //   "para1": "Did you know your old clothes end up in landfills most of the time, now you can give them a new life by recycling them with ScrapQ, We atScrapQ collect your old clothes and enable recycling this effort saves your old clothes from going into the landfill and also protects the environment.",
    //   "para2": "Now, just book an appointment with ScrapQ to recycle your old clothes @Uniform rate, with Accurate Weight and Instant Payment",
    //   "amount": "",
    //   "currency": "",
    //   "unit": ""
    // },
    // {
    //   "grayImage": "assets/recyclables/recyclable-mobile-graymdpi.png",
    //   "greenImage": "assets/recyclables/recyclable-mobile-greenmdpi.png",
    //   "itemTitle": "Mobiles",
    //   "itemImage": "assets/mobilemdpi.png",
    //   "para1": "Mobile phones have grown from mere communication tools to indispensable everyday devices. Comprising an intricate blend of metals, plastics, and glass. Over 1.5 billion mobile phones are sold annually worldwide and only about 20% of old mobile phones are recycled, even though recycling them can conserve valuable resources and reduce the environmental impact.",
    //   "para2": "As models quickly become outdated due to rapid technological advancements, recycling becomes even more critical. Doing so not only ensures the recovery of precious metals but also prevents the accumulation of e-waste.",
    //   "amount": "",
    //   "currency": "",
    //   "unit": ""
    // },
    // {
    //   "grayImage": "assets/recyclables/recyclable-tablet-graymdpi.png",
    //   "greenImage": "assets/recyclables/recyclable-tablet-greenmdpi.png",
    //   "itemTitle": "Tablets",
    //   "itemImage": "assets/tabletmdpi.png",
    //   "para1": "Tablets, with their larger screens and extended functionalities, have taken the tech world by storm. Annually, more than 170 million tablets are sold globally. Just like mobile phones, they contain essential materials like rare metals, plastics, and glass. The tablet recycling rate is also low, similar to mobiles.",
    //   "para2": "Recycling tablets can help in saving energy equivalent to powering over 20,000 households for a year. As these devices age and newer versions are launched, it's crucial to promote and participate in recycling efforts to benefit both the environment and economy.",
    //   "amount": "",
    //   "unit": "",
    //   "currency": ""
    // }
  ]

  swiperConfig: SwiperOptions = {
    allowTouchMove: true,
    virtual: true,
    slidesPerView: 10,
    breakpoints: {
      200: {
        slidesPerView: 1
      },
      320: {
        slidesPerView: 1,
        spaceBetween: 0
      },
      480: {
        slidesPerView: 2
      },
      768: {
        slidesPerView: 3
      },
      1024: {
        slidesPerView: 6,
        spaceBetween: 10
      },
      1200: {
        slidesPerView: 6,
        spaceBetween: 10
      }
    }
  }
  loading: boolean = true;

  constructor(private commonService: CommonService,
    private cityService: CityService) {
  }

  ngOnInit(): void {
    this.cityService.getCity().subscribe(city => {
      if (city) {
        this.getProductCategories(city);
      }
    });
  }

  nextSlide() {
    if (this.currentIndex !== this.data.length - 1) {
      this.currentIndex = this.currentIndex + 1;
      this.swiper?.swiperRef.slideNext()
      this.swiper?.swiperRef.slideTo(this.currentIndex)
    }
  }

  prevSlide() {
    if (this.currentIndex !== 0) {
      this.currentIndex = this.currentIndex - 1;
      this.swiper?.swiperRef.slidePrev()
      this.swiper?.swiperRef.slideTo(this.currentIndex)
    }
  }

  showItem(index: number) {
    this.currentIndex = index;
  }

  async getProductCategories(city: string) {
    this.loading = true;
    (await this.commonService.getProductCategories(city)).subscribe((response: any) => {
      if (response.status === 'SUCCESS') {
        const productCategories = response.data.categories.filter((category: any) => category.consumercategoryname === 'Residence');
        this.data = this.data.map((item: RecyclableItem) => {
          const product = productCategories.find((productItem: any) => productItem.name === item.itemTitle);
          if (product) {
            return {
              ...item,
              categoryId: product.categoryId,
              unit: '/' + product.uom,
              amount: product.price
            }
          } else {
            return item;
          }

        })
      } else {
        // this.errorMessage = "Error retrieving privacy policy. Please try again later.";
      }

      this.loading = false;
    });

  }
}
