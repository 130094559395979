import { Component, HostListener, OnDestroy, OnInit, ViewChild, Inject } from '@angular/core';
import { AbstractControl, FormControl, ValidationErrors, Validators } from '@angular/forms';
import * as moment from 'moment';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { AuthService } from 'src/app/services/auth/auth.service';
import { CommonService } from 'src/app/services/common/common.service';
import { LocationService } from 'src/app/services/location/location.service';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { SnackBarService } from 'src/app/services/snack-bar/snack-bar.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Params } from '@angular/router';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NgxImageCompressService } from 'ngx-image-compress';
import { ScratchCard, SCRATCH_TYPE } from 'scratchcard-js';
import { environment } from 'src/environments/environment';
import { OtpInputComponent } from '../../otp-input/otp-input.component';
import { DialogImageComponent } from '../dialogs/dialog-image/dialog-image.component';

SwiperCore.use([Navigation, Pagination]);

interface EWasteMedia {
  key: number;
  displayName: string;
  file?: File | null;
  src: string;
  durationSeconds?: number;
  type: 'image' | 'video';
  required: boolean;
  base64?: string;
}

interface Product {
  categoryId: string;
  name: string;
  code: string;
  image: string;
  price: string;
  weightedPriceAverage: string | null;
  uom: string;
  description: string;
  consumercategoryname: string;
  consumercategoryid: string;
  customPriceId: string | null;
  city: string;
  gstPercentage: string;
}
@Component({
  selector: 'app-dropoff',
  templateUrl: './dropoff.component.html',
  styleUrls: ['./dropoff.component.scss']
})

export class DropOffComponent implements OnInit, OnDestroy {
  @ViewChild('otpInput', { static: false }) otpInput!: OtpInputComponent; // Access the OtpInputComponent
  static readonly DATE_FORMAT = 'DD-MMM-YYYY';
  center: google.maps.LatLngLiteral = { lat: 17.428250168737826, lng: 78.4551260126551 };
  otp: any;
  zoom = 18;
  markerOptions: google.maps.MarkerOptions = {
    draggable: true, clickable: false, icon: {
      url: '/assets/consumer-marker.svg',
      scaledSize: { width: 50, height: 50 } as google.maps.Size
    },
  };
  mapOptions: google.maps.MapOptions = {
    mapTypeControl: false,
    streetViewControl: false,
    fullscreenControl: false
  }
  otpSent = false;
  otpVerified = false;
  showSummaryScreen = false;

  timerId: any;
  resendOtpTimeLeft = 30;
  formattedAddress = '';
  mobileNo: FormControl = new FormControl<number | null>(null, [Validators.required, Validators.pattern('[0-9]{10}'), Validators.maxLength(10)]);
  savedAddresses: any[] = [];
  addressCategories: any;
  categoryIndex: FormControl = new FormControl<number | null>(null, [Validators.required]);
  screenWidth: any;
  minDate = moment(new Date()).add('days', 1);
  city: any = '';
  buttonClick = new Subject();
  options: any;
  loading = false;
  consumerId: any;
  locationError: boolean = false;
  customerName: FormControl = new FormControl('', [Validators.required, this.alphaOnly]);
  companyName: FormControl = new FormControl('', [this.alphaOnly]);
  ewasteItemIndex: FormControl = new FormControl<number | null>(null, [Validators.required]);
  email: FormControl = new FormControl('', [Validators.email, this.customEmailValidator]);
  appointmentDate: FormControl = new FormControl({ value: moment(new Date()), disabled: true });
  frontImage: any;
  backImage: any;
  productsList: Product[] = [];
  eWasteMedia: EWasteMedia[] = [{
    key: 0,
    displayName: 'Front Photo',
    file: null,
    src: '',
    type: 'image',
    required: true
  },
  {
    key: 1,
    displayName: 'Back Photo',
    file: null,
    src: '',
    type: 'image',
    required: true
  },
  {
    key: 2,
    displayName: 'Video',
    file: null,
    src: '',
    durationSeconds: 30,
    type: 'video',
    required: false
  }];
  rrrId: string = '';
  mediaBorderColor = '#ccc';
  agentData: any;
  currentPosition: google.maps.LatLngLiteral = { lat: 0, lng: 0 };

  breakpoints: any = {
    '0': {
      slidesPerView: 2,
      spaceBetween: 10,
      slidesPerGroup: 2
    },
    '300': {
      slidesPerView: 3,
      spaceBetween: 10,
      slidesPerGroup: 3
    },
    '500': {
      slidesPerView: 4,
      spaceBetween: 10,
      slidesPerGroup: 4
    }
  };
  confirmationId: any;
  appointmentData: any;
  showVideoUploadAlert: boolean = true;
  addressId: any = null;
  showTermsandConditions: boolean = false;

  constructor(
    private locationService: LocationService,
    private authService: AuthService,
    private commonService: CommonService,
    private dialog: MatDialog,
    private snackbarService: SnackBarService,
    public domSanitizer: DomSanitizer,
    private activatedRoute: ActivatedRoute,
    private imageCompress: NgxImageCompressService) {
    this.options = {
      componentRestrictions: { country: 'IN' }
    }

    this.locationService.getPosition().then((position) => {
      this.locationError = false;
      this.currentPosition = position;
    }).catch((err: GeolocationPositionError) => {
      this.locationError = true;
      if (err.code === 1) {
        this.snackbarService.showErrorBar('Location permission is required to book appointment. Once you grant permission, please refresh the page to continue', 5000);
      } else {
        this.snackbarService.showErrorBar('An error occurred while trying to determine your location. Please try again.');
      }
    });

    this.buttonClick.pipe(debounceTime(500)).subscribe(() => {
      this.bookAppointment();
    });
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.rrrId = params['rrrId'];
      this.getCelektAgent(this.rrrId);
    });

    // this.getCelektAppointmentSummary('SQ00134970');
  }

  // Custom validator function to allow only alpha characters
  alphaOnly(control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    const aphaPattern = /^[a-zA-Z\s][a-zA-Z\s]*$/;

    if (value && !aphaPattern.test(value)) {
      return { alphaOnly: true }; // Validation failed
    }
    return null; // Validation passed
  }

  customEmailValidator(control: FormControl): ValidationErrors | null {
    // Regular expression for a more comprehensive email validation
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    if (control.value && !emailPattern.test(control.value)) {
      return { customEmail: true }; // Validation failed
    }

    return null; // Validation passed
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.screenWidth = window.innerWidth;
  }

  get mapHeight() {
    return this.screenWidth >= 1024 ? '210px' : '200px';
  }

  get swiperMaxWidth() {
    return this.screenWidth >= 450 ? '450px' : this.screenWidth - 32 + 'px';
  }

  getProductImageSource(imageId: string) {
    return `${environment.API_URL}/fileManager/getImageFileById/${imageId}.jpg`
  }

  showScratchCard() {

    const sc = new ScratchCard('#js--sc--container', {
      scratchType: SCRATCH_TYPE.LINE,
      containerWidth: 300,
      containerHeight: 300,
      imageForwardSrc: '/assets/scratch.png',
      // imageBackgroundSrc: '/assets/Monitor.jpg',
      htmlBackground: `<div class="gift-box">
      <img src="/assets/gift.svg" class="logo" height="auto" width="110" />
      <p class="heading">Congratulations!</p>
      <p class="description">You have won the discount Coupon worth upto Rs. <strong>10,000</strong></p>
    </div>
    <div class="coupon">
      <div class="coupon-code">
        <span>Coupon Code: ${this.appointmentData?.couponCode || '-'}</span>
      </div>
      <span class="coupon-validity">Coupon Validity: ${this.appointmentData?.couponValidity || '-'}</span>
    </div>`,
      clearZoneRadius: 50,
      percentToFinish: 50,
      nPoints: 30,
      pointSize: 4,
      enabledPercentUpdate: true,
      callback: () => {
        this.showTermsandConditions = true;
      },
    });

    sc.init().then(() => {
      // sc.canvas.addEventListener('scratch.move', () => {
      //   const percent = sc.getPercent().toFixed(2);
      //   console.log(percent);
      // });
    }).catch((error: any) => {
      this.snackbarService.showSuccessBar(error.message, 3000);
    });
  }

  public async handleAddressChange(address: Address) {
    this.categoryIndex.setValue('');
    const location = address.geometry.location;
    this.formattedAddress = address.formatted_address;

    this.center = {
      lat: location.lat(),
      lng: location.lng(),
    };
  }

  onMarkerDragEnd(event: google.maps.MapMouseEvent) {
    if (event.latLng) {
      const { lat, lng } = event.latLng.toJSON();
      this.center = {
        lat, lng
      }
      this.getLocationByLatLong(lat, lng);
    }
  }

  getLocationByLatLong(latitude: any, longitude: any) {
    const geocoder = new google.maps.Geocoder();
    const latlng = new google.maps.LatLng(latitude, longitude);
    geocoder.geocode({
      location: latlng
    }, async (results, status) => {
      if (status === google.maps.GeocoderStatus.OK) {
        if (results) {
          this.formattedAddress = results[0].formatted_address;
        }
      }
    });
  }

  ngOnDestroy() {
    clearInterval(this.timerId);
  }

  onOtpEntered(otp: string) {
    this.otp = otp;
  }

  showLoader() {
    this.loading = true;
  }

  hideLoader() {
    this.loading = false;
  }

  startOTPTimer() {
    this.resendOtpTimeLeft = 30;
    const self = this;
    const countdown = () => {
      if (self.resendOtpTimeLeft == 0) {
        clearTimeout(this.timerId);
      } else {
        self.resendOtpTimeLeft--;
      }
    }
    this.timerId = setInterval(countdown, 1000);
  }

  async sendOTP(resendOTP = false) {
    if (this.locationError) {
      this.snackbarService.showErrorBar('Location permission is required to drop e-waste. Once you grant permission, please refresh the page to continue', 5000);
      return;
    }

    if (!this.city) {
      this.snackbarService.showErrorBar('Invalid City');
      return;
    }
    if (this.mobileNo.valid || resendOTP) {
      this.showLoader();
      const data = {
        mobileNo: this.mobileNo.value,
        city: this.city,
        celkon: true
      };
      (await this.authService.sendOtp(data)).subscribe((response: any) => {
        this.hideLoader();
        if (response.status === 'SUCCESS') {
          this.otpSent = true;
          this.mobileNo.disable();
          this.otpInput?.resetOtp();
          this.startOTPTimer();
          this.snackbarService.showSuccessBar(response.data.message);
          this.getEWasteProductCategories(this.city);
          this.getAddressCategories();
        } else {
          this.snackbarService.showErrorBar(response.error.message);
        }
      }, () => this.hideLoader());
    } else {
      this.mobileNo.markAsTouched();
      // if (this.mobileNo?.invalid) {
      //   this.snackbarService.showErrorBar('Mobile Number is not valid');
      // }
    }
  }

  async getAddressCategories() {
    (await this.commonService.getAddressCategories()).subscribe((res: any) => {
      if (res.status === 'SUCCESS') {
        this.addressCategories = res.data.categories;
      } else {
        this.snackbarService.showErrorBar(res.error.message);
      }
    })
  }

  async verifyOTP() {
    if (this.otp?.length === 6) {
      this.showLoader();
      const data = {
        verificationCode: this.otp,
        mobileOrEmail: this.mobileNo.value,
        celkon: true
      };
      (await this.authService.verifyOTP(data)).subscribe((response: any) => {
        this.hideLoader();
        if (response.status === 'SUCCESS') {
          this.otpVerified = true;
          clearTimeout(this.timerId);
          this.consumerId = response?.data?.userId;
          this.customerName.setValue(response?.data?.name || '');
          this.companyName.setValue(response?.data?.companyName || '');
          this.email.setValue(response?.data?.email || '');
          if (response.data?.addresses?.length > 0) {
            this.savedAddresses = response.data.addresses;
            const defaultAddress = this.savedAddresses.find(address => address.defaultAddress === 'YES');
            const addressCategoryIndex = this.addressCategories.findIndex((addressCategory: any) => addressCategory.consumerCategoryId === defaultAddress.categories[0].key);
            this.formattedAddress = defaultAddress.formattedAddress;
            this.addressId = defaultAddress.addressId;
            this.categoryIndex.setValue(addressCategoryIndex);
            this.center.lat = Number(defaultAddress.latitude);
            this.center.lng = Number(defaultAddress.longitude);
          } else {
            this.center = this.currentPosition;
            const { lat, lng } = this.center;
            this.getLocationByLatLong(lat, lng);
          }
        } else {
          this.snackbarService.showErrorBar(response.error.message);
        }
      }, () => this.hideLoader());
    } else {
      this.snackbarService.showErrorBar('Verification code is incorrect');
    }
  }

  isAnyMediaEmpty(mediaArray: EWasteMedia[]): boolean {
    for (const media of mediaArray) {
      if (media.src === '' && media.required) {
        return true;
      }
    }
    return false; // None of the src values are empty
  }

  async bookAppointment() {
    this.mediaBorderColor = 'red';

    if (this.customerName.invalid) {
      this.customerName.markAsTouched();
      this.snackbarService.showErrorBar('Please provide a valid name');
      return;
    }

    if (this.companyName.invalid) {
      this.companyName.markAsTouched();
      this.snackbarService.showErrorBar('Please provide a valid company name');
      return;
    }

    if (this.email.invalid) {
      this.email.markAsTouched();
      this.snackbarService.showErrorBar('Please provide a valid email');
      return;
    }

    if (this.savedAddresses.length === 0) {
      if (!this.formattedAddress) {
        this.snackbarService.showErrorBar('Please enter a valid address');
        return;
      }

      if (this.categoryIndex.invalid) {
        this.categoryIndex.markAsTouched();
        this.snackbarService.showErrorBar('Please select an address category');
        return;
      }
    }

    if (this.ewasteItemIndex.invalid) {
      this.ewasteItemIndex.markAsTouched();
      this.snackbarService.showErrorBar('Please select an e-waste item');
      return;
    }

    if (this.isAnyMediaEmpty(this.eWasteMedia)) {
      this.snackbarService.showErrorBar('Please upload e-waste photos and video');
      return;
    }

    this.showLoader();

    let data: any = {
      mobile: this.mobileNo.value,
      name: this.customerName.value,
      rrrId: this.rrrId,
      addressType: this.addressCategories[this.categoryIndex.value].name,
      formattedAddress: this.formattedAddress.trim(),
      latitude: this.center.lat,
      longitude: this.center.lng,
      preferredDate: moment(new Date(this.appointmentDate.value)).format(DropOffComponent.DATE_FORMAT),
      addressId: this.addressId,
      preferredSlot: '2',
      isMyGate: false,
      email: this.email.value,
      companyName: this.companyName.value
    };

    (await this.commonService.registerEWasteRRR(data)).subscribe(async (response: any) => {
      const selectedProduct = this.productsList[this.ewasteItemIndex.value];
      console.log('upload item api payload', data);
      if (response.status === 'SUCCESS') {
        this.confirmationId = response.data.sell.confirmationId;

        if (this.eWasteMedia[2].file) {
          (await this.commonService.uploadVideo(this.confirmationId, selectedProduct.categoryId, this.eWasteMedia[2].file!)).subscribe(async (uploadResult: any) => {
            if (uploadResult.status === 'SUCCESS') {
              const videoURL = uploadResult.data.videoURL;
              const data = {
                sellObjId: response.data.sell.sellObjId,
                agentObjId: this.agentData?.agentId,
                items: [
                  {
                    categoryId: selectedProduct.categoryId,
                    categoryName: selectedProduct.name,
                    image: selectedProduct.image,
                    pricePerUnit: selectedProduct.price,
                    quantity: 1,
                    uom: selectedProduct.uom,
                    frontImageSource: this.eWasteMedia[0].base64,
                    backImageSource: this.eWasteMedia[1].base64,
                    videoURL
                  }
                ],
                address: {
                  addressId: this.addressId,
                  formattedAddress: this.formattedAddress?.trim(),
                  longitude: this.center.lng,
                  latitude: this.center.lat,
                  categories: [this.addressCategories[this.categoryIndex.value]]
                },
                closedLat: this.currentPosition.lat,
                closedLong: this.currentPosition.lng
              };
              (await this.commonService.uploadCelektItem(data)).subscribe(async (res: any) => {
                console.log('upload item api res', res)
                if (res.status === 'SUCCESS') {
                  const data = {
                    sellObjId: response.data.sell.sellObjId,
                    agentObjId: this.agentData?.agentId
                  };

                  (await this.commonService.completeCelektAppointment(data)).subscribe((result: any) => {
                    if (result.status === 'SUCCESS') {
                      this.showSummaryScreen = true;
                      this.getCelektAppointmentSummary(response.data.sell.confirmationId);
                      this.snackbarService.showSuccessBar(result.data.message, 3000);
                    } else {
                      this.hideLoader();
                      this.snackbarService.showErrorBar(result.error.message);
                    }
                  }, () => this.hideLoader());
                } else {
                  this.hideLoader();
                  this.snackbarService.showErrorBar(res.error.message);
                }
              }, () => this.hideLoader());
            } else {
              this.hideLoader();
              this.snackbarService.showErrorBar(uploadResult.error.message);
            }
          }, () => this.hideLoader());
        }
        else {
          const data = {
            sellObjId: response.data.sell.sellObjId,
            agentObjId: this.agentData?.agentId,
            items: [
              {
                categoryId: selectedProduct.categoryId,
                categoryName: selectedProduct.name,
                image: selectedProduct.image,
                pricePerUnit: selectedProduct.price,
                quantity: 1,
                uom: selectedProduct!.uom,
                frontImageSource: this.eWasteMedia[0].base64,
                backImageSource: this.eWasteMedia[1].base64
              }
            ],
            address: {
              addressId: this.addressId,
              formattedAddress: this.formattedAddress?.trim(),
              longitude: this.center.lng,
              latitude: this.center.lat,
              categories: [this.addressCategories[this.categoryIndex.value]]
            },
            closedLat: this.currentPosition.lat,
            closedLong: this.currentPosition.lng
          };
          (await this.commonService.uploadCelektItem(data)).subscribe(async (res: any) => {
            console.log('upload item api res', res)
            if (res.status === 'SUCCESS') {
              const data = {
                sellObjId: response.data.sell.sellObjId,
                agentObjId: this.agentData?.agentId
              };

              (await this.commonService.completeCelektAppointment(data)).subscribe((result: any) => {
                if (result.status === 'SUCCESS') {
                  this.showSummaryScreen = true;
                  this.getCelektAppointmentSummary(response.data.sell.confirmationId);
                  this.snackbarService.showSuccessBar(result.data.message, 3000);
                } else {
                  this.hideLoader();
                  this.snackbarService.showErrorBar(result.error.message);
                }
              }, () => this.hideLoader());
            } else {
              this.hideLoader();
              this.snackbarService.showErrorBar(res.error.message);
            }
          }, () => this.hideLoader());
        }
      } else {
        this.hideLoader();
        this.snackbarService.showErrorBar(response.error.message);
      }
    }, () => { this.hideLoader(); });
  }

  fileToBase64(file: File, callback: (base64String: string) => void) {
    const reader = new FileReader();

    reader.onload = (e) => {
      const base64String = reader.result as string;
      callback(base64String);
    };

    reader.readAsDataURL(file);
  }

  onMediaFileSelected(event: any, index: number) {
    const file = event.target.files[0];
    if (file) {
      if (file.type.startsWith('video/')) {
        this.showVideoUploadAlert = true;
        const video = document.createElement('video');
        video.src = URL.createObjectURL(file);

        video.onloadedmetadata = () => {
          if (video.duration >= 11) {
            const videoDurationLimitExceed = 'Your video exceeds the 10-second duration limit.';
            this.dialog.open(MessageDialog, {
              data: { message: videoDurationLimitExceed },
              width: '90%',
              maxWidth: '400px',
              disableClose: true
            });
            event.target.value = '';
          } else if (file.size >= 50 * 1024 * 1024) {
            const videoSizeLimitExceed = 'Your video exceeds the 50MB size limit.';
            this.dialog.open(MessageDialog, {
              data: { message: videoSizeLimitExceed },
              width: '90%',
              maxWidth: '400px',
              disableClose: true
            });
            event.target.value = '';
          } else {
            this.eWasteMedia[index].src = URL.createObjectURL(file);
            this.eWasteMedia[index].file = file;

            this.fileToBase64(file, (base64String) => {
              this.eWasteMedia[index].base64 = base64String;
            });
          }
        };

        video.load();
      } else {
        this.eWasteMedia[index].src = URL.createObjectURL(file);
        this.eWasteMedia[index].file = file;

        this.fileToBase64(file, (base64String) => {
          this.imageCompress
            .compressFile(base64String, 0, 50, 50) // 50% ratio, 50% quality
            .then(compressedImage => {
              this.eWasteMedia[index].base64 = compressedImage;
            });
        });
      }
    }
  }

  showVideoInstructionDialog(cb: any) {
    const videoRecordInstructionMessage = "Please create a short video (under 10 seconds) while you're dropping off your e-waste.";
    const dialog = this.dialog.open(MessageDialog, {
      data: {
        title: 'Video Recording Instructions',
        message: videoRecordInstructionMessage
      },
      width: '90%',
      maxWidth: '400px',
      disableClose: true
    });
    dialog.afterClosed().subscribe(() => {
      cb();
    });
  }

  deleteMedia(index: number) {
    this.eWasteMedia[index].src = '';
    this.eWasteMedia[index].file = null;
  }

  uploadVideo(ref: any) {
    if (this.showVideoUploadAlert) {
      this.showVideoUploadAlert = false; // Set the flag to false to prevent repeated alerts
      this.showVideoInstructionDialog(() => ref.click());
    } else {
      ref.click();
    }
  }

  getMediaFormat(type: string) {
    return type === 'image' ? 'image/*' : 'video/*';
  }

  async getEWasteProductCategories(city: string) {
    this.showLoader();
    (await this.commonService.getEWasteProductCategories(city)).subscribe((res: any) => {
      this.hideLoader();
      if (res.status === 'SUCCESS') {
        this.productsList = res.data.categories.filter((product: any) => product.consumercategoryname === 'Residence');
      } else {
        this.snackbarService.showErrorBar(res.error.message);
      }
    }, () => this.hideLoader());
  }

  async getCelektAgent(agentId: string) {
    this.showLoader();
    (await this.commonService.getCelektAgent(agentId)).subscribe((res: any) => {
      this.hideLoader();
      if (res.status === 'SUCCESS') {
        this.agentData = res.data.agent;
        this.city = this.agentData.city;
      } else {
        this.snackbarService.showErrorBar(res.error.message);
      }
    }, () => this.hideLoader());
  }

  async getCelektAppointmentSummary(confirmationId: string) {
    this.showLoader();
    (await this.commonService.getCelektAppointmentSummary(confirmationId)).subscribe((res: any) => {
      this.hideLoader();
      if (res.status === 'SUCCESS') {
        this.appointmentData = res.data.sell;
        setTimeout(() => { this.showScratchCard() }, 100);
      } else {
        this.snackbarService.showErrorBar(res.error.message);
      }
    }, () => this.hideLoader());
  }

  showMediaDialog(title: string, src: string, type = 'image') {
    this.dialog.open(DialogImageComponent, {
      data: {
        title: title,
        src: src,
        type
      },
      width: '90%',
      maxWidth: '400px'
    });
  }

}


@Component({
  selector: 'app-message-dialog',
  template: `
   <div mat-dialog-title align='center'>{{data.title || 'Information'}}</div>
   <div mat-dialog-content>{{data.message}}</div>
    <mat-dialog-actions align="center">
        <button mat-flat-button mat-dialog-close color='accent'>OKAY</button>
    </mat-dialog-actions>
     `
})

export class MessageDialog {

  constructor(public dialogRef: MatDialogRef<MessageDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  closeDialog() {
    this.dialogRef.close();
  }
}

