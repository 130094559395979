<div class="carousel-container">
  <app-hero></app-hero>
  <div class="booking-card">
    <app-book-appointment-card></app-book-appointment-card>
  </div>
  <p class="text-over-carousel">
    {{'Leading the way in\n Environmental, Social and\nEnviromental impact for a\nsustainable future'}}
  </p>
</div>

<div class="title-container">
  <p class="title">Impact Created so far</p>
</div>
<app-impact-created-v2></app-impact-created-v2>

<div style="background-color: rgba(245, 245, 245, 0.4);margin: 60px 0 15px 0; padding-bottom: 35px;">
  <div class="title-container">
    <p class="title">What We Recycle</p>
  </div>
  <app-recyclables></app-recyclables>
</div>

<div class="title-container">
  <p class="title">How it works</p>
</div>
<app-how-it-works></app-how-it-works>

<div class="title-container">
  <p class="title">What we do</p>
</div>
<app-what-we-do></app-what-we-do>

<div class="title-container">
  <p class="title">Making innovations since 2020</p>
</div>

<app-innovations></app-innovations>

<div style="margin-top: 60px;">
  <app-our-network-homepage></app-our-network-homepage>
</div>

<div class="title-container">
  <p class="title">Customer Speaks</p>
</div>
<app-client-speak></app-client-speak>

<div style="background-color: rgba(245, 245, 245,0.4);padding-bottom: 35px;">
  <div class="title-container">
    <p class="title">Our Clients</p>
  </div>
  <app-our-clients></app-our-clients>
</div>