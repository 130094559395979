import { Component, ViewChild } from '@angular/core';
import { SwiperComponent } from 'swiper/angular';

@Component({
  selector: 'app-how-it-works',
  templateUrl: './how-it-works.component.html',
  styleUrls: ['./how-it-works.component.css']
})
export class HowItWorksComponent {
  @ViewChild('swiper', { static: false }) swiper?: SwiperComponent;
  breakpoints: any;
  howItWorks: any;

  constructor() {
    this.howItWorks =
      [
        {
          title: '01',
          con: 'Contact Us',
          ph: '90 30 72 72 77',
          dw: 'Download App',
        },
        {
          title: '02',
          desc: 'OnSite Collection'
        },
        {
          title: '03',
          desc: 'Accurate Weighing'
        },
        {
          title: '04',
          desc: 'Instant Payment'
        },
        {
          title: '05',
          desc: 'Responsible Recycling'
        }
      ]


    this.breakpoints = {
      '0': {
        slidesPerView: 1,
        spaceBetween: 0
      },
      '768': {
        slidesPerView: 3,
        spaceBetween: 0
      },
      '991': {
        slidesPerView: 3,
        spaceBetween: 0
      },
      '1024': {
        slidesPerView: 4,
        spaceBetween: 0
      },
      '1200': {
        slidesPerView: 5,
        spaceBetween: 0
      },
      '1440': {
        slidesPerView: 5,
        spaceBetween: 0
      }
    };
  }
}
