import { Component } from '@angular/core';

@Component({
  selector: 'app-e-waste-drop-off-layout',
  templateUrl: './e-waste-drop-off-layout.component.html',
  styleUrls: ['./e-waste-drop-off-layout.component.scss']
})
export class EWasteDropOffLayoutComponent {
  address = `Celekt Gadgets LLP.\nPlot No. 283/A C-1 to C-5, Incore 9, 4th Floor,\nLandmark D-Mart Building, Kavuri Hills,\nMadhapur, Hyderabad, Telangana 500081.`;
}
