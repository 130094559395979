import { Component, Inject, Injectable } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class SnackBarService {
  constructor(private snackBar: MatSnackBar) { }

  showSuccessBar(message: string, duration: number = 3000): MatSnackBarRef<SnackBarComponent> {
    return this.snackBar.openFromComponent(SnackBarComponent, {
      data: { message },
      duration: duration,
      panelClass: ['success-bar'],
      horizontalPosition: 'center',
      verticalPosition: 'top'
    });
  }

  showErrorBar(message: string, duration: number = 3000): MatSnackBarRef<SnackBarComponent> {
    return this.snackBar.openFromComponent(SnackBarComponent, {
      data: { message },
      duration: duration,
      panelClass: ['error-bar'],
      horizontalPosition: 'center',
      verticalPosition: 'top'
    });
  }
}

@Component({
  selector: 'app-snack-bar',
  template: `<span class="message" matSnackBarLabel>{{ data.message }}</span>
  <span matSnackBarActions>
  <button mat-icon-button matSnackBarAction  class="close-button" (click)="dismissSnackBar()">
    <mat-icon>close</mat-icon>
  </button></span>`,
  styleUrls: ['./snack-bar.scss']
})
export class SnackBarComponent {
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any,
    private snackBarRef: MatSnackBarRef<SnackBarComponent>) { }
  dismissSnackBar(cancel: boolean = false) {
    if (cancel === false) {
      this.snackBarRef.dismissWithAction()
    } else {
      this.snackBarRef.dismiss()
    }
  }
}
