<div class="container">
    <div style="flex-grow: 1;">
      <swiper #swiper class="services-carousel services-carousel-sewage" [pagination]="true" [breakpoints]="breakpoints">
        <ng-template swiperSlide>
          <div class="default-card1">
            <h2 class="title1">{{howItWorks[0].title?.toUpperCase()}}</h2>
            <h2 class="desc1">{{howItWorks[0].con.toUpperCase()}}</h2>
            <h2 class="desc1" style="border-bottom: 1px solid black;margin: 2px 35px 0px 35px;">{{howItWorks[0].ph.toUpperCase()}}</h2>
           
            <h2 class="desc1" style="margin-top: 20px;">{{howItWorks[0].dw.toUpperCase()}}</h2>
          
          <div style="display: flex;justify-content: center;">
            <a href="https://play.google.com/store/apps/details?id=com.scrapq.consumer&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1&pli=1" target="_blank">
              <img src="/assets/Google_Play.svg" width="100px" height="40px">
            </a>
            <a href="https://apps.apple.com/in/app/scrapq/id1296158469" target="_blank">
              <img src="/assets/Icons-14.png" width="100px" height="36px">
            </a>
          </div>      
        </div>      
        </ng-template>
        <ng-template swiperSlide>
          <div class="default-card2">
            <h2 class="title">{{howItWorks[1].title?.toUpperCase()}}</h2>
            <h2 class="desc">{{howItWorks[1].desc?.toUpperCase()}}</h2>
          </div>        
        </ng-template>
        <ng-template swiperSlide>
          <div class="default-card3">
            <h2 class="title">{{howItWorks[2].title?.toUpperCase()}}</h2>
            <h2 class="desc">{{howItWorks[2].desc?.toUpperCase()}}</h2>
          </div>        
        </ng-template>
        <ng-template swiperSlide>
          <div class="default-card4">
            <h2 class="title">{{howItWorks[3].title?.toUpperCase()}}</h2>
            <h2 class="desc">{{howItWorks[3].desc?.toUpperCase()}}</h2>
          </div>        
        </ng-template>
        <ng-template swiperSlide>
          <div class="default-card5">
            <h2 class="title">{{howItWorks[4].title?.toUpperCase()}}</h2>
            <h2 class="desc">{{howItWorks[4].desc?.toUpperCase()}}</h2>
          </div>        
        </ng-template>
        </swiper>
    </div>
  </div>
  