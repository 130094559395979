import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common/common.service';

@Component({
  selector: 'app-faqs',
  templateUrl: './faqs.component.html',
  styleUrls: ['./faqs.component.scss']
})
export class FaqsComponent implements OnInit {
  faqs: any = [];

  constructor(private commonService: CommonService) {
  }

  async ngOnInit() {
    (await this.commonService.getFaqs()).subscribe((res: any) => {
      this.faqs = res.data.faqData;
    })
  }
  expandedIndex = 0;
}
