import { Component, Input, Output, EventEmitter, OnInit, ViewChild, ElementRef } from '@angular/core';

export interface Option {
  key: string;
  value: string;
}

@Component({
  selector: 'app-custom-dropdown',
  templateUrl: './custom-dropdown.component.html',
  styleUrls: ['./custom-dropdown.component.scss']
})
export class CustomDropdownComponent implements OnInit {
  @Input() options: any[] = [];
  @Output() optionSelected: EventEmitter<string> = new EventEmitter<string>();
  @ViewChild('dropdownList', { static: false }) dropdownList!: ElementRef<HTMLUListElement>;
  active: number = -1;
  isDropdownOpen: boolean = false;

  ngOnInit() {
    const reshapeOptions = this.options.map((option) => ({ key: option.addressId, value: option.formattedAddress }));
    this.options = reshapeOptions;
  }

  selectedOption(option: Option, index: number) {
    this.active = index;
    console.log('option selelcted', option)
    this.optionSelected.emit(option.key);
  }

  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  closeDropdown() {
    this.isDropdownOpen = false;
  }
}
