<p class="title">Our Network</p>
<div class="container">

  <div class="network-image-container">
    <p class="overlay-title">
      Our Network</p>
  </div>

  <div class="tab-container">
    <mat-tab-group class="sub-container">
      <mat-tab label="Agents">
        <div>
          <p class="desc">
            A network of 2300+ agents all across Hyderabad are always ready to help you recycle better.
            These agents are using ScrapQ platform to enhance the cause of "Recycling is Divine"
          </p>
          <div style="border-top: 1px solid lightgray; ">
            <p class="tab-key">City</p>
            <p class="tab-value">Hyderabad</p>
          </div>
          <div style="border-top: 1px solid lightgray; border-bottom: 1px solid lightgray;">
            <p class="tab-key">Agents</p>
            <p class="tab-value">2300+</p>
          </div>
          <div>
            <p class="tab-key">Service Time</p>
            <p class="tab-value">&#60;30min</p>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Corporate">
        <div>
          <p class="desc">
            ScrapQ is the most preferred Recycling Partner with 3500+ corporate entities in Hyderabad which
            is growing on daily basis, ranging from Multinationals to SME’s.
          </p>
          <div style="border-top: 1px solid lightgray; ">
            <p class="tab-key">City</p>
            <p class="tab-value">Hyderabad</p>
          </div>
          <div style="border-top: 1px solid lightgray; border-bottom: 1px solid lightgray;">
            <p class="tab-key">Corporate</p>
            <p class="tab-value">3500+</p>
          </div>
          <div>
            <p class="tab-key">Service Time</p>
            <p class="tab-value">&#60;4hrs</p>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Residential">
        <div>
          <p class="desc">
            ScrapQ serves around 50000+ households across Hyderabad, we are also tied up with large
            Housing communities all over Hyderabad for all their responsible recycling requirements
          </p>
          <div style="border-top: 1px solid lightgray;">
            <p class="tab-key">City</p>
            <p class="tab-value">Hyderabad</p>
          </div>
          <div style="border-top: 1px solid lightgray; border-bottom: 1px solid lightgray;">
            <p class="tab-key">Households</p>
            <p class="tab-value">50000+</p>
          </div>
          <div>
            <p class="tab-key">Service Time</p>
            <p class="tab-value">&#60;30min</p>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>