import { Directive, HostListener, ElementRef } from '@angular/core';

@Directive({
  selector: '[appNumericOnly]'
})
export class NumericOnlyDirective {
  constructor(private el: ElementRef) { }

  @HostListener('input', ['$event']) onInput() {
    const input = this.el.nativeElement;
    const value = input.value;
    input.value = value.replace(/\D/g, ''); // Replace non-numeric characters with an empty string
  }
}
