<div mat-dialog-title align='center'>{{data.title || 'Information'}}</div>
<div mat-dialog-content>
  <ng-container *ngIf="data.type==='image';else showVideo;">
    <img class="image" [src]="data.src" [alt]="data.title" />
  </ng-container>
  <ng-template #showVideo>
    <video controls controlsList="nodownload" [src]="data.src" title="Dropoff Video">
      Your browser does not support the video tag.
    </video>
  </ng-template>
</div>
<mat-dialog-actions align="center">
  <button mat-flat-button mat-dialog-close color='accent'>CLOSE</button>
</mat-dialog-actions>