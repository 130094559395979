<div class="container">
  <div class="card">
    <div class="impact-created-carousel">
      <button mat-icon-button color="primary" aria-label="prev" class="prev-button" (click)="previousSlide()">
        <mat-icon style="transform: scale(2);">arrow_left</mat-icon>
      </button>
      <swiper #impactCreated [config]="swiperConfig" class="slides">
        <ng-template swiperSlide *ngFor="let item of impactCreatedData; let i = index">
          <div class="impact-created-slide-container" [style.backgroundImage]="getBackgroundImage(item[0].rowName)">
            <div class="overlay">
              <h3 class="heading-3">{{item[0].rowName}}</h3>
              <div class="impact-created-slide-content">
                <div class="impact-created-card" *ngFor="let childItem of item;let childIndex =index;"
                  [ngClass]="{'impact-created-card__with-left-right-border':childIndex===1}">
                  <div class="text">{{childItem.fieldDescriptionOne}}</div>
                  <div class="count">{{childItem.value}}</div>
                  <div class="text">{{childItem.fieldDescriptionTwo}}</div>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </swiper>
      <button mat-icon-button color="primary" aria-label="next" class="next-button" (click)="nextSlide()">
        <mat-icon style="transform: scale(2);">arrow_right</mat-icon>
      </button>
    </div>
  </div>
</div>