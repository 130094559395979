<div class="main-container">
  <div class="container">

    <div *ngIf="!showSummaryScreen" class="limit-width">
      <h1 class="title">E-Waste Dropoff</h1>
      <p class="dropoff-address"><span class="label">Address:
        </span>{{agentData?.address?.formattedAddress}}</p>
      <!-- <div style="display:flex;justify-content: space-between;">
        <p><span class="label">Date: </span>{{appointmentDate.value}}</p>
        <p><span class="label">City: </span>{{city}}</p>
      </div> -->
    </div>

    <ng-container *ngIf="!showSummaryScreen">

      <div class="column limit-width" *ngIf="!otpVerified">
        <mat-form-field (click)="picker.open()" color="accent">
          <mat-label>Dropoff Date</mat-label>
          <mat-icon matIconPrefix color="accent">calendar_month</mat-icon>
          <input style="cursor: pointer;" matInput [matDatepicker]="picker" [readonly]="true" [min]='minDate'
            [formControl]="appointmentDate">
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>

        <!-- <mat-form-field>
          <mat-label>Dropoff Address</mat-label>
          <mat-icon matIconPrefix color="accent">pin_drop</mat-icon>
          <input matInput [value]="agentData?.address?.formattedAddress" type="text" [disabled]="true" />
        </mat-form-field> -->

        <mat-form-field color="accent">
          <mat-label>Dropoff City</mat-label>
          <mat-icon matIconPrefix color="accent">location_city</mat-icon>
          <input matInput type="text" [value]="city" [disabled]="true" />
        </mat-form-field>
      </div>

      <div class="limit-width">
        <form *ngIf="!otpVerified" class="column" novalidate>
          <mat-form-field color="accent">
            <mat-label>My Phone Number</mat-label>
            <mat-icon matIconPrefix color="accent">phone_iphone</mat-icon>
            <input type="tel" maxlength="10" matInput [formControl]="mobileNo" placeholder="My Phone Number"
              autofocus />
            <mat-error *ngIf="mobileNo.hasError('required')">
              Phone number is required.
            </mat-error>
            <mat-error *ngIf="mobileNo.touched && mobileNo.hasError('pattern')">
              Please enter a valid phone number.
            </mat-error>
          </mat-form-field>

          <app-otp-input *ngIf="otpSent" #otpInput [otpInputPlaceholders]="['✲','✲','✲','✲','✲','✲']"
            (otpEntered)="onOtpEntered($event)" style="margin-bottom: 20px;"></app-otp-input>
          <div class="row" style="gap:10px;">
            <button mat-flat-button color="accent" *ngIf="!otpSent" (click)="sendOTP()">Send
              OTP</button>
            <button mat-flat-button color="accent" *ngIf="otpSent" (click)="verifyOTP()">Verify
              OTP</button>
            <button mat-stroked-button color="accent" *ngIf="otpSent" (click)="sendOTP(true)"
              [disabled]="resendOtpTimeLeft>0">
              {{resendOtpTimeLeft>0 ? "Resend OTP in "+
              resendOtpTimeLeft + " secs" : "Resend OTP"}}</button>
          </div>
        </form>
      </div>
      <div class="form-fill-text-container">
        <p class="description" *ngIf="otpVerified">Please fill in
          the following details:</p>
      </div>
      <div *ngIf="otpVerified" class="e-waste-form-container">
        <div class="column">

          <mat-form-field (click)="picker.open()" color="accent">
            <mat-label>Dropoff Date</mat-label>
            <mat-icon matIconPrefix color="accent">calendar_month</mat-icon>
            <input style="cursor: pointer;" matInput [matDatepicker]="picker" [readonly]="true" [min]='minDate'
              [formControl]="appointmentDate">
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>

          <!-- <mat-form-field color="accent">
            <mat-label>Dropoff Address</mat-label>
            <mat-icon matIconPrefix color="accent">pin_drop</mat-icon>
            <input matInput [value]="agentData?.address?.formattedAddress" type="text" [disabled]="true" />
          </mat-form-field> -->

          <mat-form-field color="accent">
            <mat-label>Dropoff City</mat-label>
            <mat-icon matIconPrefix color="accent">location_city</mat-icon>
            <input matInput type="text" [value]="city" [disabled]="true" />
          </mat-form-field>

          <mat-form-field color="accent">
            <mat-label>My Phone Number</mat-label>
            <mat-icon matIconPrefix color="accent">phone_iphone</mat-icon>
            <input type="tel" maxlength="10" matInput [formControl]="mobileNo" placeholder="My Phone Number" />
            <mat-error *ngIf="mobileNo.hasError('required')">
              Phone number is required.
            </mat-error>
            <mat-error *ngIf="mobileNo.touched && mobileNo.hasError('pattern')">
              Please enter a valid phone number.
            </mat-error>
          </mat-form-field>

          <mat-form-field color="accent">
            <mat-label>My Name</mat-label>
            <mat-icon matIconPrefix color="accent">person</mat-icon>
            <input matInput type="text" [formControl]="customerName" autofocus />
            <mat-error *ngIf="customerName.hasError('required')">
              Name is required.
            </mat-error>
            <mat-error *ngIf="customerName.hasError('alphaOnly')">
              Please enter a valid name.
            </mat-error>
          </mat-form-field>

          <mat-form-field color="accent">
            <mat-label>Company Name</mat-label>
            <mat-icon matIconPrefix color="accent">store</mat-icon>
            <input matInput type="text" [formControl]="companyName" />
            <mat-error *ngIf="companyName.hasError('alphaOnly')">
              Please enter a valid company name.
            </mat-error>
          </mat-form-field>

          <mat-form-field color="accent">
            <mat-label>Email</mat-label>
            <mat-icon matIconPrefix color="accent">mail</mat-icon>
            <input matInput type="email" [formControl]="email" />
            <mat-error *ngIf="email.hasError('customEmail')">
              Please enter a valid email address.
            </mat-error>
          </mat-form-field>
        </div>

        <div class="column">

          <ng-container *ngIf="savedAddresses.length===0">
            <mat-form-field color="accent">
              <mat-label>My Address*</mat-label>
              <mat-icon matIconPrefix color="accent">person_pin_circle</mat-icon>
              <input type="text" matInput ngx-google-places-autocomplete [options]="options"
                (search)="formattedAddress='';categoryIndex.reset();" (onAddressChange)="handleAddressChange($event)"
                [value]="formattedAddress" (keydown.enter)="$event.preventDefault()" placeholder="Search Address">
              <button *ngIf="formattedAddress" matSuffix mat-icon-button aria-label="Clear"
                (click)="formattedAddress='';categoryIndex.reset();">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>

            <google-map [options]="mapOptions" #map width="100%" [height]="mapHeight" style="margin-bottom:24px;"
              [center]="center" [zoom]="zoom">
              <map-marker [position]="center" [options]="markerOptions"
                (mapDragend)="onMarkerDragEnd($event)"></map-marker>
            </google-map>

            <mat-form-field color="accent">
              <mat-label>My Address Type</mat-label>
              <mat-icon matIconPrefix color="accent">apartment</mat-icon>
              <mat-select [formControl]="categoryIndex" required>
                <mat-option [value]='index' *ngFor="let addressCategory of addressCategories;let index = index;">
                  {{addressCategory.name}}</mat-option>
              </mat-select>
              <mat-error *ngIf="categoryIndex.hasError('required')">
                Please select your address type
              </mat-error>
            </mat-form-field>

          </ng-container>

          <!-- <mat-form-field color="accent">
            <mat-label>Select E-Waste Item</mat-label>
            <mat-icon matIconPrefix color="accent">devices_other</mat-icon>
            <mat-select [formControl]="ewasteItemIndex">
              <mat-option [value]="index"
                *ngFor="let product of productsList;let index = index;">{{product.name}}</mat-option>
            </mat-select>
            <mat-error *ngIf="ewasteItemIndex.hasError('required')">
              Please select an e-waste item.
            </mat-error>
          </mat-form-field> -->

          <div *ngIf="productsList.length>0">
            <mat-label>Select E-Waste Item :</mat-label>
            <swiper class="products-swiper-container" [breakpoints]="breakpoints" #swiper [navigation]="true"
              [pagination]="true" [slidesPerGroup]="3" [slidesPerView]="3" [spaceBetween]="10">
              <ng-template swiperSlide *ngFor="let product of productsList;let index=index;">
                <div class="product-slide" (click)="ewasteItemIndex.setValue(index)">
                  <div class="image-container" [ngClass]="{'active': ewasteItemIndex.value === index}">
                    <img height="50px" width="50px" [src]="getProductImageSource(product.image)" [alt]="product.name" />
                  </div>
                  <p style="font-size: 12px;">{{product.name}}</p>
                </div>
              </ng-template>
            </swiper>
          </div>

          <div class="media-container">
            <p class="media-title">Upload Photos and a 10-Second Video During Drop-off</p>
            <div class="media-tiles">
              <div class="media-tile" *ngFor="let media of eWasteMedia;let index=index;">
                <ng-container *ngIf="!media.src;else showMedia">

                  <ng-container *ngIf="media.type==='video';else uploadImage;">
                    <div class="placeholder" (click)="uploadVideo(fileInput)"
                      [style.borderColor]="media.required && mediaBorderColor">
                      <mat-icon class="plus-icon" [style.color]="media.required && mediaBorderColor">upload</mat-icon>
                      <input type="file" capture="environment" #fileInput style="display:none;"
                        (change)="onMediaFileSelected($event, index)" [accept]="getMediaFormat(media.type)" />
                      <span class="label">{{media.displayName}}</span>
                    </div>
                  </ng-container>

                  <ng-template #uploadImage>
                    <!-- <div class="placeholder" (click)="uploadImageandCompress(index)"
                      [style.borderColor]="mediaBorderColor">
                      <mat-icon class="plus-icon" [style.color]="mediaBorderColor">upload</mat-icon>
                      <span class="label">{{media.displayName}}</span>
                    </div> -->
                    <div class="placeholder" (click)="imageFileInput.click()" [style.borderColor]="mediaBorderColor">
                      <mat-icon class="plus-icon" [style.color]="mediaBorderColor">upload</mat-icon>
                      <input type="file" capture="environment" #imageFileInput style="display:none;"
                        (change)="onMediaFileSelected($event, index)" [accept]="getMediaFormat(media.type)" />
                      <span class="label">{{media.displayName}}</span>
                    </div>
                  </ng-template>

                </ng-container>

                <ng-template #showMedia>
                  <div class="image-holder">
                    <img [src]="domSanitizer.bypassSecurityTrustResourceUrl(media.src)" *ngIf="media.type==='image'"
                      alt="Front Image" />
                    <video controls *ngIf="media.type==='video'">
                      <source [src]="domSanitizer.bypassSecurityTrustResourceUrl(media.src)" type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                    <mat-icon class="delete-icon" (click)="deleteMedia(index)">cancel</mat-icon>
                  </div>
                </ng-template>
              </div>
            </div>
          </div>
          <div style="display:flex;justify-content: center;">
            <button mat-flat-button color="accent" (click)="buttonClick.next('1')">Submit</button>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="showSummaryScreen && appointmentData">
      <div class="limit-width" style="margin-bottom:10px;">
        <p class="successMessage">
          Thanks for joining Mission E-Waste and taking a step
          towards a safe environment.
        </p>

        <div style="text-align: center;">
          <div class="sc__wrapper">
            <div id="js--sc--container"></div>
          </div>
        </div>
        <!-- <p class="successMessage">
          <strong style="font-size:20px; letter-spacing: 1px;">Congratulation!</strong>
          you have won the discount Coupon worth upto Rs. <strong>10,000</strong>.
        </p> -->
        <!-- <div class="gift-box">
          <img src="/assets/gift.svg" class="logo" height="auto" width="200" />
          <p class="heading">Congratulations!</p>
          <p class="description">You have won the discount Coupon worth upto Rs. <strong>10,000</strong></p>
        </div>
        <div class="coupon">
          <div class="coupon-code">
            <span>Coupon Code: {{appointmentData?.couponCode || '-'}}</span>
          </div>
          <span class="coupon-validity">Coupon Validity: {{appointmentData?.couponValidity || '-'}}</span>
        </div> -->
        <ng-container *ngIf="showTermsandConditions">
          <p style="font-size: 16px;line-height: 1.5;font-weight: 400;">
            Redeem the discount coupon at your nearest Celekt Mobile Store.
          </p>

          <div class="terms-conditions">
            <p>Terms and Conditions:</p>
            <ul>
              <li>The discount coupon is valid for a specified percentage or amount of eligible purchases at Celekt
                Mobiles.
              </li>
              <li>The coupon must be presented at the time of purchase to receive the discount.
              </li>
              <li>The coupon cannot be combined with other offers or promotions unless otherwise stated.
              </li>
              <li>It is applicable only to eligible products/services and cannot be redeemed for cash or applied to
                prior purchases.
              </li>
              <li>Celekt Mobiles reserves the right to modify or cancel the coupon at its discretion.</li>
            </ul>
          </div>
        </ng-container>

        <mat-card style="width:100%;margin-top:20px;">
          <p class="dropoff-summary-title">Summary</p>
          <mat-list class="summary-card-container">
            <div>
              <!-- <mat-list-item>
                <mat-icon matListItemIcon>vpn_key_alert</mat-icon>
                <div matListItemTitle>Coupon Code</div>
                <div matListItemLine>{{appointmentData.couponCode || '-'}}</div>
              </mat-list-item>
              <mat-list-item>
                <mat-icon matListItemIcon>schedule</mat-icon>
                <div matListItemTitle>Coupon Validity</div>
                <div matListItemLine>{{appointmentData.couponValidity || '-'}}</div>
              </mat-list-item> -->
              <mat-list-item>
                <mat-icon matListItemIcon>calendar_month</mat-icon>
                <div matListItemTitle>Dropoff Date</div>
                <div matListItemLine>{{appointmentData.preferredDate || '-'}}</div>
              </mat-list-item>
              <mat-list-item lines="3">
                <mat-icon matListItemIcon>pin_drop</mat-icon>
                <div matListItemTitle>DropOff Address</div>
                {{appointmentData.agentAddress.formattedAddress || '-'}}
              </mat-list-item>
              <mat-list-item>
                <mat-icon matListItemIcon>location_city</mat-icon>
                <div matListItemTitle>Dropoff City</div>
                <div matListItemLine>{{appointmentData.city || '-'}}</div>
              </mat-list-item>
              <mat-list-item>
                <mat-icon matListItemIcon>person</mat-icon>
                <div matListItemTitle>Name</div>
                <div matListItemLine>{{appointmentData.consumerName || '-'}}</div>
              </mat-list-item>
            </div>
            <div>
              <!-- <mat-list-item lines="3">
                <mat-icon matListItemIcon>store</mat-icon>
                <div matListItemTitle>Company Name</div>
                <div matListItemLine>{{appointmentData?.companyName || '-'}}</div>
              </mat-list-item> -->
              <mat-list-item>
                <mat-icon matListItemIcon>phone_iphone</mat-icon>
                <div matListItemTitle>Phone Number</div>
                <div matListItemLine>{{appointmentData.consumerMobileNo || '-'}}</div>
              </mat-list-item>
              <mat-list-item lines="2">
                <mat-icon matListItemIcon>mail</mat-icon>
                <div matListItemTitle>Email</div>
                <div matListItemLine>{{appointmentData.consumerEmailId || '-'}}</div>
              </mat-list-item>
              <!-- <mat-list-item lines="3">
                <mat-icon matListItemIcon>person_pin_circle</mat-icon>
                <div matListItemTitle>Address</div>
                {{appointmentData.consumerAddress.formattedAddress || '-'}}
              </mat-list-item><mat-list-item lines="3">
                <mat-icon matListItemIcon>person_pin_circle</mat-icon>
                <div matListItemTitle>Address</div>
                {{appointmentData.consumerAddress.formattedAddress || '-'}}
              </mat-list-item>
              <mat-list-item>
                <mat-icon matListItemIcon>apartment</mat-icon>
                <div matListItemTitle>Address Type</div>
                <div matListItemLine>{{appointmentData.consumerAddress.categories[0].value || '-'}}</div>
              </mat-list-item> -->
              <mat-list-item>
                <mat-icon matListItemIcon>devices_other</mat-icon>
                <div matListItemTitle>E-waste Item</div>
                <div matListItemLine>{{appointmentData.items[0].categoryName || '-'}}</div>
              </mat-list-item>
            </div>
          </mat-list>
          <mat-list-item>
            <mat-icon matListItemIcon>image</mat-icon>
            <div matListItemTitle>Photos and Video</div>
          </mat-list-item>
          <div class="media-summary-container">
            <div class=" media-tiles">
              <div class="media-tile" (click)="showMediaDialog('Front Photo',appointmentData.items[0].frontImageURL)">
                <div class="image-holder">
                  <img [src]="appointmentData.items[0].frontImageURL" alt="Front Photo" />
                  <mat-icon class="zoom-icon">zoom_out_map</mat-icon>
                </div>
              </div>
              <div class="media-tile" (click)="showMediaDialog('Back Photo',appointmentData.items[0].backImageURL)">
                <div class="image-holder">
                  <img [src]="appointmentData.items[0].backImageURL" alt="Back Photo" />
                  <mat-icon class="zoom-icon">zoom_out_map</mat-icon>
                </div>
              </div>
              <ng-container *ngIf="appointmentData.items[0]?.videoURL">
                <div class="media-tile"
                  (click)="showMediaDialog('Dropoff Video',appointmentData.items[0].videoURL,'video')">
                  <div class="image-holder">
                    <video [poster]="appointmentData.items[0].frontImageURL" controlsList="nodownload"
                      [src]="appointmentData.items[0].videoURL" title="Dropoff Video">
                      Your browser does not support the video tag.
                    </video>
                    <mat-icon class="video-icon">play_circle</mat-icon>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </mat-card>
      </div>
    </ng-container>

    <div id="overlay" *ngIf="loading">
      <div id="overlayText"><mat-spinner [diameter]="50" color="accent"></mat-spinner></div>
    </div>

  </div>
</div>