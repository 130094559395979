import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MapMarker } from '@angular/google-maps';
import { BookAppointmentService } from 'src/app/services/book-appointment/book-appointment.service';

@Component({
  selector: 'app-book-appointment-map',
  templateUrl: './book-appointment-map.component.html',
  styleUrls: ['./book-appointment-map.component.scss']
})
export class BookAppointmentMapComponent implements OnInit, AfterViewInit {
  @ViewChild(MapMarker) marker!: MapMarker;
  mapOptions: google.maps.MapOptions = {
    zoom: 14
  };
  markerOptions: google.maps.MarkerOptions = {
    draggable: false, clickable: false,
    icon: {
      url: '/assets/consumer-marker.svg',
      scaledSize: { width: 50, height: 50 } as google.maps.Size
    },
  };
  mapCenter: google.maps.LatLngLiteral = { lat: 17.4280, lng: 78.4551 };

  constructor(private bookAppointmentService: BookAppointmentService) {
  }

  ngOnInit(): void {
    this.bookAppointmentService.getLatLng().subscribe((latLng) => {
      const { latitude, longitude } = latLng;
      this.mapCenter = { lat: latitude, lng: longitude };
    });
  }

  ngAfterViewInit(): void {
    this.bookAppointmentService.getMarkerDragStatus().subscribe((result) => {
      this.marker.marker?.setDraggable(result);
    })
  }

  onMarkerDragEnd(event: google.maps.MapMouseEvent) {
    console.log('marke end called')
    if (event.latLng) {
      const { lat: latitude, lng: longitude } = event.latLng.toJSON();
      this.bookAppointmentService.onMarkerDragEnd({ latitude, longitude });
    }
  }
}
