import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';

interface LatLng {
  latitude: number;
  longitude: number;
}

@Injectable({
  providedIn: 'root'
})
export class BookAppointmentService {
  private showMapSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private dropOffMapSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private latLngSubject: BehaviorSubject<LatLng> = new BehaviorSubject<LatLng>({ latitude: 0, longitude: 0 });
  private markerDragEndSubject: BehaviorSubject<LatLng | null> = new BehaviorSubject<LatLng | null>(null);
  private markerDragEnabledSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  constructor() { }

  // Method to toggle the showMap value
  toggleShowMap(): void {
    this.showMapSubject.next(!this.showMapSubject);
  }

  showMap(): void {
    this.showMapSubject.next(true);
  }

  hideMap(): void {
    this.showMapSubject.next(false);
  }

  // Method to get the current value of showMap as an Observable
  getShowMap(): Observable<boolean> {
    return this.showMapSubject.asObservable();
  }


  showDropOffMap(): void {
    this.dropOffMapSubject.next(true);
  }

  hideDropOffMap(): void {
    this.dropOffMapSubject.next(false);
  }

  // Method to get the current value of dropOffMap as an Observable
  getDropOffMap(): Observable<boolean> {
    return this.dropOffMapSubject.asObservable();
  }



  // Methods to set and get latitude and longitude
  setLatLng(lat: number, lng: number): void {
    console.log('value', lat, lng)
    const latLng: LatLng = { latitude: lat, longitude: lng };
    this.latLngSubject.next(latLng);
  }

  getLatLng(): Observable<LatLng> {
    return this.latLngSubject.asObservable();
  }

  // Method to set the marker drag end event
  onMarkerDragEnd(latLng: LatLng): void {
    this.markerDragEndSubject.next(latLng);
  }

  // Method to get the marker drag end event as an Observable
  getMarkerDragEnd(): Observable<LatLng | null> {
    return this.markerDragEndSubject.asObservable();
  }

  // Method to enable marker dragging
  enableMarkerDrag(): void {
    this.markerDragEnabledSubject.next(true);
  }

  // Method to disable marker dragging
  disableMarkerDrag(): void {
    this.markerDragEnabledSubject.next(false);
  }

  // Method to get the marker dragging status as an Observable
  getMarkerDragStatus(): Observable<boolean> {
    return this.markerDragEnabledSubject.asObservable();
  }
}
