<div class="global-container global-container--without-mx">
  <swiper [config]="swiperConfig">
    <ng-template swiperSlide *ngFor="let item of innovations;">
      <div class="innovation" [style.backgroundImage]="'url(' + item.imageUrl + ')'">
        <div class="overlay">
          <div class="innovation__title">{{ item.title }}</div>
          <p class="innovation__description">{{ item.description }}</p>
        </div>
      </div>
    </ng-template>
  </swiper>
</div>