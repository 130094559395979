import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AboutUsComponent } from './components/about-us/about-us.component';
import { BookAppointmentComponent } from './components/book-appointment/book-appointment.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { FaqsComponent } from './components/faqs/faqs.component';
import { HomeComponent } from './components/home/home.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { NetworkComponent } from './components/network/network.component';
import { DropoffPointsMapComponent } from './components/dropoff-points-map/dropoff-points-map.component';
import { MainLayoutComponent } from './layouts/main-layout/main-layout.component';
import { EWasteDropOffLayoutComponent } from './layouts/e-waste-drop-off-layout/e-waste-drop-off-layout.component';

const routes: Routes = [
  {
    path: '',
    component: MainLayoutComponent,
    children: [
      {
        path: '',
        component: HomeComponent,
        data: { title: 'Recycling is divine' }
      },
      {
        path: 'about-us',
        component: AboutUsComponent
      },
      {
        path: 'book-appointment',
        component: BookAppointmentComponent
      },

      {
        path: 'faqs',
        component: FaqsComponent
      },
      {
        path: 'contact-us',
        component: ContactUsComponent
      },
      {
        path: 'our-networks',
        component: NetworkComponent
      },
      {
        path: 'privacy-policy',
        component: PrivacyPolicyComponent
      },
      {
        path: 'drop-off',
        component: DropoffPointsMapComponent
      },

      {
        path: 'content',
        loadChildren: () => import('./components/blogs/blogs.module').then(m => m.BlogsModule)
      }
    ]
  },
  {
    path: 'celekt',
    component: EWasteDropOffLayoutComponent,
    loadChildren: () => import('./components/celekt/celekt.module').then(m => m.CelektModule)
  },
  {
    path: '**',
    component: PageNotFoundComponent,
    data: { title: 'Page Not Found' }
  }
];
@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
