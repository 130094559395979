<div class="main-container">
  <div class="container">
    <div class="limit-width">
      <div class="column">
        <mat-form-field color="accent">
          <mat-label>Coupon Code/Mobile Number</mat-label>
          <mat-icon matIconPrefix color="accent">search</mat-icon>
          <input matInput type="text" [formControl]="searchBox" autofocus />
          <mat-error *ngIf="searchBox.touched && searchBox.hasError('required')">
            Coupon Code/Mobile number is required
          </mat-error>
        </mat-form-field>
        <div style="display:flex;justify-content: center;">
          <button [disabled]="searchBox.invalid" mat-flat-button color="accent" (click)="openCoupon()">Submit</button>
        </div>
      </div>
    </div>
  </div>
</div>