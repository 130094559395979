import { Component, ElementRef, EventEmitter, OnInit, Input, Output, QueryList, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-otp-input',
  templateUrl: './otp-input.component.html',
  styleUrls: ['./otp-input.component.scss']
})
export class OtpInputComponent implements OnInit {
  @ViewChildren('digit1, digit2, digit3, digit4, digit5, digit6') otpInputs!: QueryList<ElementRef>;
  otpForm: FormGroup;
  @Output() otpEntered: EventEmitter<string> = new EventEmitter<string>();
  @Input() otpInputPlaceholders = ['S', 'C', 'R', 'A', 'P', 'Q'];

  constructor(private formBuilder: FormBuilder) {
    this.otpForm = this.formBuilder.group({
      digit1: ['', Validators.required],
      digit2: ['', Validators.required],
      digit3: ['', Validators.required],
      digit4: ['', Validators.required],
      digit5: ['', Validators.required],
      digit6: ['', Validators.required]
    });
  }

  ngOnInit(): void {
    this.otpForm.valueChanges.subscribe((value) => {
      const otp = Object.values(value).join('');
      this.otpEntered.emit(otp);
    })
  }

  onInputChange(digitIndex: number) {
    const currentDigit = this.otpInputs.toArray()[digitIndex - 1];
    const nextDigitIndex = digitIndex;

    if (currentDigit.nativeElement.value !== '') {
      if (nextDigitIndex < this.otpInputs.length) {
        const nextDigit = this.otpInputs.toArray()[digitIndex];
        nextDigit.nativeElement.focus();
      }
    }
  }

  onInputKeydown(event: KeyboardEvent, digitIndex: number) {
    if (event.key === 'ArrowRight' || event.key === 'ArrowLeft') {
      event.preventDefault();

      const nextDigitIndex = digitIndex + (event.key === 'ArrowRight' ? 1 : -1);

      if (nextDigitIndex > 0 && nextDigitIndex <= this.otpInputs.length) {
        const nextDigit = this.otpInputs.toArray()[nextDigitIndex - 1];
        nextDigit.nativeElement.focus();
      }
    } else if (event.key === 'Backspace') {
      const currentDigit = this.otpInputs.toArray()[digitIndex - 1];

      if (currentDigit.nativeElement.value === '') {
        const prevDigitIndex = digitIndex - 1;
        if (prevDigitIndex > 0) {
          const prevDigit = this.otpInputs.toArray()[prevDigitIndex - 1];
          prevDigit.nativeElement.focus();
        }
      }
    }
  }

  resetOtp() {
    this.otpForm.reset();
  }

}
