<div class="main-container">
  <div class="container">
    <h1 class="title">Book Appointment</h1>
    <div class="form-container">
      <p class="description">Please give us your mobile number, address, date. We will have our
        REO’s come and collect your recyclables</p>
      <form *ngIf="!otpVerified" class="column" [formGroup]="mobileVerificationForm" novalidate>
        <mat-form-field>
          <mat-label>My Phone Number</mat-label>
          <mat-icon matIconPrefix color="primary">phone_iphone</mat-icon>
          <input type="tel" maxlength="10" matInput formControlName="mobile" placeholder="My Phone Number" />
        </mat-form-field>

        <app-otp-input *ngIf="otpSent" #otpInput (otpEntered)="onOtpEntered($event)"></app-otp-input>
        <div class="row">
          <button mat-flat-button color="primary" *ngIf="!otpSent" (click)="sendOTP()">Send
            OTP</button>
          <button mat-flat-button color="primary" *ngIf="otpSent" (click)="verifyOTP()">Verify
            OTP</button>
          <button mat-flat-button color="primary" *ngIf="otpSent" (click)="sendOTP()" [disabled]="resendOtpTimeLeft>0"
            [class.disabled-button]="resendOtpTimeLeft>0">
            {{resendOtpTimeLeft>0 ? "Resend OTP in "+
            resendOtpTimeLeft + " secs" : "Resend OTP"}}</button>
        </div>
      </form>

      <div *ngIf="otpVerified" class="column">
        <mat-form-field>
          <mat-label>My Phone Number</mat-label>
          <mat-icon matIconPrefix color="primary">phone_iphone</mat-icon>
          <input matInput disabled [value]="mobileVerificationForm.controls['mobile'].value" />
        </mat-form-field>

        <mat-form-field *ngIf="showSavedAddressedDropdown && savedAddresses.length>0;else showSearchLocation">
          <mat-label>My Location</mat-label>
          <mat-icon matIconPrefix color="primary">person_pin_circle</mat-icon>
          <mat-select [formControl]="addressId">
            <mat-option [value]='address.addressId' *ngFor="let address of savedAddresses;">
              {{address.formattedAddress}}</mat-option>
          </mat-select>
          <button matTooltip="Add new location" matSuffix mat-icon-button aria-label="Add new location"
            (click)="formattedAddress='';categoryId='';disableAddressCategory=false;showSavedAddressedDropdown=false;markerOptions.draggable=true;">
            <mat-icon>add</mat-icon>
          </button>
        </mat-form-field>

        <ng-template #showSearchLocation>
          <mat-form-field>
            <mat-label>My Location</mat-label>
            <mat-icon matIconPrefix color="primary">person_pin_circle</mat-icon>
            <input type="text" matInput ngx-google-places-autocomplete [options]="options"
              (search)="formattedAddress='';categoryId='';disableAddressCategory=false;"
              (onAddressChange)="handleAddressChange($event)" [value]="formattedAddress"
              (keydown.enter)="$event.preventDefault()" placeholder="Search Location" autofocus>
            <button *ngIf="formattedAddress" matSuffix mat-icon-button aria-label="Clear"
              (click)="formattedAddress='';categoryId='';disableAddressCategory=false;">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
        </ng-template>

        <mat-form-field>
          <mat-label>My Address Type</mat-label>
          <mat-icon matIconPrefix color="primary">apartment</mat-icon>
          <mat-select [value]="categoryId" [(ngModel)]="categoryId"
            (ngModelChange)="handleAddressCategoryChange($event)" [disabled]="disableAddressCategory">
            <mat-option [value]='addressCategory.consumerCategoryId' *ngFor="let addressCategory of addressCategories;">
              {{addressCategory.name}}</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field (click)="picker.open()">
          <mat-label>My Appointment Date</mat-label>
          <mat-icon matIconPrefix color="primary">calendar_month</mat-icon>
          <input style="cursor: pointer;" matInput [matDatepicker]="picker" [matDatepickerFilter]="filterHolidays"
            [readonly]="true" [min]='minDate' [max]="maxDate" [formControl]="appointmentDate">
          <mat-hint *ngIf="holidayMessage">{{holidayMessage}}</mat-hint>
          <mat-datepicker #picker></mat-datepicker>
          <mat-spinner matSuffix diameter="30" *ngIf="isholidaysLoading"></mat-spinner>
        </mat-form-field>

        <div style="margin-top: 16px;">
          <button mat-flat-button color="primary" (click)="buttonClick.next('1')">Book Appointment</button>
        </div>
      </div>

      <p class="successMessage" *ngIf="showDownloadAppMessage">We have an easy solution for modifying or canceling your
        appointment! Simply download the user-friendly <span class="successMessage"><strong>ScrapQ
            App</strong></span> from
        either the Play Store or
        the App Store. It's
        quick and hassle-free!</p>
    </div>

    <google-map #map [width]="mapWidth" height="400px" class="google-map" [center]="center" [zoom]="zoom">
      <map-marker [position]="center" [options]="markerOptions" (mapDragend)="onMarkerDragEnd($event)"></map-marker>
    </google-map>

    <div id="overlay" *ngIf="loading">
      <div id="overlayText"><mat-spinner [diameter]="50"></mat-spinner></div>
    </div>

  </div>
</div>