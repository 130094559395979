<mat-toolbar>
  <!-- <div class="top-bar">
    <div class="social-media-icons">
      <a href="https://www.facebook.com/ScrapQ.recycle/?hc_ref=ARQGT6H0H-SrosX7p2T5sRqdAB_dGylll9P76qFk6QGW0Su5jOS4l6KXoeldFtYLEt8&fref=nf"
        target="_blank" rel="noreferrer">
        <img src="assets/Icons-01.png" height="15" width="15" />
      </a>
      <a href="https://twitter.com/ScrapQ_recycle" target="_blank" rel="noreferrer">
        <img src="assets/Icons-03.png" height="15" width="15" />
      </a>
      <a href="https://in.linkedin.com/company/scrapq" target="_blank" rel="noreferrer">
        <img src="assets/Icons-05.png" height="15" width="15" />
      </a>
      <a href="https://www.instagram.com/scrapq.recycle/" target="_blank" rel="noreferrer">
        <img src="assets/Icons-04.png" height="15" width="15" />
      </a>
    </div>

    <div class="contact">
      <a class="phone" href="tel:+919030727277"> <mat-icon>phone</mat-icon>
        <span>+91 903 072 7277</span></a>
      <a class="email" href="email:support@scrapq.com">
        <mat-icon>email</mat-icon> <span>support@scrapq.com</span></a>
    </div>
  </div> -->
  <mat-toolbar-row class="logo-section">
    <img src="/assets/scrapq.png" routerLink="" class="logo" height="auto" width="40" />
    <div style="width: 50%; display:flex;justify-content: flex-end; align-items: center;gap:15px;">
      <div class="menu-links-desktop">
        <ul>
          <li>
            <a routerLink="/" [routerLinkActiveOptions]="{exact:
              true}" routerLinkActive="link-active">Home</a>
          </li>
          <li>
            <a routerLink="/about-us" routerLinkActive="link-active">About ScrapQ </a>
          </li>
          <li>
            <a routerLink="/book-appointment" routerLinkActive="link-active">Book Appointment</a>
          </li>
          <li>
            <a routerLink="/our-networks" routerLinkActive="link-active">Our Networks </a>
          </li>
          <!-- <li>
            <a routerLink="/faqs" routerLinkActive="link-active">Facts & FAQ's </a>
          </li> -->
          <li>
            <a routerLink="/contact-us" routerLinkActive="link-active">Contact Us </a>
          </li>
          <!-- <li>
            <a routerLink="/privacy-policy" routerLinkActive="link-active">Privacy policy </a>
          </li> -->
        </ul>
      </div>
      <select [(ngModel)]="userCity" (change)="saveUserCity()" class="city-select">
        <option [value]="city.name" *ngFor="let city of citiesData">{{city.name}}</option>
      </select>
      <button *ngIf="showMenu" class="menu-icon" mat-icon-button (click)="openSideMenu()" style="margin-left:5px;">
        <mat-icon color="primary">menu</mat-icon>
      </button>
    </div>
  </mat-toolbar-row>
  <mat-toolbar-row class="locc">
    <div style="width: 100%; display:flex;justify-content: flex-end; align-items: center;gap:10px;">
      <select [(ngModel)]="userCity" (change)="saveUserCity()" class="city-select1">
        <option [value]="city.name" *ngFor="let city of citiesData">{{city.name}}</option>
      </select>
    </div>

  </mat-toolbar-row>

</mat-toolbar>