import { Component } from '@angular/core';
import { AutoplayOptions, SwiperOptions } from 'swiper/types';

@Component({
  selector: 'app-our-clients',
  templateUrl: './our-clients.component.html',
  styleUrls: ['./our-clients.component.scss']
})
export class OurClientsComponent {
  clients: any;

  swiperOptions: SwiperOptions = {
    loop: true,
    // pagination: { clickable: true },
    autoplay: {
      delay: 0,
      disableOnInteraction: false
    },
    // grid: { rows: 2, fill: 'row' },
    // slidesPerView: 4,
    slidesPerGroup: 1,
    spaceBetween: 30,
    speed: 1000,
    grabCursor: true,
    breakpoints: {
      '0': {
        slidesPerView: 1,
        spaceBetween: 10,
      },
      '600': {
        slidesPerView: 2,
        spaceBetween: 10,
      },
      '770': {
        slidesPerView: 4,
        spaceBetween: 10,
      },
      '991': {
        slidesPerView: 5,
        spaceBetween: 10
      },
      // '1024': {
      //   slidesPerView: 3,
      //   spaceBetween: 10
      // },
      // '1440': {
      //   slidesPerView: 4,
      //   spaceBetween: 0
      // }
    }
  };



  constructor() {
    this.clients = [
      {
        image: `/assets/Indian_Railways.svg.png`,
      },
      {
        image: `/assets/Indian_Railways.svg.png`,
      },
      {
        image: `/assets/Indian_Railways.svg.png`,
      },
      {
        image: `/assets/Indian_Railways.svg.png`,
      }, {
        image: `/assets/Indian_Railways.svg.png`,
      },
      {
        image: `/assets/Indian_Railways.svg.png`,
      }
    ];
  }

}
