<div class="container">

  <div class="card-container">
    <div *ngIf="!selectedTabIndex || selectedTabIndex===2" class="preview-content">
      <div class="card-initial-text-container">
        <div>
          <p class="card-initial-text">Book an Appointment</p>
        </div>
      </div>
      <!-- <div class="card-initial-text-container">
        <div>
          <p class="card-initial-text">Drop Off Points</p>
        </div>
      </div> -->
      <div class="card-initial-text-container">
        <div>
          <p class="card-initial-text">Call us at</p>
          <p class="card-initial-text">90 30 72 72 77</p>
        </div>
      </div>
      <div class="card-initial-text-container">
        <div>
          <p class="card-initial-text">Missed Call</p>
          <p class="card-initial-text">040 30 70 70 70</p>
        </div>
      </div>
      <div class="card-initial-text-container">
        <div>
          <p class="card-initial-text">Send a message to</p>
          <p class="card-initial-text">90 30 72 72 77</p>
        </div>
      </div>
      <div class="card-initial-text-container">
        <div>
          <p class="card-initial-text">WhatsApp us to</p>
          <p class="card-initial-text">90 30 72 72 77</p>
        </div>
      </div>
      <div class="card-initial-text-container card-initial-text-container-last-item">
        <a href="https://play.google.com/store/apps/details?id=com.scrapq.consumer&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1&pli=1"
          target="_blank">
          <img src="/assets/Google_Play.svg" width="100px" height="40px">
        </a>
        <a href="https://apps.apple.com/in/app/scrapq/id1296158469" target="_blank">
          <img src="/assets/Icons-14.png" width="100px" height="33px">
        </a>
      </div>

    </div>

    <div *ngIf="selectedTabIndex===1">
      <div class=" card-initial-text-container">
        <div>
          <p class="card-initial-text">Book an Appointment</p>
        </div>
      </div>
      <div class="selected-tab-content-container">
        <p class="card-text">Please give your details for our REO (<b>RE</b>cycling <b>O</b>fficer) to pick up your
          recyclables.</p>

        <ng-container *ngIf="!otpVerified">
          <form class="form-container" [formGroup]="mobileVerificationForm" novalidate>
            <div class="input-container">
              <span class="icon-prefix">
                <img src="/assets/phone-icon.png" alt="Phone Icon">
              </span>
              <input type="tel" placeholder="My Phone Number" formControlName="mobile" maxlength="10">
            </div>
            <button class="generate-otp-button" *ngIf="!otpSent" (click)="sendOTP()">Send
              OTP</button>
          </form>

          <app-otp-input *ngIf="otpSent" (otpEntered)="onOtpEntered($event)"></app-otp-input>

          <div class="otp-verification">
            <button class="verify-button" *ngIf="otpSent" (click)="verifyOTP()">Verify</button>
            <button *ngIf="otpSent && resendOtpTimeLeft> 0" class="resend-timer-button" [disabled]="true">
              Resend OTP {{ resendOtpTimeLeft }}
            </button>
            <button class="resend-button" *ngIf="otpSent && resendOtpTimeLeft=== 0" (click)="sendOTP()">Resend
              OTP</button>
          </div>

        </ng-container>
        <ng-container *ngIf="otpVerified">
          <div class="input-container">
            <span class="icon-prefix">
              <img src="/assets/phone-icon.png" alt="Phone Icon">
            </span>
            <input type="text" placeholder="My Phone Number" disabled
              [value]="mobileVerificationForm.controls['mobile'].value">
          </div>

          <div class="input-container" style="position:relative;" (click)="addressDropdown.toggleDropdown()"
            *ngIf="showDefaultLocation;else searchLocation">
            <span class="icon-prefix">
              <img src="/assets/marker-icon.png" alt="Phone Icon">
            </span>
            <select id="savedAddress" [formControl]="addressId" class="dropdown-input saved-location">
              <option [value]="address.addressId" *ngFor="let address of savedAddresses;">{{address.formattedAddress}}
              </option>
            </select>
            <span class="icon-suffix" style="display:flex;align-items:center;gap:6px;color:black;">
              <span style=" font-size:25px;
              transform: scale(0.6);cursor: default;">&#9660;</span>
              <span matTooltip="add new location" style=" font-size:25px;margin-top:-3px;
              transform: scale(1);cursor: pointer;" (click)="showLocationSearchBox()">&plus;</span>
            </span>
            <app-custom-dropdown #addressDropdown (optionSelected)="addressSelected($event)"
              [options]="savedAddresses"></app-custom-dropdown>
          </div>


          <ng-template #searchLocation>
            <div class="input-container">
              <span class="icon-prefix">
                <img src="/assets/marker-icon.png" alt="Phone Icon">
              </span>
              <input type="search" class="search-location" ngx-google-places-autocomplete [options]="options"
                (search)="formattedAddress='';categoryId='';disableAddressCategory=false;"
                (onAddressChange)="handleAddressChange($event)" [value]="formattedAddress"
                (keydown.enter)="$event.preventDefault()" placeholder="Search Location">
            </div>
          </ng-template>

          <div class="input-container">
            <span class="icon-prefix">
              <img src="/assets/marker-icon.png" alt="Phone Icon">
            </span>
            <select id="addressType" class="dropdown-input" [(ngModel)]="categoryId"
              (ngModelChange)="handleAddressCategoryChange($event)" [disabled]="disableAddressCategory">
              <option [value]='addressCategory.consumerCategoryId' *ngFor="let addressCategory of addressCategories;">
                {{addressCategory.name}}</option>
            </select>
          </div>

          <div class="input-container" (click)="picker.open()">
            <span class="icon-prefix">
              <img src="/assets/calendar-icon.png" alt="Phone Icon">
            </span>
            <input style="cursor: pointer;" [matDatepicker]="picker" [readonly]="true"
              [matDatepickerFilter]="filterHolidays" [min]='minDate' [max]="maxDate" [formControl]="appointmentDate">
            <mat-datepicker #picker></mat-datepicker>
          </div>
          <p *ngIf="holidayMessage" style="font-size: 12px;padding-left: 5px; margin:0;">{{holidayMessage}}</p>
          <button class="generate-otp-button" (click)="buttonClick.next('1')">Go</button>
        </ng-container>
      </div>
    </div>

    <div *ngIf="selectedTabIndex===3">
      <div class=" card-initial-text-container">
        <div>
          <p class="card-initial-text">Call Me Back</p>
        </div>
      </div>
      <div class="selected-tab-content-container">
        <p class="card-text">
          Give us your phone number, our customer care executive will call you back to setup your appointment.
        </p>
        <div class="input-container">
          <span class="icon-prefix">
            <img src="/assets/phone-icon.png" alt="Phone Icon">
          </span>
          <input type="tel" placeholder="My Phone Number" [(ngModel)]="mobileNo" maxlength="10">
        </div>
        <button class="submit-button" (click)="callMeBack()">Call Me</button>
      </div>
      <p class="errorMessage" *ngIf="errorMessage">{{errorMessage}}</p>
      <p class="succesMessage" *ngIf="successMessage">{{successMessage}}</p>
    </div>

    <div *ngIf="selectedTabIndex===4">
      <div class=" card-initial-text-container">
        <div>
          <p class="card-initial-text">Give A Missed Call</p>
        </div>
      </div>
      <div class="selected-tab-content-container">
        <p class="card-text">Now contacting ScrapQ is even simpler. All you have to do is, give us a missed call
          and
          expect to hear from our customer care executive.</p>
        <a class="missed-call-number" href="tel:04030707070">
          <img src="assets/Missed_Call.png" alt="Give a missed call" />
          040 30 70 70 70
        </a>
        <a class="missed-call-number" href="tel:+919030727277">
          <img src="assets/Missed_Call.png" alt="Give a missed call" />
          90 30 72 72 77
        </a>
      </div>
    </div>

    <div *ngIf="selectedTabIndex===5">
      <div class=" card-initial-text-container">
        <div>
          <p class="card-initial-text">Send an SMS</p>
        </div>
      </div>
      <div class="selected-tab-content-container">
        <p class="card-text">Fix an appointment with us through an SMS.</p>
        <p class="card-text">Send SMS to 90 30 72 72 77 with date in DD-MMM format.</p>
        <p class="card-text">For example type: 05-AUG Send to: 90 30 72 72 77</p>
        <a class="missed-call-number" href="sms://+919030727277">
          <img src="assets/SMS.png" alt="send an sms" />
          90 30 72 72 77
        </a>
      </div>
    </div>

    <div *ngIf="selectedTabIndex===6">
      <div class=" card-initial-text-container">
        <div>
          <p class="card-initial-text">WhatsApp Us</p>
        </div>
      </div>
      <div class="selected-tab-content-container">
        <p class="card-text">Add us on your WhatsApp contact list. Get in touch through WhatsApp to setup an
          appointment
          and sell your recyclables.</p>
        <p class="card-text">WhatsApp your Location, Date and Time in DD-MMM format.</p>
        <p class="card-text">(05-AUG) to: +91 90 30 72 72 77</p>
        <a class="missed-call-number" href="whatsapp://send?abid=9030727277">
          <img src="assets/Whatsapp.png" alt="send an sms" />
          90 30 72 72 77
        </a>
      </div>
    </div>

    <div *ngIf="selectedTabIndex===7">
      <div class=" card-initial-text-container">
        <div>
          <p class="card-initial-text">Download App</p>
        </div>
      </div>
      <div class="selected-tab-content-container">
        <p class="card-text">
          Have you tried our cool ScrapQ App yet? Download it today! ScrapQ App is available on both Android
          and
          Iphones. Please download from Playstore or App Store today. Scan the below QR CODE for quick download
        </p>
        <div class="qr-img">
          <img src="assets/qr-code.png" alt="qr code" height="90">
        </div>
        <div class="row margin-top">
          <a
            href="https://play.google.com/store/apps/details?id=com.scrapq.consumer&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"><img
              src="/assets/Google_Play.svg" target="_blank" width="90"></a>
          <a href="https://apps.apple.com/in/app/scrapq/id1296158469"><img src="/assets/Apple.svg" target="_blank"
              width="90"></a>
        </div>
      </div>
    </div>

    <div class="card-tab-buttons">
      <div class="card-button desktop" [class.card-button-active]="selectedTabIndex===1"
        (click)="setSelectedTabIndex(1)">
        <img src="assets/Book_Appointment_White.png" alt="book appointment" />
      </div>
      <div class="card-button mobile" [class.card-button-active]="selectedTabIndex===1" routerLink="book-appointment">
        <img src="assets/Book_Appointment_White.png" alt="book appointment" />
      </div>
      <!-- <div class="card-button desktop" [class.card-button-active]="selectedTabIndex===2"
        (click)="setSelectedTabIndex(2)">
        <img src="assets/Book_Appointment_White.png" alt="book appointment" />
      </div>
      <div class="card-button mobile" [class.card-button-active]="selectedTabIndex===2" routerLink="drop-off">
        <img src="assets/Book_Appointment_White.png" alt="book appointment" />
      </div> -->
      <div class="card-button" [class.card-button-active]="selectedTabIndex===3" (click)="setSelectedTabIndex(3)">
        <img src="assets/Call_White.png" alt="Call Me Back" />
      </div>
      <div class="card-button" [class.card-button-active]="selectedTabIndex===4" (click)="setSelectedTabIndex(4)">
        <img src="assets/Missed_Call_White.png" alt="Give a missed call" />
      </div>
      <div class="card-button" [class.card-button-active]="selectedTabIndex===5" (click)="setSelectedTabIndex(5)">
        <img src="assets/SMS_White.png" alt="send an sms" />
      </div>
      <div class="card-button" [class.card-button-active]="selectedTabIndex===6" (click)="setSelectedTabIndex(6)">
        <img src="assets/wtsapp-white.png" alt="whatsapp us" />
      </div>
      <div class="card-button" [class.card-button-active]="selectedTabIndex===7" (click)="setSelectedTabIndex(7)">
        <img src="assets/Download_White.png" alt="download app" />
      </div>
    </div>

    <h3 class="title">Connect with us!</h3>
  </div>
</div>