<!-- drop off page layout -->
<ng-container *ngIf="showDropOffPageMode;else showMap">
  <div class="main-container">
    <div class="container">
      <h1 class="title">Drop Off Points</h1>
      <div class="address-carousel-container" *ngIf="dropOffAddresses.length>0">
        <button class="hide-on-mobile" mat-icon-button aria-label="prev" (click)="prevSlide()"
          style="place-self: center;" [disabled]="currentIndex===0">
          <mat-icon style=" transform: scale(2.5);">arrow_left</mat-icon>
        </button>
        <div style="min-width: 0;" class="address-card-container">
          <swiper #dropoffAddresses [config]="swiperOptions" class="address-carousel" (slideChange)="onSwiper($event)">
            <ng-template swiperSlide *ngFor="let address of dropOffAddresses;">
              <mat-card class="address-card">
                <mat-card-header>
                  <mat-card-title>{{address.name}}</mat-card-title>
                  <mat-card-subtitle>{{address.rrr ? 'RRR' : 'Stationary'}}</mat-card-subtitle>
                </mat-card-header>
                <mat-card-content>
                  <p class="address">{{address.formattedAddress}} </p>
                </mat-card-content>
                <mat-card-actions style="display:flex; justify-content:space-between;">
                  <button mat-button color="primary">{{address.distance}} away</button>
                  <a mat-button [href]="getDirectionLink(address)" color="primary" target="_blank">Get Directions</a>
                </mat-card-actions>
              </mat-card>
            </ng-template>
          </swiper>
        </div>
        <button class="hide-on-mobile" mat-icon-button aria-label="prev" (click)="nextSlide()"
          style="place-self: center;" [disabled]="(currentIndex+1)===dropOffAddresses.length">
          <mat-icon style=" transform: scale(2.5);">arrow_right</mat-icon>
        </button>
      </div>

      <google-map #map [height]="mapHeight" [width]="mapWidth" [options]="mapOptions" [center]="mapCenter"
        class="drop-points-map">
        <map-marker #marker [position]="mapCenter" [options]="currentLocationMarkerOptions"
          (mapClick)="openInfoCurrentLocationWindow()">
        </map-marker>
        <map-marker #marker *ngFor="let address of dropOffAddresses;let index=index;" [position]="getLatLng(address)"
          [options]="markerOptions" (mapClick)="openInfoWindow(index+1, address)">
        </map-marker>
        <map-info-window [innerHTML]="infoContent | safeHtml"></map-info-window>
      </google-map>

    </div>
  </div>
</ng-container>

<!-- Home Page drop off points background map -->
<ng-template #showMap>
  <google-map #map width="100%" height="100%" [options]="mapOptions" [center]="mapCenter">
    <map-marker #marker [position]="mapCenter" [options]="currentLocationMarkerOptions"
      (mapClick)="openInfoCurrentLocationWindow()">
    </map-marker>
    <map-marker #marker *ngFor="let address of dropOffAddresses;let index=index;" [position]="getLatLng(address)"
      [options]="markerOptions" (mapClick)="openInfoWindow(index+1, address)">
    </map-marker>
    <map-info-window [innerHTML]="infoContent | safeHtml"></map-info-window>
  </google-map>
</ng-template>