<div class="container">
  <div style="flex-grow: 1;">
    <swiper #swiper class="impact-created-carousel" [pagination]="true" [breakpoints]="breakpoints">
      <ng-template swiperSlide *ngFor="let item of impactCreatedData;let index=index;">
        <div [class]="'carousel-card-'+index">
          <p class="title">
            {{item[0].rowName}}</p>
          <p class="desc" *ngIf="item[0].enabled">
            {{item[0].fieldDescriptionOne}}<br><span
              style="font-size: 35px;">{{item[0].value}}</span><br>{{item[0].fieldDescriptionTwo}}</p>
          <p class="desc" *ngIf="item[1].enabled">
            {{item[1].fieldDescriptionOne}}<br><span
              style="font-size: 35px;">{{item[1].value}}</span><br>{{item[1].fieldDescriptionTwo}}</p>
          <p class="desc" *ngIf="item[2].enabled">{{item[2].fieldDescriptionOne}}<br><span
              style="font-size: 35px;">{{item[2].value}}</span><br>{{item[2].fieldDescriptionTwo}}</p>
        </div>
      </ng-template>
    </swiper>
  </div>
</div>