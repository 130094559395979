import { Injectable } from '@angular/core';
import { Platform } from '@angular/cdk/platform';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class DownloadAppService {

  constructor(private platform: Platform,
    private activatedRoute: ActivatedRoute) {
  }

  init() {
    this.activatedRoute.fragment.subscribe((fragment) => {
      if (fragment === 'download-app') {
        this.openAppStore();
      }
    })
  }

  openAppStore(): void {
    const platform = this.getPlatform();
    let appStoreLink: string;

    switch (platform) {
      case 'iOS':
        appStoreLink = 'https://apps.apple.com/in/app/scrapq/id1296158469';
        open(appStoreLink, '_blank');
        break;
      case 'Android':
        appStoreLink = 'https://play.google.com/store/apps/details?id=com.scrapq.consumer';
        window.open(appStoreLink, '_blank');
        break;
      default:
        // Handle unsupported platform or show a message
        console.log('Unsupported platform');
        break;
    }
  }

  private getPlatform(): string {
    if (this.platform.IOS) {
      return 'iOS';
    } else if (this.platform.ANDROID) {
      return 'Android';
    } else {
      return 'Unknown';
    }
  }
}
