<div class="main-container">
  <div class="container">

    <ng-container *ngIf="!otpVerified">
      <div class="limit-width">
        <form class="column" novalidate>
          <mat-form-field color="accent">
            <mat-label>My Phone Number</mat-label>
            <mat-icon matIconPrefix color="accent">phone_iphone</mat-icon>
            <input type="tel" maxlength="10" matInput [formControl]="mobileNo" placeholder="My Phone Number"
              autofocus />
            <mat-error *ngIf="mobileNo.hasError('required')">
              Phone number is required.
            </mat-error>
            <mat-error *ngIf="mobileNo.touched && mobileNo.hasError('pattern')">
              Please enter a valid phone number.
            </mat-error>
          </mat-form-field>

          <app-otp-input *ngIf="otpSent" #otpInput [otpInputPlaceholders]="['✲','✲','✲','✲','✲','✲']"
            (otpEntered)="onOtpEntered($event)" style="margin-bottom: 20px;"></app-otp-input>
          <div class="row" [style.justifyContent]="getJustifyContentValue(otpSent)" style="gap:10px;">
            <button mat-flat-button color="accent" *ngIf="!otpSent" [disabled]="mobileNo.invalid"
              (click)="sendOTP()">Send
              OTP</button>
            <button mat-flat-button color="accent" *ngIf="otpSent" (click)="verifyOTP()">Verify
              OTP</button>
            <button mat-stroked-button color="accent" *ngIf="otpSent" (click)="sendOTP(true)"
              [disabled]="resendOtpTimeLeft>0">
              {{resendOtpTimeLeft>0 ? "Resend OTP in "+
              resendOtpTimeLeft + " secs" : "Resend OTP"}}</button>
          </div>
        </form>
      </div>
    </ng-container>

    <ng-container *ngIf="sells.length>0 && otpVerified;">
      <div class="limit-width">
        <div class="column" style="margin-top: 20px;" *ngIf="sells.length>1">
          <mat-form-field color="accent">
            <mat-label>Coupon Codes</mat-label>
            <mat-icon matIconPrefix color="accent">vpn_key_alert</mat-icon>
            <mat-select [formControl]="sellIndex">
              <mat-option [value]='index' *ngFor="let sell of sells;let index=index;">
                {{sell.couponCode}} <span style="color:gray;font-size: 12px;">Validity: {{sell.couponValidity ||
                  'N/A'}}</span></mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div *ngIf="appointmentData" style="margin-bottom:10px;">
          <p class="successMessage">
            Thanks for joining Mission E-Waste and taking a step
            towards a safe environment.
          </p>
          <div class="gift-box">
            <img src="/assets/gift.svg" class="logo" height="auto" width="200" />
            <p class="heading">Congratulations!</p>
            <p class="description">You have won the discount Coupon worth upto Rs. <strong>10,000</strong></p>
          </div>
          <div class="coupon">
            <div class="coupon-code">
              <span>Coupon Code: {{appointmentData?.couponCode || '-'}}</span>
            </div>
            <span class="coupon-validity">Coupon Validity: {{appointmentData?.couponValidity || '-'}}</span>
          </div>
          <p style="font-size: 16px;line-height: 1.5;font-weight: 400;">
            Redeem the discount coupon at your nearest Celekt Mobile Store.
          </p>

          <div class="terms-conditions">
            <p>Terms and Conditions:</p>
            <ul>
              <li>The discount coupon is valid for a specified percentage or amount of eligible purchases at Celekt
                Mobiles.
              </li>
              <li>The coupon must be presented at the time of purchase to receive the discount.
              </li>
              <li>The coupon cannot be combined with other offers or promotions unless otherwise stated.
              </li>
              <li>It is applicable only to eligible products/services and cannot be redeemed for cash or applied to
                prior purchases.
              </li>
              <li>Celekt Mobiles reserves the right to modify or cancel the coupon at its discretion.</li>
            </ul>
          </div>

          <mat-card style="width:100%">
            <p class="dropoff-summary-title">Summary</p>
            <mat-list class="summary-card-container">
              <div>
                <!-- <mat-list-item>
                <mat-icon matListItemIcon>vpn_key_alert</mat-icon>
                <div matListItemTitle>Coupon Code</div>
                <div matListItemLine>{{appointmentData.couponCode || '-'}}</div>
              </mat-list-item>
              <mat-list-item>
                <mat-icon matListItemIcon>schedule</mat-icon>
                <div matListItemTitle>Coupon Validity</div>
                <div matListItemLine>{{appointmentData.couponValidity || '-'}}</div>
              </mat-list-item> -->
                <mat-list-item>
                  <mat-icon matListItemIcon>calendar_month</mat-icon>
                  <div matListItemTitle>Dropoff Date</div>
                  <div matListItemLine>{{appointmentData.preferredDate || '-'}}</div>
                </mat-list-item>
                <mat-list-item lines="3">
                  <mat-icon matListItemIcon>pin_drop</mat-icon>
                  <div matListItemTitle>DropOff Address</div>
                  {{appointmentData.agentAddress.formattedAddress || '-'}}
                </mat-list-item>
                <mat-list-item>
                  <mat-icon matListItemIcon>location_city</mat-icon>
                  <div matListItemTitle>Dropoff City</div>
                  <div matListItemLine>{{appointmentData.city || '-'}}</div>
                </mat-list-item>
                <mat-list-item>
                  <mat-icon matListItemIcon>person</mat-icon>
                  <div matListItemTitle>Name</div>
                  <div matListItemLine>{{appointmentData.consumerName || '-'}}</div>
                </mat-list-item>
              </div>
              <div>
                <!-- <mat-list-item lines="3">
                <mat-icon matListItemIcon>store</mat-icon>
                <div matListItemTitle>Company Name</div>
                <div matListItemLine>{{appointmentData?.companyName || '-'}}</div>
              </mat-list-item> -->
                <mat-list-item>
                  <mat-icon matListItemIcon>phone_iphone</mat-icon>
                  <div matListItemTitle>Phone Number</div>
                  <div matListItemLine>{{appointmentData.consumerMobileNo || '-'}}</div>
                </mat-list-item>
                <mat-list-item lines="2">
                  <mat-icon matListItemIcon>mail</mat-icon>
                  <div matListItemTitle>Email</div>
                  <div matListItemLine>{{appointmentData.consumerEmailId || '-'}}</div>
                </mat-list-item>
                <!-- <mat-list-item lines="3">
                <mat-icon matListItemIcon>person_pin_circle</mat-icon>
                <div matListItemTitle>Address</div>
                {{appointmentData.consumerAddress.formattedAddress || '-'}}
              </mat-list-item>
              <mat-list-item>
                <mat-icon matListItemIcon>apartment</mat-icon>
                <div matListItemTitle>Address Type</div>
                <div matListItemLine>{{appointmentData.consumerAddress.categories[0].value || '-'}}</div>
              </mat-list-item> -->
                <mat-list-item>
                  <mat-icon matListItemIcon>devices_other</mat-icon>
                  <div matListItemTitle>E-waste Item</div>
                  <div matListItemLine>{{appointmentData.items[0].categoryName || '-'}}</div>
                </mat-list-item>
              </div>
            </mat-list>
            <mat-list-item>
              <mat-icon matListItemIcon>image</mat-icon>
              <div matListItemTitle>Photos and Video</div>
            </mat-list-item>
            <div class="media-summary-container">
              <div class=" media-tiles">
                <div class="media-tile" (click)="showMediaDialog('Front Photo',appointmentData.items[0].frontImageURL)">
                  <div class="image-holder">
                    <img [src]="appointmentData.items[0].frontImageURL" alt="Front Photo" />
                    <mat-icon class="zoom-icon">zoom_out_map</mat-icon>
                  </div>
                </div>
                <div class="media-tile" (click)="showMediaDialog('Back Photo',appointmentData.items[0].backImageURL)">
                  <div class="image-holder">
                    <img [src]="appointmentData.items[0].backImageURL" alt="Back Photo" />
                    <mat-icon class="zoom-icon">zoom_out_map</mat-icon>
                  </div>
                </div>
                <ng-container *ngIf="appointmentData.items[0]?.videoURL">
                  <div class="media-tile"
                    (click)="showMediaDialog('Dropoff Video',appointmentData.items[0].videoURL,'video')">
                    <div class="image-holder">
                      <video [poster]="appointmentData.items[0].frontImageURL" controlsList="nodownload"
                        [src]="appointmentData.items[0].videoURL" title="Dropoff Video">
                        Your browser does not support the video tag.
                      </video>
                      <mat-icon class="video-icon">play_circle</mat-icon>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </mat-card>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="showNoCouponsCodeMessage">
      <div>
        <p class="noCouponCodesAvailable">Sorry, no coupon codes are available at the moment.</p>
      </div>
    </ng-container>


    <ng-container *ngIf="showNoCouponNotFound">
      <div>
        <p class="noCouponCodesAvailable">Coupon code does not exist.</p>
        <div style="display:flex;justify-content: center;">
          <button mat-stroked-button color="accent" routerLink="/celekt/store/coupon">Search Another Coupon
            Code</button>
        </div>
      </div>
    </ng-container>

    <div id="overlay" *ngIf="loading">
      <div id="overlayText"><mat-spinner [diameter]="50" color="accent"></mat-spinner></div>
    </div>

  </div>
</div>