import { Component, OnInit } from '@angular/core';
import { PageTitleService } from './services/page-title/page-title.service';
import { DownloadAppService } from './services/download-app/download-app.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  constructor(private pageTitleService: PageTitleService,
    private downloadAppService: DownloadAppService) { }

  ngOnInit() {
    this.pageTitleService.init();
    this.downloadAppService.init();
  }
}
