import { Component, ViewChild } from '@angular/core';
import { CommonService } from 'src/app/services/common/common.service';
import { SwiperOptions } from 'swiper';
import { SwiperComponent } from 'swiper/angular';

export type RowName = "Environment" | "Economic" | "Social";
export interface ImpactCreated {
  rowName: RowName;
  fieldDescriptionOne: string;
  fieldDescriptionTwo: string;
  value: string;
  rowOrder: number;
  columnOrder: number;
  enabled: boolean;
}

export interface ImageMapping {
  [key: string]: string;
}

const createImageMapping = (input: { [K in RowName]: string }): ImageMapping => input;

export interface RowGroup {
  [rowName: string]: ImpactCreated[];
}

export interface ImpactCreatedResponse {
  status: string;
  data: {
    recyclingImpact: ImpactCreatedArray;
    message: string;
  };
}

export interface ImpactCreatedArray extends Array<ImpactCreated> { }

export interface NestedImpactCreatedArray extends Array<ImpactCreatedArray> { }
@Component({
  selector: 'app-impact-created-v2',
  templateUrl: './impact-created-v2.component.html',
  styleUrls: ['./impact-created-v2.component.scss']
})
export class ImpactCreatedV2Component {
  @ViewChild('impactCreated') swiper!: SwiperComponent;

  swiperConfig: SwiperOptions = {
    allowTouchMove: true,
    grabCursor: true,
    pagination: { clickable: true },
    breakpoints: {
      200: {
        initialSlide: 0,
        spaceBetween: 10,
        slidesPerView: 1,
      },
      // 425: {
      //   slidesPerView: 1,
      //   spaceBetween: 10
      // },
      768: {
        initialSlide: 1,
        slidesPerView: 1.2,
        centeredSlides: true,
        spaceBetween: 15,
        pagination: false
      },
      // 992: {},

      // 1024: {
      //   slidesPerView: 1,
      //   spaceBetween: 30,
      // }

    }
  }

  impactCreatedData: NestedImpactCreatedArray = [];

  constructor(private commonService: CommonService) { }
  ngOnInit(): void {
    this.getImpactCreated();
  }

  reshapeData(achievements: ImpactCreatedArray): NestedImpactCreatedArray {
    const rowGroups: RowGroup = {};
    achievements.forEach((achievement) => {
      if (!rowGroups[achievement.rowName]) {
        rowGroups[achievement.rowName] = [];
      }

      rowGroups[achievement.rowName].push(achievement);
    });

    for (const rowName in rowGroups) {
      rowGroups[rowName].sort((a, b) => a.columnOrder - b.columnOrder);
    }
    const sortedRowGroups = Object.values(rowGroups).sort((a, b) => a[0].rowOrder - b[0].rowOrder);
    return sortedRowGroups;
  }

  async getImpactCreated() {
    (await this.commonService.getImpactCreated()).subscribe((res: any) => {
      const response: ImpactCreatedResponse = res;
      if (response.status === 'SUCCESS') {
        this.impactCreatedData = this.reshapeData(response.data.recyclingImpact);
        console.log('impace created', this.impactCreatedData)
      }
    })
  }

  previousSlide() {
    if (this.swiper) {
      this.swiper.swiperRef.slidePrev();
    }
  }

  nextSlide() {
    if (this.swiper) {
      this.swiper.swiperRef.slideNext();
    }
  }

  getBackgroundImage(row: RowName) {
    const imageMapping: ImageMapping = createImageMapping({
      Environment: "/assets/Sustainability-3.png",
      Economic: "/assets/Sustainability-2.png",
      Social: "/assets/Varanasi.png"
    });

    return `url(${imageMapping[row]})`
  }
}
