<div class="main-container">
  <div class="container">
    <h1 class="title">Facts & FAQs</h1>
    <div class="content">
      <div><b>Do you know?</b></div>
      <div style="border-bottom: 1px solid #75cc75" width="105%">
        <p>
          Plastic bags and other plastic garbage thrown into the ocean
          kill as many as 1,000,000 sea creatures every year!
        </p>
      </div>
      <ul>
        <details [open]="index===0" *ngFor="let faq of faqs;let index=index;">
          <summary class="c-faq__title">{{faq.question}}</summary>
          <div class="c-faq__answer" [innerHTML]="faq.answer | safeHtml">
          </div>
        </details>
        <!-- <details class="mvnew-hr1 c-faq">
        <summary class="c-faq__title">
          What materials can be recycled?
        </summary>
        <div class="c-faq__answer">
          <p>
            <b>GLASS</b><br />
            Most household glass can be recycled repeatedly; just
            rinse or wash out the container and recycle. Glass is one
            of the easiest materials to recycle, so buy products
            packaged in glass if you can.
            <br />
            <b>METALS</b><br />
            Metal food and drink cans made from aluminium or steel are
            recyclable. You can even recycle used aluminium foil; just
            be sure cans and foil are clean.
            <br />
            <b>PAPER</b><br />
            Nearly every paper item is recyclable. If you have a
            garden, you can recycle your own paper in your compost
            pile.
            <br />
            <b>PLASTICS</b><br />
            Plastic products such as beverage bottles and containers
            are recyclable.
            <br />
            <b>E-WASTE</b><br />
            Electronic waste such as mobile phones, refrigerators, air
            conditioners, etc. must be safely recycled. Otherwise,
            they can become hazardous to the entire planet.
            <br />
            <b>OTHERS</b><br />
            Some recyclers accept textiles, organic materials such as
            garden and kitchen waste, construction debris, cooking
            oil, batteries, tyres, pressurised containers (gas
            bottles, fire extinguishers, etc.), wood, and hazardous
            waste such as asbestos and chemicals.
          </p>
        </div>
      </details>
       <details class="mvnew-hr1 c-faq">
        <summary class="c-faq__title">Why ScrapQ?</summary>
        <div class="c-faq__answer">
          <p>
            ScrapQ is a unique, easy-to-use interface which enables
            you to dispose your scrap items at your desired time and
            location with accurate weightage in a professional manner.
            ScrapQ ensures that your scrap is safely recycled and
            reused. Thus, helps you to build a safer society by
            reducing waste. It also pays you in many ways to help you
            recycle. What more do you want to get started on recycling
            today!
          </p>
        </div>
      </details>
      <details class="mvnew-hr1 c-faq">
        <summary class="c-faq__title">
          What scrap items does ScrapQ pickup?
        </summary>
        <div class="c-faq__answer">
          <p>
            Regular scrap items like newspapers, plastics, cans,
            books, cartons, bottles, steel and all such items that
            usually your round-the-corner kabadiwala picks up. We also
            pick up electronic waste like old refrigerators,
            televisions, computers, air conditioners etc. Please call
            us if you have any specific requirements.
          </p>
          <br />
          <p>
            Unfortunately, we can’t pickup items such wet waste or
            organic materials such as garden and kitchen waste,
            Construction debris Cooking oil, Batteries, Tyres,
            Pressurised Containers (gas bottles, fire extinguishers
            etc.), Wood, hazardous waste such as Asbestos, chemicals
            etc.
          </p>
        </div>
      </details>
      <details class="mvnew-hr1 c-faq">
        <summary class="c-faq__title">
          Is there a minimum weight requirement for you to pickup?
        </summary>
        <div class="c-faq__answer">
          <p>Not really, we can pickup even a single bottle.</p>
        </div>
      </details>
      <details class="mvnew-hr1 c-faq">
        <summary class="c-faq__title">How much do you pay?</summary>
        <div class="c-faq__answer">
          <p>
            We work hard to keep our rates competitive all the time.
            Please check our website for latest rates:
          </p>
          <span class="ex_link">
            <a href="/" id="pricing">http://www.scrapq.com</a>
          </span>
        </div>
      </details>
      <details class="mvnew-hr1 c-faq">
        <summary class="c-faq__title">
          How do I get the payment?
        </summary>
        <div class="c-faq__answer">
          <p>
            We pay by cash on the spot. And our electronic payment
            systems are round the corner too.
          </p>
        </div>
      </details>
      <details class="mvnew-hr1 c-faq">
        f
        <summary class="c-faq__title">
          How can I book an appointment?
        </summary>
        <div class="c-faq__answer">
          <p>
            Please give us a missed call on
            <span class="ex_number_one">
              <br /><a>040 30707070</a></span>
            or
            <span class="ex_number_two"><br /><a>9030 727277</a></span>, we will call you and make an
            appointment. Or leave your
            number on our website,
          </p>
          <div style="width: 20px !important">
            <a href="bookappointMent.html">BookHere</a>
          </div>
        </div>
      </details>
      <details class="mvnew-hr1 c-faq">
        <summary class="c-faq__title">
          Which cities you are providing these services in?
        </summary>
        <div class="c-faq__answer">
          <p>
            We currently provide our services in Hyderabad, but fast
            approaching other cities too.
          </p>
        </div>
      </details>
      <details class="mvnew-hr1 c-faq">
        <summary class="c-faq__title">
          Can I drop off my scrap at your place instead?
        </summary>
        <div class="c-faq__answer">
          <p>
            Of course, you can. We have designated drop off locations
            across the city. Use our app or call center to fix an
            appointment today and drop them off.
          </p>
        </div>
      </details>
      <details class="mvnew-hr1 c-faq">
        <summary class="c-faq__title">
          Do I get a better deal if I drop off instead of asking you
          to pickup?
        </summary>
        <div class="c-faq__answer">
          <p>
            As we will save on conveyance, we ensure to pass our
            savings on to you. So, yes, you will get a better deal if
            you choose to drop off instead.
          </p>
        </div>
      </details> -->
      </ul>
    </div>
    <div class="image">
      <img src="/assets/Icons-89.png" class="mx-w" />
    </div>
  </div>
</div>