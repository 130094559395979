<div class="hero-container carousel" [ngSwitch]="showContentType">

  <app-book-appointment-map *ngSwitchCase="'MAP'"></app-book-appointment-map>
  <app-dropoff-points-map *ngSwitchCase="'DROPOFF'"></app-dropoff-points-map>

  <swiper *ngSwitchCase="'CAROUSEL'" #swiper [speed]="1000" style="height:100%" [pagination]="paginationOptions"
    [loop]="true" [autoplay]="autoplayOptions" [slidesPerView]="1">
    <ng-template swiperSlide class="carousel-slide carousel-slide-1"></ng-template>
    <ng-template swiperSlide class="carousel-slide carousel-slide-2"></ng-template>
    <!-- <ng-template swiperSlide class="carousel-slide carousel-slide-3"></ng-template>
    <ng-template swiperSlide class="carousel-slide carousel-slide-4"></ng-template>
    <ng-template swiperSlide class="carousel-slide carousel-slide-5"></ng-template> -->
  </swiper>

</div>