import { Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-celekt-store-summary',
  templateUrl: './celekt-store-summary.component.html',
  styleUrls: ['./celekt-store-summary.component.scss']
})
export class CelektStoreSummaryComponent {
  searchBox: FormControl = new FormControl('', [Validators.required]);
  constructor(private router: Router) {

  }

  openCoupon() {
    this.router.navigateByUrl(`/celekt/store/coupon/summary/${this.searchBox.value}`);
  }
}
