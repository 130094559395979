<footer>
  <div class="footer-content">
    <div>
      <p class="title">ScrapQ</p>
      <p>ScrapQ is a unique service provider in collection of recyclables, enabling recycling, confidential data
        destruction and scrap disposal services in Hyderabad.</p>
      <div class="social-media-icons">
        <a href="https://www.facebook.com/ScrapQ.recycle/?hc_ref=ARQGT6H0H-SrosX7p2T5sRqdAB_dGylll9P76qFk6QGW0Su5jOS4l6KXoeldFtYLEt8&fref=nf"
          target="_blank" rel="noreferrer">
          <img src="assets/Icons-01.png" height="35" width="35" />
        </a>
        <a href="https://twitter.com/ScrapQ_recycle" target="_blank" rel="noreferrer">
          <img src="assets/Icons-03.png" height="35" width="35" />
        </a>
        <a href="https://in.linkedin.com/company/scrapq" target="_blank" rel="noreferrer">
          <img src="assets/Icons-05.png" height="35" width="35" />
        </a>
        <a href="https://www.instagram.com/scrapq.recycle/" target="_blank" rel="noreferrer">
          <img src="assets/Icons-04.png" height="35" width="35" />
        </a>
      </div>
    </div>
    <div>
      <p class="title">Company</p>
      <div class="links">
        <ul>
          <li>
            <a routerLink="/about-us" routerLinkActive="link-active">About ScrapQ </a>
          </li>
          <li>
            <a routerLink="/book-appointment" routerLinkActive="link-active">Book Appointment</a>
          </li>
          <!-- <li>
            <a routerLink="/our-networks" routerLinkActive="link-active">Our Networks </a>
          </li> -->
          <li>
            <a routerLink="/faqs" routerLinkActive="link-active">Facts & FAQ's </a>
          </li>
          <li>
            <a routerLink="/privacy-policy" routerLinkActive="link-active">Privacy policy </a>
          </li>
          <li>
            <a routerLink="/contact-us" routerLinkActive="link-active">Contact Us </a>
          </li>

        </ul>
      </div>
    </div>
    <div>
      <p class="title">Corporate Office</p>
      <p style="white-space: pre-line; font-size: 0.9rem;">
        {{address}}
      </p>
      <div class="contact">
        <a class="phone" href="tel:+919030727277"> <mat-icon>phone</mat-icon>
          <span>+91 903 072 7277</span></a>
        <a class="email" href="mailto:support@scrapq.com">
          <mat-icon>email</mat-icon> <span>support@scrapq.com</span></a>
      </div>
    </div>
    <div>
      <p class="title">Awards & Certifications</p>
      <div class="social-media-icons" style="margin-top: 14.4px;">
        <img src="/assets/certificates/sample-award.png" height="70" width="70" />
        <img src="/assets/certificates/sample-award.png" height="70" width="70" />
        <img src="/assets/certificates/sample-award.png" height="70" width="70" />
        <img src="/assets/certificates/sample-award.png" height="70" width="70" />
      </div>
    </div>
  </div>

  <!-- <div class="row">
    <div>
      <p style="font-size: 1.1rem;">ScrapQ Hub Pvt Ltd</p>
      <p style="white-space: pre-line; font-size: 0.9rem;">
        {{address}}
      </p>
      <div style="display: flex; gap: 5px; justify-content: flex-start;">
        <mat-icon>phone</mat-icon>
        <span>040 30 70 70 70, +91 90 30 72 72 77</span>
      </div>
    </div>
    <div>
      <p>Copyright 2020 ScrapQ</p>
    </div>
  </div> -->
  <div class="footer-copyright">
    &copy; 2020 - {{currentYear}} ScrapQ Hub Pvt Ltd. All rights reserved.
  </div>
</footer>