import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { BookAppointmentService } from 'src/app/services/book-appointment/book-appointment.service';
import SwiperCore, { Autoplay, EffectCoverflow, Grid, Pagination, Parallax, Virtual } from 'swiper';
import { AutoplayOptions, PaginationOptions } from 'swiper/types';

SwiperCore.use([Virtual, Pagination, Autoplay, Grid, Parallax, EffectCoverflow]);

export type ShowContentType = 'MAP' | 'DROPOFF' | 'CAROUSEL';
@Component({
  selector: 'app-hero',
  templateUrl: './hero.component.html',
  styleUrls: ['./hero.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HeroComponent implements OnInit {
  autoplayOptions: AutoplayOptions = {
    delay: 2500,
    disableOnInteraction: false
  }

  paginationOptions: PaginationOptions = {
    clickable: true
  }
  showContentType: ShowContentType = 'CAROUSEL';
  constructor(private bookAppointmentService: BookAppointmentService) {
  }

  ngOnInit(): void {
    this.bookAppointmentService.getShowMap().subscribe((showMap) => {
      if (showMap) {
        this.showContentType = 'MAP';
      } else {
        this.showContentType = 'CAROUSEL'
      }
    });

    this.bookAppointmentService.getDropOffMap().subscribe((value) => {
      if (value) {
        this.showContentType = 'DROPOFF';
      } else {
        this.showContentType = 'CAROUSEL'
      }
    });
  }
}
